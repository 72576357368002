<template>
  <div id="howitworks" class="howItWorks">
    <h2>How it works</h2>

    <EscrowFeatureTwoSideVue
      :title="''"
      :description="'We give you a customized payment link when you upgrade to Merchant level.'"
      :image="'merchantLink'"
    />

    <MerchantTwoSideReverse
      :title="''"
      :description="'This link can be used to receive payments instantly from your customers and can be disabled or generated as you wish. Get Linked! Get Paid!'"
      :image="'merchantLink2'"
    />

    <MerchantTwoSide
      :title="''"
      :description="'We also give you a dashboard to track all the money received via your merchant links to ensure accountability and accuracy.'"
      :image="'merchantLink'"
    />

   
  </div>
</template>



<script>
import EscrowFeatureTwoSideVue from "../Escrow/Features/EscrowFeatureTwoSide.vue";
import MerchantTwoSideReverse from "./Features/MerchantTwoSideReverse.vue";
import MerchantTwoSide from "./Features/MerchantTwoSide.vue"
export default {
  components: {
    EscrowFeatureTwoSideVue,
    MerchantTwoSideReverse,
    MerchantTwoSide
  },
};
</script>





<style scoped>
@media screen and (max-width: 400px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
}

@media screen and (max-width: 516px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1045px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1168px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1500px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1900px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}
</style>


<!-- I pass an image a prop in this component -->