<template>
    <v-app>
        <NavMenu :route="$route.path.substring(1)" />
        <AboutJumbotron/>
        <FlagShipComponent />
        <AboutTwoSide />
        <AboutReverseTwoSide />
        <AboutTeam />
        <CtaSection/>
        <AppSection/>
        <div style="margin:70px 0px 0px 0px">
        <FooterSection/>
        <WhatsApp />
        </div>
    </v-app>
</template>




<script>
import NavMenu from "../components/NavMenu.vue"
import AboutJumbotron from "../components/About/AboutJumbotron"
import AboutTwoSide from "../components/About/AboutTwoSide"
import FlagShipComponent from "../components/About/FlagShipComponent.vue"
import AboutReverseTwoSide from "../components/About/AboutReverseTwoSide.vue"
import AboutTeam from '@/components/About/AboutTeam.vue'
import CtaSection from "../components/Home/CtaSection.vue"
import AppSection from "../components/Home/AppSection.vue"
import FooterSection from "../components/Home/FooterSection.vue"
import WhatsApp from "@/components/WhatsApp/WhatsApp.vue"


export default {
    components:{
        NavMenu,
        AboutJumbotron,
        AboutTwoSide,
        AboutTeam,
        AboutReverseTwoSide,
        FlagShipComponent,
        CtaSection,
        AppSection,
        FooterSection,
        WhatsApp
    },

    metaInfo() {
    return {
      title: `About Us`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
}
</script>