<template>
    <v-main class="bgcolor">
        <div class="jumbotronContainer">
        <div class="jumbotronText">
        <h2>We protect both parties on all transactions</h2>
        <p>We ensure that both buyers and seller are protected on every transaction </p>
        <div>
            <v-btn href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1" depressed class="createButton rounded-lg" x-large>Create Account</v-btn>

        </div>
       
        </div>

        <div class="relative jumbotronImage">
            <img class="imageSize"  src="../../assets/why.gif" alt="kuleanshield"/>
        </div>
        </div>
    </v-main>
    
</template>




<script>
export default {
    
}
</script>


<style scoped>

.createButton{
    border-radius:8px;
    background:#0582D2 !important;
    color:white !important
}

.joinButton{
    color:white
}

.jumbotronContainer {
  padding: 0px 100px;
}

.bgcolor{
    background: #E4EFF6;
    height:auto
}

.navMenu {
  padding: 34px 100px;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-btn{
 width:90%;
 margin:15px 0px 0px 0px
}
    .createButton,.joinButton{
        width:90% !important;
        
    }


     .jumbotronContainer h2{
    color:black;
    font-size:40px !important;
    line-height: 40px !important;
    width:90%
  }

    
    .bgcolor{
        height:auto !important
    }

    .jumbotronContainer img{
        margin:30px 0px;
        width:95%
    }

     .bgcolor{
        height:auto
    }

     .jumbotronContainer{
        padding: 94px 0px 0px 40px;
         display:block;
    }

    .jumbotronContainer img{
        margin:90px 0px
    }

    .jumbotronContainer h2{
    color:black;
    font-size:50px;
    line-height: 50px;
    width:90%
  }

  .jumbotronContainer p{
    color:blac;
    padding:32px 0px;
    width:90%;
    font-size:17px
  }


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
    .bgcolor{
        height:auto
    }

     .jumbotronContainer{
        padding: 94px 0px 0px 40px;
         display:block;
    }

    .jumbotronContainer img{
        margin:90px 0px;
        width:90%
    }

    .jumbotronContainer h2{
    color:black;
    font-size:50px;
    line-height: 50px;
    width:90%
  }

  .jumbotronContainer p{
    color:black;
    padding:32px 0px;
    width:90%;
    font-size:17px
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .jumbotronContainer{
    padding: 94px 0px 0px 40px;
    display:block;
   justify-content: space-between;
    }

    .jumbotronContainer h2{
    color:black;
    font-size:56px;
    line-height: 50px;
    width:90%
  }

  .jumbotronContainer p{
    color:black;
    padding:32px 0px;
    width:60%;
    font-size:19px
  }



}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bgcolor{
    height:auto
  }
  .jumbotronContainer{
        padding: 120px 50px 0px 30px;
        display:flex;
   justify-content: space-between;
    }

    .jumbotronText{
    padding:40px 0px 90px 0px;
    width:100%
  }
    .jumbotronContainer h2{
    color:black;
    font-size:56px;
    line-height: 56px;
    width:80%
  }

   .jumbotronContainer p{
    color:black;
    padding:32px 0px;
    width:70%;
    font-size:18px
  }

    .jumbotronImage{
    width:90%;
    position: relative
  }




}







/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 .bgcolor{
    height:auto
  }
    .jumbotronContainer{
   padding: 120px 46px 0px 100px;
   display:flex;
   justify-content: space-between;
  }

  .jumbotronContainer h2{
    color:black;
    font-size:56px;
    line-height:56px
  }

  .jumbotronContainer p{
    color:black;
    padding:32px 0px;
    width:50%;
    font-size:16px
  }

  

   .jumbotronText{
    padding:40px 0px 90px 0px;
    width:60%
  }

   
    .jumbotronImage{
    width:50%;
    position: relative;
  

  }

 

}

@media screen and (min-width: 1500px) {
    .bgcolor{
    height:auto;
  }
    .jumbotronContainer{
   padding: 120px 46px 0px 100px;
   display:flex
  }

  .jumbotronContainer h2{
    color:black;
    font-size:56px;
  }

   .jumbotronContainer p{
    color:black;
    padding:32px 0px;
    width:50%;
    font-size:16px
  }

    .jumbotronImage{
    width:40%;
    position: relative;

  }

  .imageSize{
    position:relative;
    height:auto;
  }

}




@media screen and (min-width: 1900px) {
  

  .jumbotronContainer{
    max-width: 1700px !important;
    margin: 0 auto;
  }

  .jumbotronContainer h2{
    color:black;
    font-size:56px;
  }
}





</style>
