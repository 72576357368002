<template>
    <div>
      <input
        outlined
        type="text"
        placeholder="Enter amount"
        v-model="amount"
        @input="formatInput"
        inputmode="numeric"
        color="#0883d3"
        class="amountInput"
        :required="true"
      />
    </div>
  </template>
    
    <script>
  import Vue from "vue";
  import { amountInputFormat } from "@/utils/amountFormatter";
  
  Vue.filter("amountInputFormat", amountInputFormat);
  
  export default {
    name:"AmountInputNormal",
    data() {
      return {
        amount: "",
        numberAmount:""
      };
    },
    methods: {
      formatInput() {
        const regex = /^\d*$/;
        if (!regex.test(this.amount)) {
          this.amount = this.amount.replace(/\D/g, "");
        }
        this.amount = this.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$emit("setAmount",this.amount)
      },
      clearAmount(){
          this.amount = ""
      }
    },
  };
  </script>
    
<style scoped>
  .amountInput {
    padding: 0px 0px 0px 11px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin:0px 0px 15px 0px;
    font-size:14px
  }
  
  input:focus {
    border: 1px solid #0582d2 !important;
  }
  
  .amountInput:focus {
    border: 1px solid #0582d2 !important;
    outline: #0582d2 solid 1px;
  }
  
  input:active {
    border: 1px solid #0582d2 !important;
    outline: #0582d2 solid 1px;
  }
  </style>
    