<template>
  <v-main class="crowdproject">
    <div>
      <ProjectMeta
        :title="project.title"
        :date="project.startDate"
        :project="project"
        :loading="loading"
      />
    </div>

    <div>
      <ProjectImage
        :loading="loading"
        :project="project"
        @showDonation="showDonation"
      />
    </div>

    <div>
      <ProjectInfo
        :project="project"
        :title="title"
        :slug="slug"
        :percentage="percentage"
        :loading="loading"
      />
    </div>
  </v-main>
</template>



<script>
import { unslugify } from "unslugify";
import moment from "moment";
import ProjectImage from "./ProjectImage.vue";
import ProjectMeta from "./ProjectMeta.vue";
import ProjectInfo from "./ProjectInfo.vue";

export default {
  data() {
    return {
      slug: this.$route.params.id,
      processId: "",
    };
  },
  components: {
    ProjectImage,
    ProjectMeta,
    ProjectInfo,
  },
  props: {
    project: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    title() {
      return unslugify(this.$route.params.id);
    },
    date() {
      return moment(Date.now()).format("LLL");
    },
    percentage() {
      return (
        (parseFloat(this.project.contributions) /
          parseFloat(this.project.goalAmount)) *
        100
      );
    },
  },

  methods: {
    showDonation() {
      this.$emit("showDonation");
    },
  },
};
</script>


<style scoped>
.crowdproject {
  margin: 40px 0px;
}

@media only screen and (min-width: 776px) {
  .crowdproject {
    margin: 40px auto;
    max-width: 856px;
  }
}
</style>