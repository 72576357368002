<template>
  <v-app>
    <CrowdFundingProject
      @showDonation="showDonation"
      :project="crowdfundingproject"
      :loading="loading"
    />

    <v-dialog fullscreen v-model="donationform">
      <DonationForm
        @closeDonation="showDonation"
        :project="crowdfundingproject"
        :title="title"
        :date="date"
        @makePayment="makePayment"
      />
    </v-dialog>

    <div class="d-none">
      <Paystack
        id="paystack"
        :amount="parseFloat(amount) * 100"
        :email="email"
        :paystackkey="paystackkey"
        :reference="reference"
        :callback="callback"
        :close="close"
        :embed="false"
        style="width: 100%; margin: auto"
        class="animate__animated animate__fadeInUp animate__fast"
      >
        <v-btn
          block
          outlined
          x-large
          class="animate__animated animate__fadeInUp animate__fast rounded-lg d-flex justify-space-between"
          :loading="loading"
        >
          <span class="ml-5">Pay with Card </span></v-btn
        >
      </Paystack>
    </div>

    <div style="margin: 90px 0px 0px 0px">
      <FooterSection />
    </div>

    <v-overlay :value="paymentloading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
    
    
    
<script>
import FooterSection from "@/components/Home/FooterSection.vue";
import CrowdFundingProject from "@/components/CrowdFunding/CrowdFundingProject.vue";
import DonationForm from "@/components/CrowdFunding/DonationForm.vue";
import moment from "moment";
import { unslugify } from "unslugify";
import { mapState } from "vuex";
import Paystack from "vue-paystack";
import axios from "axios";
import { LOGINURL, PAYSTACKKEY } from "@/constants/constants";

export default {
  components: {
    CrowdFundingProject,
    FooterSection,
    DonationForm,
    Paystack,
  },

  data() {
    return {
      donationform: false,
      processId: "",
      name: "",
      email: "",
      amount: "",
      paystackkey: PAYSTACKKEY,
      paystackRef: "",
      paymentDetails: "",
      paymentloading: false,
    };
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    title() {
      return unslugify(this.$route.params.id);
    },
    date() {
      return moment(Date.now()).format("LLL");
    },
    ...mapState({
      crowdfundingproject: "crowdfundingproject",
      loading: "loading",
    }),
  },

  metaInfo() {
    return {
      title: `CrowdFunding`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },

  created() {
    this.$store.dispatch(
      "fetchCrowdfundingProject",
      window.location.pathname.split("/")[2]
    );
  },

  methods: {
    showDonation() {
      this.donationform = !this.donationform;
    },

    makePayment(payload) {
      this.paymentloading = true;
      this.name = payload.name;
      this.email = payload.email;
      this.amount = payload.amount;
      let paystack = document.getElementById("paystack");
      axios({
        method: "POST",
        url:
          LOGINURL +
          "settlements/onetimesettlementmgt/crowd-funding/initiate-card-fund-wallet",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          processId: payload.processId,
        },
      })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.paymentDetails = response.data.data;
            this.paymentloading = false;
            paystack.click();
          } else {
            this.paymentloading = false
            this.$swal({
              icon: "warning",
              title: "Something went wrong",
              text: response.data.description,
            });
          }
        })
        .catch((error) => {
          this.paymentloading = false
          this.$swal({
            icon: "warning",
            title: "Something went wrong",
            text: error.message,
          });
        });
    },

    callback(response) {
      this.paymentloading = true;
      this.paystackRef = response.reference;
      axios({
        method: "POST",
        url:
          LOGINURL +
          "settlements/onetimesettlementmgt/crowd-funding/notify-fund-wallet",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          walletNumber: this.paymentDetails.walletNumber,
          amount: this.amount,
          payStackRefrence: this.paystackRef.trim(),
          transactionRef: this.paymentDetails.transactionRef,
        },
      })
        .then((response) => {
          this.paymentloading = false;
          if (response.data.statusCode == 200) {
            this.$swal({
              icon: "success",
              title: "Payment Successful!",
              text:
                "You have successfully sent NGN " +
                this.amount +
                " as a donation to this campaign",
            })
              .then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                }
              })
              .catch((error) => {
                this.$swal({
                  icon: "error",
                  title: "Something went wrong",
                  text: error.message,
                });
              });
          } else {
            this.$swal({
              icon: "warning",
              title: "Something went wrong",
              text: response.data.description,
            });
          }
        })
        .catch((error) => {
          this.paymentloading = false;
          this.$swal({
            icon: "warning",
            title: "Something went wrong",
            text: error.message,
            confirmButtonText: `<p style="color:blue">Try again</p>`,
          });
        });
    },

    close: () => {
      console.log("You closed checkout page");
    },
  },
};
</script>
    
    
  
    
<style scoped>
</style>
