<template>
  <div class="merchantCardContainer">
    <div class="kuleanpayLogo">
    <img class="pointer" @click="goHome" src="../../assets/kuleanBrandWhite.svg" />
    </div>
    <v-card class="merchantCard">
        <div class="merchantInfo">
            <div>PAY</div>
            <h1>{{ this.merchantName }}</h1>
        </div>

        
        <div class="tabContainer">
        <v-tabs  grow v-model="tab">
            <v-tab class="">
                Debit/Credit Card
            </v-tab>
            <v-tab>
                Bank Transfer
            </v-tab>
        </v-tabs>
    </div>


        <v-tabs-items v-model="tab">
      <v-tab-item
      >
      <CardPayment />
      </v-tab-item>

      <v-tab-item
      >
      <BankDetails :merchantName="merchantName" @goHome="goHome"/>
      </v-tab-item>
    </v-tabs-items>
    </v-card>
  </div>
</template>



<script>
import BankDetails from "./BankDetails"
import CardPayment from "./CardPayment.vue";
export default {
    name:"MerchantPaymentCard",
    components:{BankDetails,CardPayment},
    props:{
        merchantName:{
            type:String
        }
    },
    data:()=>({
        tab:""
    }),
    methods:{
        goHome(){
            this.$router.push("/")
        }
    }
}
</script>

<style scoped>
.pointer{
    cursor: pointer;
}

@media only screen and (max-width:300px) {

.v-tab {
    letter-spacing: normal !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px 0px 0px -40px !important;
    padding:0px 0px 0px -90px !important;
    width:30px
}
}

@media only screen and (max-width:600px) {
    .merchantCardContainer{
        margin:50px 20px 50px 20px;
    }

    .kuleanpayLogo{
        display:flex;
        justify-content: center;
        margin:0px 0px 50px 0px
    }

    .merchantCard{
        padding:20px;
        max-width:448px;
        margin:auto
    }

    .merchantInfo{
        background:#F0F7FF;
        text-align: center;
        padding:12px
    }

    .merchantInfo h1{
        font-size:24px
    }

    .v-tab {
    letter-spacing: normal !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    padding:0px !important
}

.tabContainer{
    margin:26px 0px 0px 0px
}
}


@media only screen and (min-width:600px) {
    .merchantCardContainer{
        margin:50px 20px 0px 20px;
    }

    .kuleanpayLogo{
        display:flex;
        justify-content: center;
        margin:0px 0px 50px 0px
    }

    .merchantCard{
        padding:40px;
        max-width:448px;
        margin:auto
    }

    .merchantInfo{
        background:#F0F7FF;
        text-align: center;
        padding:12px
    }

    .merchantInfo h1{
        font-size:24px
    }

    .v-tab {
    letter-spacing: normal !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    padding:0px !important
}

.tabContainer{
    margin:26px 0px 0px 0px
}
}

@media only screen and (min-width:776px) {
    .merchantCardContainer{
        margin:30px 20px 60px 20px;
    }

    .kuleanpayLogo{
        display:flex;
        justify-content: center;
        margin:0px 0px 20px 0px
    }

    .merchantCard{
        padding:40px;
        max-width:448px;
        margin:auto
    }

    .merchantInfo{
        background:#F0F7FF;
        text-align: center;
        padding:12px
    }

    .merchantInfo h1{
        font-size:24px
    }

    .v-tab {
    letter-spacing: normal !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px !important;
    padding:0px !important
}

.tabContainer{
    margin:26px 0px 0px 0px
}
}

@media only screen and (min-width:894px) {
    
}

@media only screen and (min-width:996px) {
    
}

@media only screen and (min-width:1200px) {
    
}

@media only screen and (min-width:1500px) {
    
}
</style>