<template>
  <v-app>
    <NavMenu :route="$route.path.substring(1)" :bg="'bgblue'" />
    <SolutionHero
      :title="'Go Escrow with Kuleanpay'"
      :description="'Both buyers and sellers are protected and funds held securely by us. Payments are released only after orders are fulfilled.'"
      :imageLg="'escrowLargeImage'"
      :imageSm="'escrowHeroImage'"
    />
    <HowItWorks />
    <BestWay />
    <CtaSection />
    <AppSection />
    <div style="margin: 90px 0px 0px 0px">
      <FooterSection />
      <WhatsApp />
    </div>
  </v-app>
</template>
  
  
  
<script>
import NavMenu from "../components/NavMenu.vue";
import HowItWorks from "@/components/Escrow/HowItWorks.vue";
import FooterSection from "@/components/Home/FooterSection.vue";
import SolutionHero from "@/components/Escrow/SolutionHero.vue";
import BestWay from "@/components/Home/BestWay.vue";
import CtaSection from "@/components/Home/CtaSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
import WhatsApp from "@/components/WhatsApp/WhatsApp.vue";

export default {
  components: {
    NavMenu,
    SolutionHero,
    HowItWorks,
    FooterSection,
    BestWay,
    CtaSection,
    AppSection,
    WhatsApp
  },

  metaInfo() {
    return {
      title: `Ecrow Payments`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
};
</script>
  
  
  
  
  
  <style scoped>
</style>