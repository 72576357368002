<template>
  <div @click="viewProject()" class="crowdproject">
    <v-img
      style="border-radius: 10px 10px 0px 0px"
      :src="'data:image/octet-stream;base64,' + image"
      height="200px"
      max-width="333px"
    />
    <div class="content">
      <div class="title">
        <div class="d-flex">
          <icon :name="'crowdfunding'" :color="'black'" :className="'mt-1'" />
          <h4 class="crowdfunding-title">{{ title | titleFormatter }}</h4>
        </div>
        <v-chip small :class="status.toLowerCase()" label>{{ status }}</v-chip>
      </div>

      <p>
        {{ description | descriptionFormatter(description) }}
      </p>

      <h3>{{ amount | amountFormatter(true) }}</h3>
    </div>
  </div>
</template>
  
  
  
  <script>
import Vue from "vue";
import {
  descriptionFormatter,
  titleFormatter,
} from "@/utils/descriptionFormatter";
import Icon from "@/assets/Icons/CustomIcons/Icon.vue";
import { amountFormatter } from "@/utils/amountFormatter";
import slugify from "slugify";

Vue.filter("descriptionFormatter", descriptionFormatter);
Vue.filter("titleFormatter", titleFormatter);
Vue.filter("amountFormatter", amountFormatter);

export default {
  name: "CrowdProject",
  components: { Icon },
  props: {
    title: {
      type: String,
    },
    status: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    active: {
      type: String,
    },
    type: {
      type: String,
    },
    project: {
      type: Object,
    },
  },

  methods: {
    viewProject() {
      window.scroll(0, 0);
      sessionStorage.setItem("project", JSON.stringify(this.project));
      if (this.type === "private") {
        this.$router.push(
          "/crowdfunding/" + this.project.transactionId
        );
      } else {
        this.$router.push("/project/" + slugify(this.title, { lower: true }));
      }
    },
  },
};
</script>
  
  <style scoped>
.crowdproject {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
  cursor: pointer;
  max-width: 333px;
}

.crowdproject v-img {
  border-radius: 10px 0px 0px 10px;
}

.crowdproject .content {
  padding: 10px 15px 40px 15px;
}

.crowdproject .title {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .crowdproject {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    margin: 0px 0px 15px 0px;
    max-width: 333px;
  }
}

@media only screen and (min-width: 600px) {
  .crowdproject {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    margin: 0px 0px 15px 0px;
    max-width: 280px;
  }
}

.crowdfunding-title {
  font-size: 16px;
  line-height: 21px;
  padding: 5px 0px 0px 1px;
}
</style>