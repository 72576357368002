import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import HomeView from '../views/HomeView.vue'
import WhyView from "../views/WhyView.vue"
import AboutUs from "../views/AboutUs.vue"
import PartnerPage from "../views/PartnerPage.vue"
import EscrowPage from "../views/EscrowPage.vue"
import DigitalBank from "../views/DigitalBank.vue"
import PrivacyPolicy from "../views/PrivacyPolicy"
import TermsOfUse from "../views/TermsOfUse"
import FAQs from "../views/Faqs"
import MerchantPage from "../views/MerchantPage"
import MerchantPayment from "../views/MerchantPayment.vue"
import JoinTransaction from "../views/JoinTransaction.vue"
import CrowdfundingPage from "../views/CrowdFundingPage.vue"
import CrowdFunding from "../views/CrowdFunding.vue"
import AccountDeletion from "../views/AccountDeletion.vue"
import NotFound from "../views/404NotFound.vue"

Vue.use(VueRouter)
Vue.use(Meta,{
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/why-kuleanpay',
    name: 'why',
    component: WhyView
  },

  {
    path: '/about-us',
    name: 'about',
    component: AboutUs
  },

  {
    path: '/partner',
    name: 'partner',
    component: PartnerPage
  },

  {
    path: '/escrow',
    name: 'Escrow',
    component: EscrowPage
  },

  {
    path: '/digital-bank',
    name: 'DigitalBank',
    component: DigitalBank
  },

  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },

  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse
  },

{
    path: "/account-deletion",
    name: "AccountDeletion",
    component: AccountDeletion,
  },

  {
    path: '/faqs',
    name: 'Faqs',
    component: FAQs
  },

  {
    path: '/join-transaction',
    name: 'JoinTransaction',
    component: JoinTransaction
  },

  {
    path: '/merchant',
    name: 'Merchant',
    component: MerchantPage
  },

  {
    path:"/crowdfunding",
    name:"CrowdfundingPage",
    component:CrowdfundingPage
  },

  {
    path: '/crowdfunding/:id',
    name: 'CrowdFunding',
    component: CrowdFunding
  },

  {
    path: '/pay/:id/:id',
    name: 'MerchantPayment',
    component: MerchantPayment
  },
  {
    path:"/:id",
    name:"NotFound",
    component:NotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
