<template>
  <v-main>
    <div class="aboutBg">
      <h1>About Kuleanpay</h1>
      <p>
        We are in the business of providing individuals and corporate bodies a
        secure online payment platform to reduce the growing rate of fraudulent
        transactions online.
      </p>

      <div class="getStartedBtn">
      <v-btn href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1" class="white--text" x-large color="#0582D2">
        Get Started <v-icon small class="ml-2">mdi-arrow-right</v-icon>
      </v-btn>
    </div>
    </div>
  </v-main>
</template>




<script>
export default {};
</script>




<style scoped>
.aboutBg {
  background-image: url("../../assets/aboutImg.png");
}

@media screen and (max-width: 400px) {
  .aboutBg {
    padding: 120px 0px;
  }

  .aboutBg h1{
    color:white
  }

  .aboutBg p{
    color:white
  }
}

@media screen and (max-width: 516px) {
  .aboutBg {
    padding: 120px 0px 120px 30px ;
  }

  .aboutBg h1{
    color:white
  }

  .aboutBg p{
    color:white
  }
}

@media screen and (min-width: 516px) {
  .aboutBg {
    padding: 120px 0px 120px 50px;
  }

  .aboutBg h1{
    color:white
  }

  .aboutBg p{
    color:white;
    max-width:350px
  }
}

@media screen and (min-width: 865px) {
  .aboutBg {
    padding: 120px 50px;
  }

  .aboutBg h1{
    color:white;
    text-align:center
  }

  .aboutBg p{
    color:white;
    text-align:center;
    display:flex;
    justify-content: center;
    margin:auto;
    max-width:500px
  }

.getStartedBtn{
  width:156px;
  margin:20px auto !important
  }
}

@media screen and (min-width: 1045px) {
  .aboutBg h1{
    color:white;
    text-align:center;
    font-size:56px
  }

  .aboutBg p{
    color:white;
    text-align:center;
    display:flex;
    justify-content: center;
    margin:auto
  }

}

@media screen and (min-width: 1168px) {
  
}

@media screen and (min-width: 1500px) {
  
}

@media screen and (min-width: 1900px) {
}
</style>
