<template>
  <div class="crowdproject">
    <v-skeleton-loader type="image"></v-skeleton-loader>
    <div class="content">
      <div class="title">
        <div class="d-flex">
          <h4><v-skeleton-loader></v-skeleton-loader></h4>
        </div>
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </div>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </div>
  </div>
</template>
    
    
    
    <script>
export default {
  name: "LoadingProject",
};
</script>
    
  <style scoped>
.crowdproject {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
  cursor: pointer;
  flex: 1;
  max-width: 400px;
}

.crowdproject v-img {
  border-radius: 10px 0px 0px 10px;
}

.crowdproject .content {
  padding: 10px 15px 40px 15px;
}

.crowdproject .title {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .crowdproject {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    margin: 0px 0px 15px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .crowdproject {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    margin: 0px 0px 15px 0px;
    max-width: 280px;
    flex: 0;
  }
}
</style>