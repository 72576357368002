<template>
  <v-app>
    <NavMenu :bg="'bgblue'" />
    <div class="header">
      <h1>Support A Meaningful Cause</h1>
      <div class="text-field">
        <v-text-field v-model="search" solo prepend-inner-icon="mdi-magnify" />
      </div>
    </div>
    <CrowdFundingProjects :projects="filterProjects" :loading="loading" />
  </v-app>
</template>


<script>
import CrowdFundingProjects from "@/components/CrowdFunding/CrowdFundingProjects.vue";
import NavMenu from "@/components/NavMenu.vue";
import { mapState } from "vuex";
export default {
  components: {
    NavMenu,
    CrowdFundingProjects,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState({
      crowdfundingprojects: "crowdfundingprojects",
      loading: "loading",
    }),
    filterProjects() {
      if (!this.search) {
        return this.crowdfundingprojects;
      }
      return this.crowdfundingprojects.filter((project) => {
        return (
          project.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  metaInfo() {
    return {
      title: `Crowdfunding`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: " Crowdfunding | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("fetchCrowdfundingProjects");
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 25px 0px 20px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 100px 0px 60px;
  }
}

.text-field {
  max-width: 500px;
  margin: 5px auto;
}
</style>