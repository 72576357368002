import { GETURL, HEADERS, LOGINURL } from "@/constants/constants";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import { MOCKCROWDFUNDING } from "@/__mock__/data";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    crowdfundingproject: {},
    crowdfundingprojects: [],
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_PUBLIC_CAMPAIGN(state, project) {
      state.crowdfundingproject = project;
    },
    SET_PUBLIC_CAMPAIGNS(state, projects) {
      state.crowdfundingprojects = projects;
    },
  },
  actions: {
    async fetchCrowdfundingProject({ commit }, value) {
      commit("SET_LOADING", true);
      console.log(value);
      try {
        const response = await axios({
          method: "POST",
          url:
            LOGINURL +
            "settlements/onetimesettlementmgt/crowd-funding/get-all-transactions-public-campaigns-outsideLink",
          headers: {
            channel: "Web",
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          data: {
            crowdFundingLink: `https//www.kuleanpay.com${value}`,
          },
        });
        commit("SET_PUBLIC_CAMPAIGN", response.data.data[0]);
        commit("SET_LOADING", false);
      } catch (error) {
        window.location.href = "/not-found"
        commit("SET_LOADING", false);
        commit("SET_PUBLIC_CAMPAIGN", MOCKCROWDFUNDING);
        commit("SET_LOADING", false);
      }
    },

    async fetchCrowdfundingProjects({ commit }) {
      commit("SET_LOADING", true);
      try {
        const response = await axios({
          method: "GET",
          url:
            GETURL +
            "settlements/onetimesettlementmgt/crowd-funding/get-all-transactions-public-campaigns-outside",
          headers: HEADERS,
        });
        commit("SET_PUBLIC_CAMPAIGNS", response.data.data);
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
      }
    },
  },
});
