<template>
  <v-main style="background: #f8f8f9">
    <div class="twosideHeight">
      <div class="twoSideContainer">
        <div class="twoSideText">
          <h1>Buy & sell with <br />Kuleanpay Escrow</h1>
          <div>
            <Features
              :image="shieldImg"
              :title="'Secure Transactions'"
              :description="'Seamless, transparent and secure transactions.'"
            />
            <Features
              :image="verifiedImg"
              :title="'Quality Services'"
              :description="'Authorize or receive payments seamlessly.'"
            />
            <Features
              :image="deliveryImg"
              :title="'Timely Delivery'"
              :description="'The buyer and seller can track packages in real time.'"
            />
          </div>
        </div>
        <div class="imagePosition">
          <img src="../../assets/Icons/buy&sellwithescrow.svg" />
        </div>
      </div>
    </div>
  </v-main>
</template>




<script lang="js">
import Features from '../Misc/Features.vue'
import verified  from "@/assets/Icons/verified.svg"
import shield from "@/assets/Icons/shield.svg"
import delivery from "@/assets/Icons/delivery.svg"

export default {
   components:{
    Features
   },
   data(){
    return{
        verifiedImg:verified,
        shieldImg:shield,
        deliveryImg:delivery
    }
   }
}
</script>

<style scoped>
.twoSideContainer h1 {
  font-size: 40px;
  line-height: 40px;
  padding: 15px 0px;
  font-weight: 600;
}

.twoSideText {
  margin: 109px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
  .imagePosition {
    margin: 30px;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
    padding: 40px 0px;
  }
  .twoSideContainer h1 {
    font-size: 30px;
    line-height: 30px;
    padding: 10px 0px 10px 0px;
    font-weight: 600;
  }

  .twoSideContainer img {
    width: 90%;
  }

  .twoSideContainer p {
    width: 100%;
  }

  .twoSideText {
    margin: 0px auto;
    width: 90%;
    text-align: start;
  }
  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
}

@media only screen and (min-width: 600px) {
  .imagePosition {
    margin: 50px;
  }

  .imagePosition img {
    width: 500px;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
  }
  .twoSideContainer h1 {
    font-size: 40px;
    line-height: 40px;
    padding: 0px 90px 17px 0px;
    font-weight: 600;
    width: 90%;
  }

  .twoSideContainer p {
    width: 70%;
  }

  .twoSideText {
    margin: 0px 40px;
    padding: 50px 0px 0px 0px;
  }
  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .imagePosition {
    margin: 50px;
  }
  .imagePosition img {
    width: 600px;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
  }
  .twoSideContainer h1 {
    font-size: 44px;
    line-height: 40px;
    padding: 0px 90px 17px 0px;
    font-weight: 600;
    width: 100%;
  }

  .twoSideContainer p {
    width: 70%;
    font-size: 18px;
  }

  .twoSideText {
    margin: 0px 40px;
    padding: 80px 0px;
  }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
}

@media only screen and (min-width: 996px) {
  .imagePosition {
    margin: 50px 0px 0px 0px;
  }

  .imagePosition img {
    width: auto;
  }
  .twoSideContainer p {
    width: 100%;
  }
  .twoSideContainer {
    max-width: 86%;
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
    width: 100%;
  }
  .twoSideText {
    margin: 0px 0px 0px 0px;
    width: 50%;
  }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }

  .textDescription {
    width: 60%;
  }
}

@media only screen and (min-width: 1400px) {
  .twoSideContainer {
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
    width: 100%;
  }
  .twoSideText {
    margin: 0px 0px 0px 0px;
    width: 50%;
  }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
    padding: 60px 0px;
  }
}

@media only screen and (min-width: 1900px) {
  .twoSideContainer {
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
    height: auto;
  }

  .twoSideContainer {
    max-width: 1500px;
    display: flex;
    margin: 100px auto 0px auto;
    justify-content: space-between;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
  }

  .twoSideText {
    margin: 0px 0px 0px 0px;
  }

  /* .twosideHeight{
    height:auto;
    border:0px !important; 
    outline: 0px !important;
} */
}
</style>