export function descriptionFormatter(description) {
    if (description.length > 75) {
      return description.slice(0,75)+"...";
    } else {
      return description;
    }
  }
  
  
  export function donationLinkFormatter(link) {
    if (link.length > 27) {
      return link.slice(0,27)+"...";
    } else {
      return link;
    }
  }
  
  
  

  export function titleFormatter(title) {
    if (title.length > 14) {
      return title.slice(0,14)+"...";
    } else {
      return title;
    }
  }