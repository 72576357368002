<template>
  <div>
    <div>
      <SubscribeComponent />
    </div>
  </div>
</template>





<script>
import SubscribeComponent from "./SubscribeComponent.vue";
export default {
  components: {
    SubscribeComponent,
  },
};
</script>
<style scoped>
</style>