<template>
  <div class="howToGetSection">
    <div class="titleSection">
      <h1>How to get your merchant link</h1>
    </div>

    <div class="bulletSection">
      <div class="bulletItem">
        <img src="../../assets/Icons/bulletImage.svg" />
        <p>
          After you create an account on Kuleanpay via web or mobile, go to
          settings and upgrade your KYC tier to Merchant level.
        </p>
      </div>

      <div class="bulletImage">
        <img src="../../assets/aboutLine.svg" />
      </div>

      <div class="bulletItem">
        <img src="../../assets/Icons/bulletImage.svg" />
        <p>
          Navigate to the merchant section and click on "Generate payment link"
          input your custom link name and generate your link.
        </p>
      </div>
    </div>

    <div class="laptopBulletSection">
      <div class="aboutInfo">
        <img class="circle" src="../../assets/Icons/bulletImage.svg" />
        <img class="line" src="../../assets/Icons/LineVertical.svg" />
        <img class="circleBottom" src="../../assets/Icons/bulletImage.svg" />
      </div>

      <div class="laptopBulletDescription">
        <p>
          After you create an account on Kuleanpay via web or mobile, go to
          settings and upgrade your KYC tier to Merchant level.
        </p>

        <p class="secondParagraph">
          Navigate to the merchant section and click on "Generate payment link"
          input your custom link name and generate your link.
        </p>
      </div>
    </div>
  </div>
</template>
  
  
  
  <script>
export default {};
</script>
  
  
  <style scoped>
@media screen and (max-width: 400px) {
  .titleSection {
    text-align: center;
    margin: 20px 0px;
  }

  .titleSection > h3 {
    font-size: 14px;
  }

  .titleSection > h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .bulletSection {
    padding: 30px 7px 30px 24px !important;
  }

  .bulletSection p {
    font-size: 19px;
    font-weight: 500;
    max-width: 320px;
  }

  .bulletItem {
    padding: 20px 0px;
  }

  .bulletImage {
    max-width: 200px;
    display: none;
  }

  .aboutInfo {
    position: relative;
    margin: 20px 0px 20px 20px;
  }
  .aboutInfo .circle {
    position: absolute;
    top: 0;
  }

  .aboutInfo .line {
    margin: 35px 10px 30px 20px;
  }

  .aboutInfo .circleBottom {
    position: absolute;
    bottom: 0;
    margin-left: -29px;
  }
}

@media screen and (max-width: 516px) {
  .titleSection {
    text-align: center;
    margin: 40px 0px;
  }

  .titleSection > h3 {
    font-size: 14px;
  }

  .titleSection > h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .bulletSection {
    padding: 0px 7px 0px 24px;
  }

  .bulletItem {
    padding: 20px 0px;
  }

  .bulletSection p {
    font-size: 19px;
    font-weight: 500;
    max-width: 320px;
  }

  .aboutInfo {
    position: relative;
    margin: 20px 0px 20px 20px;
  }
  .aboutInfo .circle {
    position: absolute;
    top: 0;
  }

  .aboutInfo .line {
    margin: 35px 10px 30px 20px;
  }

  .aboutInfo .circleBottom {
    position: absolute;
    bottom: 0;
    margin-left: -29px;
  }
}

@media screen and (max-width: 865px) {
  .titleSection {
    text-align: center;
    margin: 30px auto;
    max-width: 580px;
  }

  .titleSection > h3 {
    font-size: 14px;
  }

  .titleSection > h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .bulletSection {
    padding: 0px 7px 0px 24px;
  }

  .bulletSection p {
    font-size: 19px;
    font-weight: 500;
    max-width: 320px;
  }

  .aboutInfo {
    position: relative;
    margin: 20px 0px 20px 20px;
  }
  .aboutInfo .circle {
    position: absolute;
    top: 0;
  }

  .aboutInfo .line {
    margin: 35px 10px 30px 20px;
  }

  .aboutInfo .circleBottom {
    position: absolute;
    bottom: 0;
    margin-left: -29px;
  }
  .laptopBulletSection {
    display: none !important;
  }
}

@media screen and (min-width: 865px) {
  .titleSection {
    text-align: center;
    margin: 30px auto;
    max-width: 580px;
  }

  .titleSection > h3 {
    font-size: 14px;
  }

  .titleSection > h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .bulletSection {
    display: none;
    padding: 0px 7px 0px 24px;
  }

  .bulletSection p {
    font-size: 19px;
    font-weight: 500;
    max-width: 320px;
  }

  .aboutInfo {
    position: relative;
    margin: 20px 40px 20px 20px;
  }
  .aboutInfo .circle {
    position: absolute;
    top: 0;
  }

  .aboutInfo .line {
    margin: 35px 10px 30px 20px;
  }

  .aboutInfo .circleBottom {
    position: absolute;
    bottom: 0;
    margin-left: -29px;
  }

  .laptopBulletSection {
    display: flex;
    max-width: 600px;
    margin: auto;
  }
  .laptopBulletDescription {
    margin: 30px 0px;
    font-weight: 500;
  }

  .laptopBulletDescription p{
    font-size:19px
  }

  .secondParagraph {
    margin: 80px 0px -20px 0px;
    padding: 90px 0px -40px 0px;
  }

  .howToGetSection{
    margin:64px 0px 74px 0px
  }
}

@media screen and (min-width: 1045px) {
}

@media screen and (min-width: 1168px) {
}

@media screen and (min-width: 1500px) {
}

@media screen and (min-width: 1900px) {
}
</style>
  
  
  
  <!-- 
  <style scoped>
  
  @media screen and (max-width: 400px) {
  }
  
  @media screen and (max-width: 516px) {
   
  }
  
  @media screen and (max-width: 865px) {
   
  }
  
  @media screen and (min-width: 865px) {
   
  }
  
  @media screen and (min-width: 1045px) {
   
  
  }
  
  @media screen and (min-width: 1168px) {
    
  }
  
  @media screen and (min-width: 1500px) {
    
  }
  
  @media screen and (min-width: 1900px) {
  }
  </style> -->