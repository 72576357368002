<template>
  <div @click="hideMenu" id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>


<script>
export default {
  mounted() {
    this.$OneSignal.showSlidedownPrompt().then(() => {
      console.log("subscribe user");
    });
  },

  methods: {
    hideMenu() {
      document.getElementById("solutionMenu").style.display = "none";
    },
  },

  metaInfo() {
    return {
      title: `Trust-based Online Payments`,
      titleTemplate: "Trust-based Online Payments | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: "Trust-based Online Payments | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com",
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
};
</script>

<style>
@import "./styles/global.css";
</style>

