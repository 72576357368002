<template>
  <v-breadcrumbs @click="click" class="breadcrumbs" :items="items">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>



<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    click() {
      alert("he")
      this.$emit("click");
    },
  },
};
</script>



<style scoped>
@media screen and (max-width: 600px) {
  .breadcrumbs {
    padding: 30px 10px 0px 14px;
    margin: 0px;
  }
}

@media screen and (min-width: 600px) {
  .breadcrumbs {
    padding: 30px 10px 0px 15px;
    margin: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .breadcrumbs {
    padding: 30px 10px 0px 0px;
    margin: 0px;
  }
}
</style>