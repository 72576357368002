<template>
  <div id="howitworks" class="howItWorks">
    <h2>How it works</h2>

    <EscrowFeatureTwoSideVue
      :title="'Create Transaction'"
      :description="'Signup on kuleanpay via web or mobile to create a transaction. Enter the details on the transaction and click create transaction'"
      :image="'createTransact'"
    />

    <EscrowFeatureReverseTwoSide
      :title="'Join Transaction'"
      :description="'The other party instantly gets notified of the transaction you created with a transaction link via email and SMS, and clicks on Join Transaction'"
      :image="'joinTransact'"
    />

    <EscrowFeatureTwoSideVue
      :title="'Make Payment'"
      :description="'After transaction is accepted by the other party, you will be prompted to make payment. Once payment is made, the other party would see the payment made but cannot access it.'"
      :image="'makePayment'"
    />

    <EscrowFeatureReverseTwoSide
      :title="'Order Processing'"
      :description="'The other party processes the order and delivers the product or service as expected. Once you are satisfied with the order, you would be prompted to confirm delivery.'"
      :image="'orderProcessing'"
    />


    <EscrowFeatureTwoSideVue
      :title="'Release Funds'"
      :description="'Once you confirm delivery of the product or service, the funds would be released into the other party\'s wallet and transaction is completed.'"
      :image="'releaseFunds'"
    />
  </div>
</template>



<script>
import EscrowFeatureTwoSideVue from "./Features/EscrowFeatureTwoSide.vue";
import EscrowFeatureReverseTwoSide from "./Features/EscrowFeatureReverseTwoSide.vue";
export default {
  components: {
    EscrowFeatureTwoSideVue,
    EscrowFeatureReverseTwoSide,
  },
};
</script>





<style scoped>
@media screen and (max-width: 400px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
}

@media screen and (max-width: 516px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1045px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1168px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1500px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1900px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}
</style>


<!-- I pass an image a prop in this component -->