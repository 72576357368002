import { render, staticRenderFns } from "./AboutTwoSide.vue?vue&type=template&id=768a455d&scoped=true&"
import script from "./AboutTwoSide.vue?vue&type=script&lang=js&"
export * from "./AboutTwoSide.vue?vue&type=script&lang=js&"
import style0 from "./AboutTwoSide.vue?vue&type=style&index=0&id=768a455d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768a455d",
  null
  
)

export default component.exports