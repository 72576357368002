<template>
  <v-app>
    <NavMenuVue :bg="'bgblue'"/>
    <div class="header">
      <h1>FAQs</h1>
    </div>

    <div class="container">
    <h2 class="my-3">What is Kuleanpay?</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in items" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    <h2 class="mt-9 my-3">Safety & Security</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in safetyItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h2 class="mt-9 my-3">Transactions</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in transactionsItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


      <h2 class="mt-9 my-3">BVN & Transfer Limits</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in bvnItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


      <h2 class="mt-9 my-3">Account Limits</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in accountItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h2 class="mt-9 my-3">BVN & Transfer Limits</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in bvnItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


      <h2 class="mt-9 my-3">Escrow Funds</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in escrowItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


      <h2 class="mt-9 my-3">Kuleanpay Wallet</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in kuleanPayItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h2 class="mt-9 my-3">Support</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in supportItems" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="card-text" v-html="item.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>




    </div>
    <app-section />
    <footer-section />
  </v-app>
</template>
  
  
  <script>
import NavMenuVue from "@/components/NavMenu.vue";
import FooterSection from "@/components/Home/FooterSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
export default {
  name: "FAQsPage",
  data: () => ({
    items: [
      {
        title: "What is Kuleanpay?",
        description:
          "Kuleanpay is a one stop payment solution for individuals, businesses, marketplaces, and ecommerce platforms. It allows you to make secure payments for goods and services.",
      },
      {
        title: "What is an Escrow? ",
        description:
          "Escrow refers to a neutral third-party holding assets or funds before they are transferred from one party in a transaction to another. It is an account which holds money in trust by a trusted 3rd party for both the buyer and seller during a commercial transaction.",
      },
      {
        title: "When should I use Kuleanpay?",
        description: `<ul><li>When the transaction is of high value.</li>
                <li>When you are conducting your first business transaction and want to avoid the associated uncertainty of service delivery.</li>
                <li>When the transaction is only temporary or short term.</li>
                <li>When both the buyer and the seller are new to the transaction and not familiar with each other.</li>
</ul>`,
      },
      {
        title:"How does Kuleanpay work?",
        description:"Kuleanpay protects Buyers and Sellers in a transaction. Kuleanpay holds the funds in trust while goods and/or services are delivered from Seller to the Buyer. Kuleanpay only releases the funds once the Buyer has received and accepted the goods and/or services. "
      },
      {
        title:"How do I start using Kuleanpay?",
        description:"Visit the website or download the app to create an account. Set up your account and you’re good to go."
      },
      {
        title:"How much can I start with?",
        description:"You can start with transactions as low as N1000."
      },
      {
        title:"Are there bank charges when you deduct from my account?",
        description:"There are no bank charges when internal transactions are carried out, however during pay-out to your local bank account, the bank charges range from N10.75 – N53.75 depending on the transaction amount. For escrow transaction charges, please refer to the escrow fees section."
      }
    ],

    safetyItems:[
    {
        title:"How safe is Kuleanpay?",
        description:`Our data is encrypted and stored in accordance with PCIDSS Level 1 standards. <br/> PCIDSS Level 1 compliance is a set of rules established by credit card companies and audited by a third party. <br/> It is the highest rating possible in the electronic payment processing industry. Furthermore, all transmissions to our banking partner and on our website are encrypted 256-bit HTTPS SSL connections. We also have a device authorisation system which prevents your account from an unauthorised login`
      },
      {
        title:"What is OTP?",
        description:"OTP means One Time Password. It is a code sent to you to verify user authorisation. It adds an extra layer of security to your account."
      },
      {
        title:"How does 2FA work on Kuleanpay?",
        description:"2FA means Two Factor Authentication. It is an extra layer of verification after you have logged in with your credentials. We also send OTP to your phone to verify your authorisation."
      },
      {
        title:"Does Kuleanpay have an office?",
        description:`Yes, we have a head office in Lagos at 44 Adebayo Doherty Rd, Lekki Phase I, Lagos Nigeria.<br/>Contact Number: +234 916 292 4534 <br/>Email: <a href="mailto:info@kuleanpay.com">info@kuleanpay.com</a>`
      },
      {
        title:"Are my card details safe?",
        description:"Because your security is our top priority, we use a PCIDSS-compliant payment processor, Paystack, to handle your credit card information.  <br/>We do not store your card details, so it is 100% safe to use your card on Kuleanpay"
      },
      {
        title:"How secure is my money?",
        description:`We operate in partnership with Providus Bank which is licensed by CBN, so your money is safe and resident in your virtual account. <br/>For escrow transactions, your money is also held securely by us until transaction completion has been confirmed. `
      },
      {
        title:"What is device change?",
        description:"This happens when you log in to a new device that has not been authorised. You will need to authorize that device before your previous device will be deactivated. Also note that you are allowed to authorize one device at a time."
      },
    ],

    transactionsItems:[
    {
        title:"Who can create a transaction? ",
        description:`Both buyers and seller can create a transaction on Kuleanpay.`
      },
      {
        title:"How do I create a transaction?",
        description:"Click on the create transaction tab and fill out the details of the transaction to create a transaction."
      },
      {
        title:"How do I join a transaction?",
        description:"You will get an SMS and an email notification if you are required to join a transaction. You can also join a transaction by inputting the transaction ID in the join transaction tab."
      },
      {
        title:"How do I check the status of my transaction?",
        description:`When logged in to your account, your transaction status is displayed on the dashboard. Or you can navigate to the transactions tab to view the status of your transaction.`
      },
      {
        title:"How do I cancel a transaction? ",
        description:"Tap on the transaction to view the transaction details and you will see the cancel option. Make sure you agree with the other party before you proceed with cancellation."
      },
      {
        title:"What fees will be charged?",
        description:`Transaction fees and Escrow fees as applicable. Refer to the escrow fees section. `
      },
      {
        title:"Who pays the service fee?",
        description:"Based on agreement, the buyer or the seller can pay for the service fees."
      },
      {
        title:"When are funds released to the Seller?",
        description:"Funds are released when the transaction is completed and both parties confirm."
      },
    ],

    bvnItems:[
        {
            title:"Why do I have to add my BVN?",
            description:"BVN is necessary for verification of user’s identity. This ensures another level of safety for your funds and transactions."
        },
        {
            title:"What if I no longer have access to phone number linked to my BVN?",
            description:"In a situation, where you no longer have access to the phone number linked to your BVN, kindly contact your bank to update the phone number."
        },
    ],
    accountItems: [
        {
            title:"What are account limits/Tiers?",
            description:`Account tiers represent levels tied to user accounts that stipulates the restrictions assigned to your level and account limits are in accordance with CBN Tier Levels. <br/> <br/>
            <table style="width: 100%;border: 1px solid black; border-collapse: collapse">
                <tbody>
                    <tr>
                        <td style="padding:10px;border: 1px solid black; border-collapse: collapse">
                        <div><strong>Tier 1</strong></div>
                        <div>Daily Limit - N20,000</div>
                        <div>Per Transaction - N20,000</div>
                        <div>Maximum Balance - N20,000</div>
                        </td>
                        <td style="padding:10px;border: 1px solid black; border-collapse: collapse">
                            <div><strong>Tier 2</strong></div>
                            <div>Daily Limit – N100,000</div>
                            <div>Per Transaction – N50,000</div>
                            <div>Maximum Balance - N200,000</div>

                        </td>
                    </tr>

                    <tr>
                        <td style="padding:10px;border: 1px solid black; border-collapse: collapse">
                            <div><strong>Tier 3</strong</div>
                            <div>Daily Limit – N5,000,000</div>
                            <div>Per Transaction – N2,000,000</div>
                        </td>
                        <td style="padding:10px;border: 1px solid black; border-collapse: collapse">
                            <div><strong>Tier 4</strong></div>
                            <div>Daily Limit – N20,000,000</div>
                            <div>Per Transaction – N5,000,000</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="padding:10px; border: 1px solid black; border-collapse: collapse">
                            <div>Maximum Balance - Unlimited</div>
                        </td>
                        <td style="padding:10px; border: 1px solid black; border-collapse: collapse">
                            <div>Maximum Balance - Unlimited</div>
                            
                        </td>
                    </tr>

                </tbody>
            
            </table>
            `
        }
    ],


    escrowItems:[
        {
            title:"What is Escrow Fund?",
            description:"Escrow funds are payments made that are linked to specific transactions. The money is kept by us during a transaction is called escrow funds."
        },
        {
            title:"How much are Escrow Fees?",
            description:`Transactions Below N200,000 - 1.8% of transaction value + N100 Convenience fee.<br/> Transactions between N200,000 - N500,000 - 1.7% of transaction value + N100 Convenience fee. <br/>Transactions for N500,000 and above - 1.5% of transaction value + N100 Convenience fee`
        },
        {
            title:"Who pays the Escrow Fees?",
            description:"The Escrow fee can be paid by both parties, who pays is selected when creating a transaction."
        },
    ],

    kuleanPayItems:[
        {
            title:"What is Kuleanpay wallet?",
            description:"Kuleanpay wallet is a virtual wallet where your funds are securely kept by us. Your wallet also serves as a digital bank account."
        },
        {
            title:"How do I fund my wallet?",
            description:"Your wallet can be funded via bank transfer or debit card."
        },
        {
            title:"How do I transfer from my wallet?",
            description:"Funds in your wallet can be transferred out to other bank accounts by clicking the withdraw button, and within few minutes, the money will reflect in your account."
        },
        {
            title:"Are Kuleanpay transfers free?",
            description:"Transfers to Kuleanpay accounts are free. However, transfer to local bank accounts attract a fee that ranges from N10.75 – N53.75 depending on the transaction amount."
        },
        {
            title:"Can I transfer to any bank account?",
            description:"You can transfer from Kuleanpay to any account of your choice within Nigeria."
        },
        {
            title:"What is the minimum/maximum amount I can transfer?",
            description:"Please refer to the Tier Levels."
        },
    ],

    supportItems:[
        {
            title:"How do I contact Kuleanpay support?",
            description:`You can reach out to us by email at <a href="mailto:info@kuleanpay.com">info@kuleanpay.com</a> or call +234 916 292 4534`
        },
        {
            title:"Where is Kuleanpay office?",
            description:"We are located at 44 Adebayo Doherty Rd, Lekki Phase I, Lagos Nigeria."
        }
    ]
  }),
  created() {
    window.scrollTo(0, 0);
  },
  components: {
    NavMenuVue,
    AppSection,
    FooterSection,
  },

  metaInfo() {
    return {
      title: `FAQs`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: " FAQs | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
};
</script>
  
  
<style scoped>
@media only screen and (max-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 25px 0px 20px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 100px 0px 60px;
  }
}


.v-expansion-panel-header{
    font-family: "PP Radio";
    font-size:17px;
    font-weight:600
}
table {
      width: 100% !important;
    }

table, td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
}
</style>