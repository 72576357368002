import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import OneSignalVue from 'onesignal-vue'
import store from './store'




Vue.use(VueTelInput,{
  defaultCountry:234,
  dropdownOptions:{
      showSearchBox:true
  },
  inputOptions:{
    placeholder:"",
    type:"tel"
  }
},
)

const options = {
  confirmButtonColor: "#cdf2fc",
  confirmButtonTextColor:"#0066f5",
  cancelButtonColor: '#000000',
  background:"#0582d2",
  color:"#ffffff",
  confirmButtonText: `<p style="color:#0284c7">Ok</p>`,
};



 

Vue.config.productionTip = false
Vue.use(VueSweetalert2,options);
Vue.use(OneSignalVue);

new Vue({
  vuetify,
  router,
  store,
  VueSweetalert2,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: 'b21d897a-5cf6-4917-9b3d-a3e5174f1237' });
  }
}).$mount('#app')
