<template>
  <div>
    <div id="semi-circle-progress">
      <div class="relative circle-container">
        <semi-circle-progress
          :percentage="percentage"
          :amount="project.contributions"
          :startDate="project.startDate"
          :strokeColor="strokeColor"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>
    
    <script>
import { semiCircleProgress } from "./ProgressBar/index";
export default {
  name: "SemiCircleProgress",
  data() {
    return {
      strokeColor: "#0582d2",
    };
  },
  props: {
    project: {
      type: Object,
    },
    percentage: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  watch: {
    percentage(old, cur) {
      if (old !== cur) {
        if (old === 0) {
          this.strokeColor = "";
        }
        if (old === 100) {
          this.strokeColor = "#1B9F04";
        }
      }
    },
  },

  components: { semiCircleProgress },
  created() {
    if (this.percentage === 0) {
      this.strokeColor = "";
    }
    if (this.percentage < 100 && this.percentage > 0) {
      this.strokeColor = "#0582d2";
    }
    if (this.percentage >= 100) {
      this.strokeColor = "#1B9F04";
    }
  },
};
</script>
    
    <style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#semi-circle-progress {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 60px 0px 0px 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.circle-container {
  margin: 0px 0px 10px 0px;
}

@media only screen and (max-width: 600px) {
  .start-date {
    position: absolute;
    top: 0;
    left: 0;
    margin: 170px 0px 0px -9px;
    z-index: 50;
  }

  .end-date {
    position: absolute;
    top: 0;
    right: 0;
    margin: 170px -9px 0px 0px;
    z-index: 50;
  }
}

@media only screen and (min-width: 600px) {
  .start-date {
    position: absolute;
    top: 0;
    left: 0;
    margin: 170px 0px 0px -10px;
    z-index: 50;
  }

  .end-date {
    position: absolute;
    top: 0;
    right: 0;
    margin: 170px -10px 0px 0px;
    z-index: 50;
  }
}
</style>
  
  