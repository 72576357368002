<template>
<v-main class="appHeight">
    <v-card class="appCard">
        <div class="mt-5 appText">
            <p>MOBILE APP</p>
            <h1>Download the Kuleanpay app</h1>
            <div class="flexButton mt-7">
            <v-btn class="buttonStyle mr-3" rel="noopener noreferrer"  href="https://apps.apple.com/ng/app/kuleanpay/id1659853273" target="_blank" large><img src="../../assets/AppStore.svg" alt="appstore" class="mr-3">APP STORE</v-btn>
            <v-btn href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay" rel="noopener noreferrer" target="_blank" class="buttonStyle" x-large><img src="../../assets/GooglePlay.svg" alt="appstore" class="mr-3">GOOGLE PLAY</v-btn>
            </div>
        </div>
        <div class="appImage">
            <v-img class="imageSize" src="../../assets/App.png" />
        </div>
    </v-card>

</v-main>
    
</template>




<script>
export default {
    
}
</script>


<style scoped>
.appHeight{
    height:auto !important;
}
/* .appCard{
    margin:64px auto;

    background: linear-gradient(108.45deg, #013580 -4.61%, #0582D2 104.96%);
    display:flex;
    justify-content: space-between;
} */

.appCard p{
    color:white
}

.appCard h1{
    color:white;
    font-size:56px;
    width:400px;
    line-height:56px;
}

.buttonStyle{
    background: rgba(255, 255, 255, 0.1) !important;
/* Whites/40 */

border: 1px solid rgba(255, 255, 255, 0.4) !important;
border-radius: 8px;
color:white;
padding:25px 19px !important;
border-radius:8px
}


@media screen and (max-width:468px) {
    .appCard h1{
    color:white;
    font-size:30px;
    width:100%;
    line-height:33px;
}

.appCard{
    width:100%;
     margin:64px auto;
    padding:50px 70px 0px 100px;
}
}

@media screen and (max-width:600px) {
      .appCard{
    margin:64px auto;
    padding:50px 70px 0px 30px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:block;
}

.appImage{
    margin:40px 0px 0px 0px !important
}

.appHeight{
    height:auto !important;
}

.buttonStyle{
    background: rgba(255, 255, 255, 0.1) !important;
border: 1px solid rgba(255, 255, 255, 0.4) !important;
border-radius: 8px;
color:white;
padding:25px 19px !important;
border-radius:8px;
margin-bottom:20px
}
}


@media screen and (min-width:600px) {
      .appCard{
    width:100%;
    margin:64px auto;
    padding:50px 70px 0px 30px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:block;
}

.appImage{
    margin:40px 0px 0px 0px !important
}

.appHeight{
    height:auto !important;
}
}


@media screen and (min-width:768px) {
   
      .appCard{
    width:100%;
    margin:64px auto;
    padding:50px 70px 0px 30px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:flex;
    justify-content: space-between;
}

.appImage{
    margin:15px 0px 0px 0px !important;
    width:40%
}

.imageSize{
    height:100%
}



.appHeight{
    height:auto !important;
}
}

@media screen and (min-width:996px) {
      .appCard{
    width:100%;
    margin:64px auto;
    padding:50px 70px 0px 30px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:flex;
    justify-content: space-between;
}

.appImage{
    margin:15px 0px 0px 0px !important
}

.appHeight{
    height:auto !important;
}

}




@media screen and (min-width:1200px) {
      .appCard{
    max-width: 1080px;
    margin:64px auto;
    padding:50px 100px 0px 40px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:flex;
    justify-content: space-between;
}

.appImage{
    margin:15px 0px 0px 0px !important
}

.appHeight{
    height:auto !important;
}
}


@media screen and (min-width:1500px) {
      .appCard{
    max-width:1200px;
    margin:64px 100px;
    padding:50px 100px 0px 100px !important;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:flex;
    justify-content: space-between;
}

.appImage{
    margin:15px 0px 0px 0px !important
}

.appHeight{
    height:auto !important;
}
}

@media screen and (min-width:1600px) {
    .appCard{
    max-width:1500px !important;
   
    margin:64px auto !important;
    padding:50px 70px 0px 70px;
    background: linear-gradient(108.45deg, #04639F -4.61%, #24A6FA 104.96%);
    display:flex;
    justify-content: space-between;
}
}

</style>