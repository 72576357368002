<template>
<v-app>
    <NavMenu :bg="'bgHome'"/>
    <BannerJumbotron />
   <OfferComponent/>
   <TwoSide />
    <ReverseTwoSide/>
    <MerchantCollection />
    <best-way/>
    <CtaSection/>
    <AppSection />
    <FooterSection/>
    <WhatsApp />
  </v-app>
</template>




<script>
import NavMenu from "../components/NavMenu.vue"
import BannerJumbotron from "../components/Home/BannerJumbotron.vue"
import OfferComponent from "../components/Home/OfferComponent.vue"
import TwoSide from "../components/Home/TwoSide.vue"
import ReverseTwoSide from "../components/Home/ReverseTwoSide.vue"
import CtaSection from "../components/Home/CtaSection"
import AppSection from "../components/Home/AppSection.vue"
import FooterSection from "../components/Home/FooterSection.vue"
import MerchantCollection from "@/components/Home/MerchantCollection.vue"
import BestWay from '@/components/Home/BestWay.vue'
import WhatsApp from "@/components/WhatsApp/WhatsApp.vue"


export default {
  components:{
    NavMenu,
    BannerJumbotron,
    OfferComponent,
    TwoSide,
    ReverseTwoSide,
    CtaSection,
    AppSection,
    FooterSection,
    MerchantCollection,
    BestWay,
    WhatsApp
  },

  metaInfo() {
    return {
      title: `Trust-based Online Payments`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },

  created(){
    window.scrollTo(0, 0);
  }
}
</script>


<style scoped>
.blueText{
  color:#0582D2
}


@media only screen and (max-width:600px){
  .blueText{
    margin:10px 0px
  }
}
</style>
