<template>
  <div class="escrowSection">
    <div class="escrowContainer">
      <h1> {{ title }}</h1>
      <p>
       {{ description }}
      </p>

      <div class="flexButtons">
        <v-btn href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1" target="_blank" depressed class="primaryBtn" x-large>
          Get Started <v-icon>mdi-chevron-right</v-icon></v-btn
        >

        <v-btn href="#howitworks" depressed x-large outlined class="outlineBtn">
          How it Works <v-icon small>mdi-arrow-down</v-icon></v-btn
        >
      </div>

      <div class="imagePosition">
      <v-img  class="my-3 hideOnLaptop" :src="require(`../../assets/${imageSm}.svg`)" />
      <img  class="my-3 hideOnMobile" :src="require(`../../assets/${imageLg}.svg`)" />
    </div>
    </div>
  </div>
</template>



<script>
export default {
  props:{
    imageSm:{
      type:String
    },
    imageLg:{
      type:String
    },
    title:{
      type:String
    },
    description:{
      type:String
    }
  }
};
</script>





<style scoped>
@media screen and (max-width: 400px) {
  .escrowSection {
    background: #f5fbff;
    padding: 64px 24px;
  }
  .primaryBtn {
    color: white;
    background: #0582d2 !important;
    border-radius: 8px;
    width: 100% !important;
  }

  .outlineBtn {
    margin: 10px 0px;
    border-radius: 8px !important;
    width: 100% !important;
  }

  .escrowSection h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .escrowSection p {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 516px) {
  .escrowSection {
    background: #f5fbff;
    padding: 64px 24px;
  }
  .primaryBtn {
    color: white;
    background: #0582d2 !important;
    border-radius: 8px;
    width: 100% !important;
  }

  .outlineBtn {
    margin: 10px 0px;
    border-radius: 8px !important;
    width: 100% !important;
  }

  .escrowSection h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .escrowSection p {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 865px) {
  .escrowSection {
    background: #f5fbff;
    padding: 64px 24px;
  }
  .primaryBtn {
    color: white;
    background: #0582d2 !important;
    border-radius: 8px;
    margin: 0px 20px 0px 0px;
  }

  .outlineBtn {
    margin: 15px 0px 15px 0px;
    border-radius: 8px !important;
  }

  .escrowSection h1 {
    font-size: 48px;
    line-height: 48px;
    max-width:390px
  }

  .escrowSection p {
    padding: 20px 0px;
    max-width:390px
  }
  .hideOnMobile{
    display:none
  }
}

@media screen and (min-width: 865px) {
  .escrowSection {
    background: #f5fbff;
    padding: 64px 24px;
  }

  .escrowContainer{
    max-width:580px;
    margin:auto;
    text-align:center
  }

  .primaryBtn {
    color: white;
    background: #0582d2 !important;
    border-radius: 8px;
  }

  .outlineBtn {
    margin: 0px 0px 15px 15px;
    border-radius: 8px !important;
  }

  .escrowSection h1 {
    font-size: 48px;
    line-height: 48px;
    max-width:490px;
    margin:auto
  }

  .escrowSection p {
    padding: 20px 0px;
  }

  .flexButtons > .v-btn {
    width: 190px !important;
  }

  .flexButtons {
    display: flex;
    justify-content: center !important;
    margin: 0px 0px 40px 0px
  }
  .hideOnLaptop{
    display:none
  }

  .hideOnMobile{
    width:862px !important;
    margin:auto -90px auto auto
  }

  .imagePosition{
    display:flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1045px) {
}

@media screen and (min-width: 1168px) {
}

@media screen and (min-width: 1500px) {
}

@media screen and (min-width: 1900px) {
}
</style>
