<template>
  <div class="cardPayment">
    <v-form
      ref="form"
      lazy-validation
      v-model="valid"
      @submit.prevent="makePayment"
    >
      <label>Amount</label>
      <v-text-field
        v-model="amount"
        :rules="amountRules"
        required
        outlined
        dense
        type="number"
        inputmode="numeric"
      ></v-text-field>

      <label>Email</label>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        required
        outlined
        dense
        type="email"
      ></v-text-field>
    </v-form>

    <div class="mt-3">
      <v-btn
        @click="makePayment"
        :loading="loading"
        large
        class="white--text"
        color="#0582D2"
        block
      >
        Pay
      </v-btn>

      <div class="d-none">
        <Paystack
          id="paystack"
          :amount="parseFloat(amount) * 100"
          :email="email"
          :paystackkey="paystackkey"
          :reference="reference"
          :callback="callback"
          :close="close"
          :embed="false"
          style="width: 100%; margin: auto"
          class="animate__animated animate__fadeInUp animate__fast"
        >
          <v-btn
            block
            outlined
            x-large
            class="
              animate__animated animate__fadeInUp animate__fast
              rounded-lg
              d-flex
              justify-space-between
            "
            :loading="loading"
          >
            <span class="ml-5">Pay with Card </span></v-btn
          >
        </Paystack>
      </div>
    </div>
  </div>
</template>




<script>
import Paystack from "vue-paystack";
import axios from "axios";
import { PAYSTACKKEY } from '@/constants/constants';

export default {
  name: "CardPayment",
  data: () => ({
    amountRules: [
      (v) => !!v || "Amount is required",
      (v) => parseInt(v) > 0 || "Amount cannot be 0 or negative",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    paystackkey: PAYSTACKKEY, //paystack public key
    paystackRef: "",
    amount: "",
    email: "",
    loading: false,
    valid: true,
  }),
  components: { Paystack },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },

  methods: {
    callback(response) {
      this.loading = true;
      this.paystackRef = response.reference;
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=payments/paymentmgt/merchant-collection-link-with-card",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          amount: this.amount,
          payStackRefrence: this.paystackRef,
          transactionRef: this.transactionRef,
          merchantCollectionLink:"https://app.kuleanpay.com/merchant?merchantId="+this.$route.params.id.toUpperCase()
        },
      })
        .then((response) => {
          this.loading = false;
          if (response.data.statusCode == 200) {
            this.$swal({
              icon: "success",
              title: "Payment Successful!",
              text: "You have successfully sent NGN "+this.amount+" as payment to this merchant",
              confirmButtonText: `<p style="color:blue">Ok</p>`,
            });
           this.$refs.form.reset()
          } else {
            this.$swal({
              icon: "warning",
              title: "Something went wrong",
              text: response.data.description,
              confirmButtonText: `<p style="color:blue">Try again</p>`,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          this.$swal({
            icon: "warning",
            title: "Something went wrong",
            text: error.message,
            confirmButtonText: `<p style="color:blue">Try again</p>`,
          });
        });
    },

    close: () => {
      console.log("You closed checkout page");
    },
    async makePayment() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      let paystack = document.getElementById("paystack");
      axios({
          method: "POST",
          url: "https://proxy.kuleanpay.com/api/login?url=payments/paymentmgt/initiate-fund-wallet-with-card-merchant-collection",
          headers: {
            Channel: "Web",
            Accept: "*/*",
            ContentType: "application/json",
          },
          data: {
            transactionAmount: this.amount,
            merchantCollectionLink:"https://app.kuleanpay.com/merchant?merchantId="+this.$route.params.id.toUpperCase()
          },
        }).then((response) => {
          this.loading = false;
          console.log(response);
          if(response.data.statusCode == 200){
          paystack.click();
          this.transactionRef = response.data.data.transactionRef;
          return
          }

          this.$swal({
            icon:"error",
            title:"Something's wrong",
            text:response.data.description,
            confirmButtonText:`<p class='brandcolor'>Ok</p>`
          })
        }).catch((error)=>{
          console.log(error)
          this.loading = false;

        })
    },
  },
};
</script>


<style scoped>
.cardPayment {
  margin: 20px 0px 0px 0px;
}

.v-text-field {
  height: 56px;
}
</style>