<template>
  <div>
    <div class="header">
      <h1>Account Deletion</h1>
    </div>

    <div class="container">
      <div>
        <h3 class="text-center">
          Kindly fill the form below to request a deletion of your account on
          Kuleanpay
        </h3>
        <v-form class="my-4">
          <v-row>
            <v-col md="6" sm="12" lg="6">
              <label>First Name</label>
              <v-text-field
                style="height: 46px"
                dense
                v-model="firstName"
                outlined
              />
            </v-col>
            <v-col md="6" sm="12" lg="6">
              <label>Last Name</label>
              <v-text-field
                style="height: 46px"
                dense
                v-model="lastName"
                outlined
              />
            </v-col>
          </v-row>
          <div style="margin-top: 10px">
            <label>Phone Number</label>
            <vue-tel-input
              @country-changed="countryChanged"
              class="phoneField"
              v-model="phone"
              type="number"
              style="height: 46px; width: 100%; margin: 0px 0px 20px 0px"
              autocomplete="off"
            ></vue-tel-input>
          </div>
          <div>
            <label>Email</label>
            <v-text-field
              style="height: 56px"
              dense
              outlined
              v-model="email"
              :rules="emailRules"
            />
          </div>

          <div>
            <v-checkbox
              v-model="checkbox"
              label="I accept that after completing this form, my account will be permanently deleted from Kuleanpay"
            ></v-checkbox>
          </div>
        </v-form>

        <v-btn
          color="#0284c7"
          @click="requestDeletion"
          block
          x-large
          class="white--text"
          :loading="loading"
          :disabled="!checkbox"
          >Request Deletion</v-btn
        >
      </div>
    </div>
    <app-section />
    <footer-section />
  </div>
</template>
    
    
<script>
import FooterSection from "@/components/Home/FooterSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
export default {
  name: "AccountDeletionPage",
  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    loading: false,
    checkbox: false,
  }),

  created() {
    window.scrollTo(0, 0);
  },
  components: {
    AppSection,
    FooterSection,
  },

  metaInfo() {
    return {
      title: `Account Deletion`,
      titleTemplate: "Account Deletion | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: "Account Deletion | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://app.kuleanpay.com/account-deletion",
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },

  methods: {
    countryChanged(e) {
      this.country = e.dialCode;
    },

    requestDeletion() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$swal({
          icon: "success",
          title: "Deletion request sent successfully",
          text: "We are currently processing your request to delete your account",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
      }, 2000);
    },
  },
};
</script>
    
    
    <style scoped>
@media only screen and (max-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 25px 0px 20px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 40px 0px 0px 90px;
    display: flex;
    justify-content: center;
  }
}

.v-expansion-panel-header {
  font-family: "PP Radio";
  font-size: 17px;
  font-weight: 600;
}
table {
  width: 100% !important;
}

table,
td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
}
</style>
