export function amountFormatter(amount, showBalance) {
    return showBalance
      ? "NGN " +
          parseFloat(parseFloat(amount && amount).toFixed(2)).toLocaleString(
            "en",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )
      : "*******";
  }
  
  export function amountInputFormat(amount) {
    return amount.toLocaleString("en");
  }
  