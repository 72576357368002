<template>
  <v-main class="footer">
    <footer class="footerGrid">
      <div class="footerlogo">
        <img src="../../assets/newKuleanLogo.svg" alt="footerImg" />
        <li class="poweredByText">
          Powered by
          <a class="brandcolor" href="https://thefifthlab.com" target="_blank">
            Fifthlab
          </a>
        </li>
      </div>

      <div>
        <h3 class="footerHeading">QUICK LINKS</h3>
        <ul>
          <li @click="goTo('/escrow')">Escrow</li>
          <li @click="goTo('/digital-bank')">Digital Banking</li>
          <li @click="goTo('/partner')">Partners</li>
          <li @click="goTo('/merchant')">Merchant payment collection</li>
        </ul>
      </div>

      <div>
        <h3 class="footerHeading">PRODUCTS</h3>
        <ul>
          <li @click="goTo('/privacy-policy')">Privacy Policy</li>
          <li @click="goTo('/terms-of-use')">Terms & Conditions</li>
          <li @click="$router.push('/faqs')">FAQS</li>
        </ul>
      </div>

      <div>
        <h3 class="footerHeading">DEVELOPER</h3>
        <ul>
          <li class="relative">Documentation <div class="comingChip">Coming soon</div></li>
          <li class="relative">API <div class="apiChip">Coming soon</div></li>
        </ul>
      </div>

      <div>
        <h3 class="footerHeading">COMPANY</h3>
        <ul>
          <li @click="goTo('/about-us')">About us</li>
          <li @click="goTo('/partner')">Become a partner</li>
          <li @click="goToEmail()">Contact us</li>
          <!-- <li><a href="tel:+234 916 292 4534">Phone: +2349162924534</a></li>
          <li>Address: 44 Adebayo Doherty Rd, Lekki Phase I, Lagos Nigeria.</li> -->
          <div class="d-flex mt-4">
            <img
              @click="gotoFacebook"
              class="mr-2 pointer"
              src="../../assets/facebookfooter.svg"
              alt="facebook"
            />
            <img
              @click="gotoTwitter"
              class="mr-2 pointer"
              src="../../assets/Twitter.svg"
              alt="twitter"
            />
            <img
              @click="gotoInstagram"
              class="mr-2 pointer"
              src="../../assets/Instagram.svg"
              alt="instagram"
            />
          </div>
        </ul>
      </div>
    </footer>

    <div class="hideOnMobile">
      <div class="d-flex justify-end pb-12 mr-7">
        <img src="../../assets/providus.svg" />
        <p class="my-2 providusTitle">Payments processed by Providus Bank</p>
      </div>
    </div>
  </v-main>
</template>


<script>
export default {
  methods: {
    gotoFacebook() {
      window.open("https://web.facebook.com/FifthlabAfrica", "_blank");
    },
    gotoTwitter() {
      window.open("https://twitter.com/FifthlabAfrica", "_blank");
    },
    gotoInstagram() {
      window.open("https://www.instagram.com/fifthlabafrica/", "_blank");
    },
    goTo(slug) {
      window.location.href = slug;
    },

    goToEmail() {
      window.open("mailto:kuleanpay.support@thefifthlab.com");
    },
  },
};
</script>



<style scoped>
.poweredByText {
  list-style-type: disc !important;
}

.brandcolor {
  color: #0582d2 !important;
  text-decoration: underline;
}

a {
  text-decoration: none;
  color: #4c4c4c !important;
}
.footerlogo {
  margin-top: -10px !important;
}

.pointer {
  cursor: pointer;
}

li {
  list-style-type: none;
  margin: 16px 0px 0px 0px;
  cursor: pointer;
  transition: 0.3s;
  transition-property: inherit;
}

li:hover {
  text-decoration: underline;
}

.footerGrid > div {
  margin: 0px 0px 40px 0px;
}

@media screen and (max-width: 490px) {
  .footerGrid {
    padding: 0px 30px 0px 30px !important;
    margin: auto;
    display: block;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 490px) {
  .footerGrid {
    padding: 4% 30px 0px 30px !important;
    margin: auto;
    display: block;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .footerGrid {
    padding: 90px 30px 0px 30px;
    margin: auto;
    display: block;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
  }

  .hideOnMobile {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .footerGrid {
    padding: 0px 40px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
  }
}

@media screen and (min-width: 768px) {
  .footerGrid {
    padding: 0px 40px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
  }
}

@media screen and (min-width: 956px) {
  .footerGrid {
    padding: 0px 40px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
  }
}

@media screen and (min-width: 1168px) {
  .footerGrid {
    padding: 0px 100px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-height: 300px;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
  }
}

@media screen and (min-width: 1900px) {
  .footerGrid {
    max-width: 1700px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-height: 300px;
  }

  .footerHeading {
    font-size: 14px;
    color: #4f545e;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style-type: none;
    margin: 16px 0px 0px 0px;
    cursor: pointer;
  }
}

.providusTitle {
  font-weight: 500;
}

.comingChip{
  position:absolute;
  top:0;
  font-size:8px;
  color:#0284c7;
  background:#ecfaff;
  padding:3px;
  border-radius:30px;
  margin-left:90px;
  margin-top:-9px
}


.apiChip{
  position:absolute;
  top:0;
  font-size:8px;
  color:#0284c7;
  background:#ecfaff;
  padding:3px;
  border-radius:30px;
  margin-left:16px;
  margin-top:-9px
}

.relative{
  position: relative;
}
</style>