<template>
    <v-app class="merchantBackground">
        <v-main>
            <MerchantPaymentCard :merchantName="merchantName"/>
        </v-main>
    </v-app>
</template>



<script>
import { unslugify } from 'unslugify';
import MerchantPaymentCard from '@/components/Merchant/MerchantPaymentCard.vue';
export default {
    computed:{
        merchantName(){
            return unslugify(this.$route.path.split("/")[2])
        }
    },
    components:{MerchantPaymentCard},
    metaInfo() {
    return {
      title: `Pay ${this.merchantName}`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.merchantName + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.merchantName
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
}
</script>



<style scoped>
.merchantBackground{
    background:#023150
}
</style>