<template>
  <div class="timer">{{ minutes }}:{{ seconds }}</div>
</template>
  
  <script lang="ts">
export default {
  name: "TimerComponent",
  data() {
    return {
      timer: null,
      totalSeconds: 480,
      minutes: 8,
      seconds: "00",
    };
  },

  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.totalSeconds > 0) {
          this.totalSeconds--;
          this.minutes = Math.floor(this.totalSeconds / 60);
          this.seconds =
            this.totalSeconds % 60 < 10
              ? `0${this.totalSeconds % 60}`
              : this.totalSeconds % 60;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
  mounted(){
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>


<style scoped>
.timer {
  color: #0284c7;
  background: #ecfaff;
  width: 90px;
  display: flex;
  justify-content: center;
  padding: 7px;
  margin: 10px auto;
}
</style>