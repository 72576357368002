export const PAYSTACKKEY = "pk_live_fa3c84631a561775a44cff7f825a34dbdd7f120b";
const APIURL = "https://proxy.kuleanpay.com/api/";
export const GETURL = APIURL + "get?url=";
export const POSTURL = APIURL + "post?url=";
export const LOGINURL = APIURL + "login?url=";
export const TOKEN = sessionStorage.getItem("userToken");
export const HEADERS = {
  channel: "Web",
  Accept: "*/*",
  "Content-Type": "application/json",
  Authorization: "Bearer Token",
};
export const SUPPORTEMAIL = "kuleanpaysupport@thefifthlab.com";
export const BRANDCOLOR = "#0582D2";
