<template>
  <v-app>
    <NavMenuVue :bg="'bgblue'" />
    <div class="header">
      <h1>Terms of Use</h1>
    </div>

    <div class="container">
      <p>
        These Terms of Use (the "Terms") represent a legally binding contract
        between you (“the User”) and Kuleanpay ("", "we" or "us"). By accessing
        or using content; services and materials on Kuleanpay's website located
        at <a href="https://kuleanpay.com">www.kuleanpay.com</a> (the
        "Platform"), any of the services provided therein (collectively, the
        "Services") and the materials may include logos, text graphics, videos,
        images, photos, software and other content (collectively, the
        "Materials"), you agree that you have read, understood, accept and agree
        to be bound by these Terms. If you do not agree with any of these Terms,
        do not access or otherwise use the Services.
      </p>

      <h2>Definitions -</h2>
      <p>
        <b>"Account"</b> means (i) an account of a Buyer from which payment for
        the Transaction and related fees will be obtained, (ii) an account of a
        Seller to which payment for the Transaction and other payments will be
        credited, or (iii) an account of a Broker to which payment for the
        Transaction and other payments will be credited and/or related fees will
        be obtained.
      </p>
      <p>
        <b>“Agreement”</b> means this agreement entered into between you and us
        upon your registration as a user on the Platform, subject to these
        Terms;
      </p>
      <p><b>“Buyer”</b> means the User making the Payment Transaction;</p>
      <p>
        <b>"Transaction Detail Screens"</b> means those screens on the Site
        where Users provide all requested information in connection with a
        Transaction;
      </p>
      <p>
        <b>“Transaction ID”</b> means the unique identifier associated with a
        particular Payment Transaction identifying the funds transferred to
        Kuleanpay on behalf of a specific Buyer and Seller;
      </p>
      <p>
        <b>“Escrow”</b> means the legal arrangement in which Kuleanpay
        temporarily holds Funds on behalf of the Users until a particular
        condition has been met.
      </p>
      <p>
        <b>“Escrow Participants”</b> means the buyer(s), sellers and brokers
        participating in an Escrow transaction;
      </p>
      <p>
        <b>“Funds”</b> means the funds transferred to the Kuleanpay under a
        specific Transaction ID pursuant to these Terms and for the purpose of
        effecting the payment of the Transaction between Buyer and Seller;
      </p>
      <p>
        <b>"User"</b> means Buyer(s), Seller(s), and Broker(s) participating in
        a Transaction;
      </p>
      <p>
        <b>“Payment”</b> means the transfer of Funds by Kuleanpay from the Buyer
        to the Seller to satisfy the payment of the relevant Transaction;
      </p>
      <p>
        <b>"Platform"</b> refers to the website for the Services, which can be
        found at www.Kuleanpay.com;
      </p>
      <p><b>“Seller”</b> means the User receiving the Payment Transaction</p>

      <h2>Description of the Service</h2>
      <p>
        The Service is an Internet-based transaction management and escrow-like
        service provided by Kuleanpay through its Platform under the terms of
        this Agreement.
      </p>
      <p>
        We enable you to make or receive immediate or future Payments to or from
        others (a Payment Transaction). In order to enable you to make a Payment
        Transaction, we will set up and maintain a payment account for you (we
        will call this 'your account'). Once we have set up your account, you
        can ask us to make a payment transaction to another person (a payment
        recipient) by paying the amount you want to pay to a payment recipient
        into your payment account and providing us with an instruction on when;
        or, upon satisfaction of what conditions, you wish us to release funds
        to that payment recipient.
      </p>
      <p>
        Our Escrow Services enable you and other Kuleanpay Users to pay funds to
        us to be held in Escrow in respect of arrangements between you and the
        other Kuleanpay Users to make a payment or payments to other Kuleanpay
        Users. Such funds will be paid to us as your, and the other Kuleanpay
        Users', Escrow Agent, and Kuleanpay will hold such funds in trust to be
        dealt with in accordance with these Escrow Terms.
      </p>
      <p>
        When both you and the other Kuleanpay Users who have funded the Escrow
        are agreed that the arrangement in respect of which we hold funds in
        Escrow has been completed, we will pay out the sums that we hold in
        Escrow to the specified Kuleanpay User(s) in accordance with a Release
        Notice as provided in the Escrow Release section of these Escrow Terms.
      </p>

      <h2>Conditions of Use</h2>

      <p>
        If you arrive at the Site through entities linked and/or integrated with
        Kuleanpay or otherwise by or through a third party (e.g., an auction,
        exchange, or Internet-based intermediary that hosts electronic
        marketplaces and mediates transactions among businesses), then you
        authorise such third party to transfer relevant data to Kuleanpay to
        facilitate the Transaction.
      </p>

      <p>
        You accept and acknowledge the following conditions to access and use
        the Platform and any Services:
      </p>

      <p>
      <ul>
        <li>You are over the age of 18;</li>
        <li>
          You only use the Platform and Services for lawful purposes and not for
          Prohibited Transactions; and you represent that you have the capacity
          to form a binding contract in your jurisdiction;
        </li>
        <li>
          You have the authority to bind any business on behalf of which you use
          our Platform;
        </li>
        <li>You register a Kuleanpay Account;</li>
        <li>You have the necessary equipment and access to the internet;</li>
        <li>
          You must provide us with accurate personal and contact information and
          not share your personal information with other Users;
        </li>
        <li>
          You will only represent yourself and will not create false aliases or
          impersonate any other person or entity (with or without their consent)
          in connection with the Platform;
        </li>
        <li>
          You are duly authorised to enter into this Agreement on behalf of any
          User to bind the User and that such User is a natural person of at
          least eighteen (18) years of age.
        </li>
        <li>
          You are not breaking any law by accessing or using this Platform or
          the Services.
        </li>
      </ul>
    </p>

    <h2>Setting up the Escrow</h2>
    <p>When setting up an Escrow with other Kuleanpay Users, you:</p>
    <p>
    <ul>
    <li>Shall ensure you have given to us the details of all of the other Escrow participants who are already or are to sign up and become Kuleanpay Users in accordance with the Kuleanpay Terms and Conditions, comprising their full name, email address and/or mobile phone number (Escrow Participants);</li>
    <li>Will specify the amount(s) that is to be held in Escrow (Escrow Funds) and the amounts to be funded by funding the Escrow Participant(s);</li>
	<li>Will specify the intended recipient(s) of the Escrow Funds and the amounts to be paid to them (if known);</li>
	<li>Shall specify the duration of the Escrow (being a period of not more than 12 calendar months) and the date on which the Escrow shall come to an end, which shall be referred to in these Escrow Terms as the long-stop date;</li>
	<li>Irrevocably authorise and unconditionally instruct us (jointly with the other Escrow Participants who will fund the Escrow) to act as your trustee and Escrow Agent in relation to the Escrow Funds;</li>
	<li>Irrevocably agree and unconditionally instruct Kuleanpay to pay out the Funds to the Escrow Participants specified to receive the funds in the Escrow Release form.</li>
	<li>Once you have provided all the relevant information set out above (Escrow Information), we shall contact the other Escrow Participants and obtain their confirmation that they wish to jointly instruct us with you on the same basis as the Escrow Information provided and we will ensure that they are, or will become Kuleanpay Users. If the other Escrow Participants do not become Kuleanpay Users, we will not proceed with the Escrow.</li>
    </ul>
</p>

<h2>Funding the Escrow</h2>
<p>Once we have received confirmation from all the Escrow Participants, in accordance with the setting up of the Escrow Account, we will accept our appointment by each of the Escrow Participants as your Escrow Agent on the basis of these Escrow Terms and the Escrow information.</p>
<p>Each of the Kuleanpay Users who will be funding the Escrow shall arrange to make a payment to us using the Kuleanpay Account. This means that you will set up a payment order to us through your Account to make an immediate or future-dated payment to us of an amount equal to your share of the transaction arrangement from your account. We will then hold the Fund as your Escrow Agent and we shall hold them in Escrow in accordance with the Escrow Agent section of these Terms.</p>
<p>The instruction to make payment to us of your share of the Escrow Funds is a payment order and it can only be revoked or changed in accordance with the provisions of these Terms. Once we have received your share of the Escrow Funds, your ability to change or revoke an Escrow Release will be subject to the Escrow Release section of these Terms.</p>
<p>If we do not receive all the Escrow Funds from all the relevant Escrow Participants by the long-stop date, we shall return all the Escrow Funds we hold at that time to the Escrow Participants who funded them ( to the same source as the Funds were received from).</p>

<h2>Escrow Agent</h2>
<p>Once all the Escrow Funds have been paid to us by each of the funding Escrow Participants:</p>

<p>
    <ul>
        <li>We shall hold the Escrow Funds in Escrow;</li>
        <li>The Escrow Funds will be held in a Kuleanpay payment account which will be a separate and segregated account with a bank that satisfies the standards that would be required for a bank account holding payment funds under the relevant regulation;</li>
<li>We will only deal with the Escrow Funds in accordance with the Escrow Release section of these Terms;</li>
<li>If there are any conflicting notices, claims, demands or instructions given by any of the Escrow Participants in relation to any part of the Escrow Funds, we shall not take any action in relation to the Escrow Funds until we have received clear instructions from all the Escrow Participants or a final order from a court or tribunal of competent jurisdiction directs us;</li>
	<li>Agree that we will have no legal, beneficial or other interest in the Escrow Funds, save as set out in these Terms.</li>

    </ul>
</p>

<p>The Escrow Participants unconditionally agree that any interest accrued or earned in relation to holding any of the Escrow Funds shall be held to the benefit of Kuleanpay.</p>

<p>We may at any time reduce the long-stop date where we believe the arrangement to which the escrow relates carries a particular risk to Kuleanpay as Escrow Agent as detailed below in the When we may refuse to act as Escrow Agent section of these Terms.</p>
<p>We may also, in our absolute discretion, increase the long-stop date upon the request and the agreement of all of the Escrow Participants.</p>
   
<h2>Escrow Release</h2>
<p>We will hold Escrow Funds in Escrow for all the Escrow Participants until we have either: received a valid notice from all of the Escrow Participants to release the Escrow Funds (a Release Notice); or, the specified long-step date for the Escrow has passed with no valid Release Notice having been provided.</p>

<p>A Release Notice shall authorise the release of the Escrow Funds by each of the Escrow Participants in accordance with the Escrow instructions and/or any additional instruction contained within the Release Notice and shall be made by each of the Escrow Participants by either:</p>


<p>
    <ul>
        <li>Completing and sending to us an escrow release instruction form in the form set out in the Kuleanpay escrow appointment form (Escrow Instruction Form), which is available upon request from Kuleanpay; or </li>
<li>Authorising the release of the Escrow Funds via our phone application or online secure customer portal.</li>

    </ul>
</p>

<p>
    The duly and properly completed Release Notices from all of the Escrow Participants shall constitute an irrevocable instruction to us to make the payment(s) from the Escrow Funds to the specified recipients in accordance with the Escrow Instructions and/or any additional instruction contained within the Release Notice. 
</p>

<p>We shall then make payments from the Escrow Funds to the nominated account of the Kuleanpay User who is specified to receive Escrow Funds in the same way as Kuleanpay would make payment from your account under the Kuleanpay Payment Services Terms.</p>

<p>If there is any balance of Escrow Funds after we have made all payments in accordance with the Release Notice, the balance shall be returned to the Escrow Participants in the proportions they funded the Escrow Funds in the same way as we would return funds to a Kuleanpay User under the Kuleanpay Payment Services Terms i.e. to the same source as the funds were received from.</p>

<p>
    If we do not receive a duly completed Release Notices from all the Escrow Participants as specified above by the specified long-stop date, and no valid request has been received to extend the long-stop date - to which we have agreed - we shall return all the Escrow Funds we hold to the Escrow Participants who funded them, in the same way as we would return funds to a Kuleanpay User under the Kuleanpay Payment Services Terms, i.e. to the same source as the funds were received from.
</p>


<h2>Limits on the Services </h2>

<p>The Services are only available for lawful items and items not otherwise excluded by the Prohibited Transactions below. Limitations on the Services may apply and can be found on the Site. Only registered Users may use the Services. In order to register, you must supply all information required on the Site. Applicable laws and regulations may further limit the Services.</p>


<h2>Prohibited Transactions </h2>
<p>Users shall not utilise the Site or the Services in connection with any Transaction that is illegal or involves any illegal items, or is for any illegal purpose; involves any obscene material; involves any ammunitions or firearm; involves pirated software, DVD or videos or item(s) otherwise infringing copyrighted works; involves illegal drugs or controlled substances; the sale or transfer of liquor licenses, gambling and sale of electronic currencies. or involves transactions directly or indirectly involving persons (individuals or entities). In addition, Kuleanpay, in its sole discretion, may refuse to complete any Transaction that Kuleanpay has reason to believe is unauthorised or made by someone other than you, may violate any law, rule or regulation, or if Kuleanpay has reasonable cause not to honour it. Each User agrees to indemnify and hold Kuleanpay harmless for losses resulting from any use or attempted use of the Services in violation of this Agreement.</p>


<h2>Rejection of Payment  </h2>
<p>Since the use of a bank account, credit card or debit card account or the making of an electronic funds transfer may be limited by your agreement with your financial institution and/or by applicable law, Kuleanpay is not liable to any User if Kuleanpay does not complete a Transaction as a result of any such limit, or if a financial institution fails to honour any credit or debit to or from an Account. Kuleanpay may post operating rules related to payment on the Site and change such rules from time to time.</p>


<h2>When we may refuse to act as Escrow Agent</h2>
<p>We may refuse to act or decline or revoke our appointment as Escrow Agent or delay the execution of any Release Notice to pay any or all of the Escrow Funds where we reasonably believe or have any suspicion that:
</p>


<p>
    <ul>
 <li>We may be in breach of any applicable law or face action from a regulator or other authority;</li>
 <li>The transfer of the Escrow Funds may be linked to activity that breaches any applicable law, constitutes illegal activity or is in breach of any other agreements with Kuleanpay;</li>
 <li>A material event has happened with the Nigerian banking or financial system that reasonably restricts or prevents us from executing any of the instructions, notwithstanding all reasonable attempts made by us to prevent such an occurrence;</li>
 <li>We have a reasonable belief that any authentication on our Platform has not been properly authorised or permitted or has been in any other way intercepted or compromised;</li>
 <li>We have reasonable knowledge or suspicion that any of the Escrow Participants are subject to a freezing order or any other sanctions;</li>
 <li>The funding Escrow Participants have not provided the Escrow Funds in accordance with these Escrow Terms.</li>

    </ul>
</p>

<h2>Obligations of Sellers</h2>

<p>On the Transaction Detail Screens, the Seller to a Transaction must designate an Account to which payment for the Transaction will be made. The Seller in a Transaction shall deliver the items set forth in Transaction Detail Screens directly to the Buyer (or Buyers) at the address specified by such Buyer as shown on the Kuleanpay website and these Terms. The Seller shall use a delivery service that confirms delivery and the Seller shall provide Kuleanpay with a tracking or reference number for the shipment of the goods. The Seller gives Kuleanpay permission to act as its agent in communicating with the shipping company regarding the notice of the delivery of the goods. In the event Kuleanpay does not receive notice of shipment from Seller within 10 (ten) calendar days of notification from Kuleanpay notice to the Seller to ship the items, the Seller authorises Kuleanpay to return the escrowed funds (excluding Kuleanpay fees) to Buyer. In the event of a return of the items by Buyer, Seller shall notify Kuleanpay of the receipt of the returned items. Upon receipt of such notice from Seller, the Seller's five (5) day inspection period shall commence. In the event the Seller accepts the returned items within the inspection period or fails to act within the inspection period, Kuleanpay shall remit the escrowed Funds (excluding Kuleanpay fees) to the Buyer. If the Seller notifies Kuleanpay of its non-acceptance of any returned items within the Seller's inspection period, then Kuleanpay will retain the escrow funds pending dispute resolution or take other action as authorised. Notwithstanding anything to the contrary above, if all Users to a Transaction agree on the Transaction Detail Screens that there is no shipping required, then no party hereto will have any obligation under this Agreement with respect to shipping.</p>

<p>In the case the Transaction involved is that of a digital product or payment for a service, the Seller shall ensure that the product or service (the “Product”): is delivered within the agreed timeline; error-free; fully address each of the Buyer's defined requirements; are a complete set of the Product as agreed in the Buyer’s requirements; the Product is of a standard consistent with the level of expertise indicated in the Seller's profile and proposals. If the Buyer agrees on the Transaction Detail Screen to be satisfied with the Product, the Escrow Fund will be paid to the Seller.</p>

<h2>Obligations of Buyers </h2>
<p>On the Transaction Detail Screens, the Buyer must designate a payment mechanism and an Account from which the purchase price and related fees (unless such fees are to be paid by the Seller) will be obtained for the deposit into escrow. Depending on the amount of the Transaction and the currency selected for the Transaction, the Buyer may remit the necessary funds via various methods, which may include corporate credit card, charge card, debit card or purchasing card, check (cheque), cashier's check, money order, ACH Debit or Wire Transfer. In the case of wire transfers, the Buyer will initiate the wire to an account designated by Kuleanpay on or before the date set forth in the Transaction Detail Screens. Regardless of the payment method, Buyer authorises Kuleanpay and its authorised agents to initiate credit or debit transactions, as applicable, to obtain the purchase price and fees due for a Transaction and to initiate any debit or credit entries or reversals, as the case may be, as may be necessary to correct any error in a payment or transfer and to discharge Buyer's obligations under this Agreement. Kuleanpay will deposit funds received from Buyer into an escrow trust account maintained by Kuleanpay (the "Escrow Account"). Unless otherwise requested, as specified in the following sentence, escrowed deposits do not earn interest for the Buyer or Seller. If you anticipate an extended closing of the Transaction, then you may request and approve an instruction to have Kuleanpay place the Buyer's funds into an interest-bearing account for the benefit of the Buyer or Seller. If interest is to accrue to the benefit of the Seller, then both the Buyer and Seller must request and approve the establishment of the interest-bearing account. If this request is made, then Kuleanpay will charge the account of the party to whom the interest accrues an additional nonrefundable service charge of N10,000, which must be paid in advance.</p>

<p>Buyer shall notify Kuleanpay of the receipt or non-receipt of the items on the date the merchandise is received or the Buyer Inspection Period is started. Buyer shall notify Kuleanpay of the Buyer's acceptance or rejection of the items before the Buyer's Inspection Period expires. Upon receipt of notice from Buyer that the items have been received and accepted, Kuleanpay shall transfer the payment amount (less any amount payable to Kuleanpay for Kuleanpay fees) to Seller's Account. Transfer to a Seller generally will be initiated within the next business day from the day on which notice of acceptance of the items is received from the Buyer. If Buyer has not notified Kuleanpay of the non-receipt or rejection of the items during the Buyer's Inspection Period, then Buyer authorises Kuleanpay to remit the escrowed funds (excluding Kuleanpay fees) to the Seller. Buyer shall follow the procedures set forth on the Site in the event the items are rejected.</p>

<h2>Our Responsibilities </h2>
<p>Kuleanpay is obligated to perform only those duties expressly described in this Agreement. Kuleanpay shall not be liable for any error in judgment, for any act taken or not taken, or for any mistake of fact or law, except for gross negligence or willful misconduct. Kuleanpay may rely upon any notice, demand, request, letter, certificate, agreement, or any other document which purports to have been transmitted or signed by or on behalf of a User indicated as the sender or signatory thereof and shall have no duty to make any inquiry or investigation. In the event that Kuleanpay is uncertain as to its duties or rights under this Agreement, receives any instruction, demand or notice from any User or financial institution which, in Kuleanpay's opinion, is in conflict with any of the provisions of this Agreement, or any dispute arises with respect to this Agreement or the Escrowed Funds, Kuleanpay may (i) consult with counsel of our choice (including our own attorneys) and any actions taken or not taken based upon the advice of counsel shall be deemed consented to by you, or (ii) refrain from taking any action other than to retain the funds in escrow for delivery in accordance with the written agreement of the Users, the decision of a mediation panel pursuant to a mediation process commenced and conducted in accordance with the applicable rules or a final, non-appealable judgment of a court of competent jurisdiction, or (iii) discharge our duties under this Agreement by depositing all Funds by interpleader action with a court of competent jurisdiction.</p>


<p>Kuleanpay may, at any time, give notice of Kuleanpay's intent to resign as Escrow Agent. If within 10 (ten) days of such notice, Kuleanpay has not received notice from all Users in a Transaction that they have designated a substitute escrow agent (which notice shall identify the substitute escrow agent), Kuleanpay may discharge Kuleanpay duties under this Agreement by depositing all escrowed Funds with a court of competent jurisdiction. If an alternate Escrow Agent is so designated, Kuleanpay shall be discharged from Kuleanpay duties under this Agreement by delivering all escrowed funds to such person or entity. Upon payment of the escrow funds pursuant to this Agreement, Kuleanpay shall be fully released from any and all liability and obligations with respect to the escrow funds and the Transaction.</p>


<h2>Cancelling a Transaction </h2>
<p>If a Transaction cannot be completed for any reason, including cancellation by Kuleanpay for any reason, Kuleanpay will notify each User in such Transaction by e-mail to the e-mail address each has provided to Kuleanpay. In Kuleanpay's sole discretion, Kuleanpay may cancel any Transaction if each User to a Transaction fails to agree on the terms as required in the Transaction Details Screens by clicking the "Agree" button as requested on the Site. You may cancel a Transaction as provided in this Agreement.</p>

<h2>Questions about the Services </h2>
<p>You may inquire about payments made through the Service by calling the number on the Site or by filling out the customer service form. If you believe an error has been made or there has been any unauthorised use of your Account or the Services, you agree to call or send an e-mail as soon as possible but no later than forty-eight hours after you became aware of an error. When you contact Kuleanpay, please be prepared to provide your name, Kuleanpay reference number and the email address you have registered on the Kuleanpay site.</p>

<h2>Statements, Verification </h2>
<p>You agree that all disclosures and communications regarding this Agreement and the Service shall be made by e-mail or on the Site unless the parties make other arrangements.</p>


<h2>Digital Identification</h2>  
<p>You understand and agree that Kuleanpay will create, issue, and verify a digital identification (a "Digital ID") for each User. This Digital ID is attached to each accepted electronic document and notification e-mail. You agree that your Digital ID is a valid "Electronic Signature."</p>


<h2>Our Fees </h2>
<p>Unless otherwise agreed upon by each User in the Transaction, Buyer agrees to pay the fees for the Services that are disclosed on the Site at the time the completed Transaction Escrow instructions are agreed to by all such Users, as well as any other fees, including, without limitation, third party service fees (e.g., shipping, appraisal, inspection, etc.). Once paid, Kuleanpay fees are nonrefundable. Kuleanpay fees may change from time to time at Kuleanpay's absolute and sole discretion. Kuleanpay is not responsible for payment of any sales, use of personal property or other governmental tax or levy imposed on any items purchased or sold through the Services or otherwise arising from the Transaction.</p>



<h2>If payment transactions happen that you did not authorise</h2>
<p>We will normally be liable to you if we act on a payment order that you have not authorised or which you did authorise but which you cancelled within the timescale set out in "Your ability to cancel or change a payment order". In that case, we will repay you any funds taken from you, as a result of the payment being debited. You will have to cover 100% of your losses if this has resulted from your login details being compromised.</p>


<p>There are some circumstances in which you will not have to cover 100% of your losses, This is when:</p>

<ul>
    <li>Your login details came into the hands of the unauthorised User as a result of something done by our employees or agents; </li>
<li>The losses have occurred after you notified us about the potential compromise of your login details or we did not provide a means of you notifying us;
</li>
</ul>


<h2>If there is an error in carrying out a payment order</h2>
<p>All Kuleanpay Users are responsible for providing us with the correct nominated account information. If you are the payment recipient and you have given incorrect nominated account information or have failed to update the information, then the Kuleanpay User making the payment will be considered to have made a valid payment to you and we will have no liability to you if you are unable to recover the funds.</p>



<p>If we fail to make a payment we should have done or if we make a payment into a nominated account of a payment recipient and the account details we paid to are incorrect, if we make a mistake, as soon as we can, we will refund you the amount of the transaction into your account and if you do not want us to re-execute the payment transaction, or we are unable to do so, we will refund you the amount into your nominated account or your funding account as we agree with you. If you have to pay any charges or interest as a result of this happening, we will refund those too.</p>
<p>If you made a mistake, for example, you give us incorrect details for the payment recipient, you set the wrong date for payment, or ask us to make a payment for the wrong amount, we will not be liable to you if you suffer a loss as a result of that or if you lose the funds.</p>
<p>If we are late in paying the payment recipient, we will also be liable to you if you suffer any losses as a result, but we can ask the bank of the payment recipient to treat the payment as having been made on time. </p>
<p>If anything else goes wrong with a payment, but we can show that we were not responsible and we made the payment to the payment recipient's bank as we agreed with you, the other bank will be liable for this and not us. </p>
<p>If funds are paid to the wrong person or are lost, even if we are not at fault, we will make reasonable efforts to recover the funds or trace them and if you write to us to ask for them, we can provide you with the information we have about the outcome of our efforts, including the details of the person who did receive the funds if we have them.</p>


<h2>Security </h2>
<p>Kuleanpay uses a Secure Sockets Layer ("SSL"), a security protocol that provides data encryption, server authentication, and message integrity for connections to the Internet to ensure that the data you provide Kuleanpay is not transmitted over the Internet unencrypted and cannot be viewed by unauthorised individuals. Kuleanpay has also implemented a security system requiring a User ID and a password to access your transactions on the Site. You agree not to give your password to any other person or entity and to protect it from being used or discovered by anyone else.</p>


<h2>Potential Disruption of Service</h2>
<p>You shall be responsible for obtaining and maintaining all telephone, computer hardware, software, and other equipment needed to access and use this Website. Access to this Website may, from time to time, be unavailable, delayed, limited or slowed due to, among other things:</p>



<p>If you made the payment in connection with an online or "distance" purchase of goods or services where you have not come into face-to-face contact with the payment recipient or the provider of the goods or services prior to entering into a legally binding agreement with them unless the type of contract does not attract distance selling protections.</p>
<p>We will also not cover any losses you suffer if you deliberately or very carelessly enabled someone else to place payment orders with us or to give us some other instructions in relation to funds held in your account unless you have told us about it before the losses happen.</p>
<p>If we can show that you have acted fraudulently, we will not be liable to you for any of your losses.</p>
<p>Where we are liable, we will pay you back as soon as we can and no later than by the end of the business day after we became aware of the unauthorised payment unless there is a legal justification for delaying making payment to you.</p>



<h2>Potential Disruption of Service</h2>
<p>You shall be responsible for obtaining and maintaining all telephone, computer hardware, software, and other equipment needed to access and use this Website. Access to this Website may, from time to time, be unavailable, delayed, limited or slowed due to, among other things:</p>
<p>
<ul>
<li>hardware failure, including, among other things, failures of computers (including your own computer), servers, networks, telecommunication lines and connections, and other electronic and mechanical equipment;</li>
<li>software failure, including, among other things, bugs, errors, viruses, configuration problems, incompatibility of systems, utilities or applications, the operation of firewalls or screening programs, unreadable codes, or irregularities within particular documents or other content;</li>
<li>overload of system capacities;</li>
<li>damage caused by severe weather, earthquakes, wars, insurrection, riots, terrorism, civil commotion, an act of God, accidents, fire, water damage, explosion, mechanical breakdown or natural disasters;</li>
<li>interruption (whether partial or total) of power supplies or other utility of service;</li>
<li>strike or other stoppages (whether partial or total) of labour;</li>
<li>governmental or regulatory restrictions, exchange rulings, court or tribunal orders or other human intervention; or</li>
<li>any other cause (whether similar or dissimilar to any of the foregoing) whatsoever beyond our control.</li>
</ul>
</p>


<p>If access to this Website is unavailable, delayed or limited, or if the Platform does not operate quickly and efficiently, you may be unable to transmit your instructions for transactions and other matters, or such instructions may not be promptly executed, or you may be unable to retrieve information on a timely basis. If your operations are dependent on such communications with us, and such communications are disrupted or delayed, you may suffer losses. Kuleanpay will not be liable for any such loss.</p>

<h2>Disclaimers </h2>
<p>You expressly agree that your use of the Services is at your sole risk. The Services are provided on a strictly "as is" and "as available" basis. Kuleanpay MAKES NO WARRANTY WITH REGARD TO THE UNDERLYING TRANSACTION, ANY ITEMS OBTAINED BY YOU THROUGH THE USE OF THE SITE OR THE SERVICES, THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES OR THE SITE WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE.</p>


<p>Kuleanpay expressly disclaims any and all express and implied warranties, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. Kuleanpay shall not be liable or responsible for those guarantees, warranties and representations, if any, offered by any Seller of items. No advice or information, whether oral or written, obtained by you from Kuleanpay or through the Services shall create any warranty not expressly made herein.</p>
<p>You acknowledge and agree that Kuleanpay does not endorse the website of any third-party or assume responsibility or liability for the accuracy of any material contained therein, any infringement of third-party intellectual property rights arising therefrom, or any fraud or other crime facilitated thereby. In no event will Kuleanpay be liable for any act or omission of any third party, including, but not limited to, your financial institution, any payment system, any third-party service provider, any provider of telecommunications services, Internet access or computer equipment or software, any mail or delivery service or any payment or clearing house system or for any circumstances beyond Kuleanpay's control (including but not limited to, fire, flood or other natural disasters, war, riot, strike, terrorism, an act of civil or military authority, equipment failure, computer virus, infiltration or hacking by a third party, or failure or interruption of electrical, telecommunications or other utility services).</p>



<h2>Limitation of Liability </h2>
<p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, NEITHER Kuleanpay NOR ITS AFFILIATES SHALL BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL, RELATING TO YOUR USE OF THE SITE OR THE SERVICES OR YOUR INABILITY TO USE THE SITE OR THE SERVICES.</p>

<h2>Termination of Services</h2>
<p>Kuleanpay may suspend or terminate your use of the Services at any time, without notice for any reason, in Kuleanpay's sole discretion. Except as warranted by risk to the security, privacy or integrity of the Services, Kuleanpay will attempt to provide you with prior notice of the suspension or termination of the Services by sending you an e-mail, but Kuleanpay is not obligated to do so. You shall remain liable for all Transactions you initiate through the Services prior to such termination and the performance of your obligations, including but not limited to the delivery of the items and the payment of all amounts you owe prior to termination or discontinuation of your use of the Services. You agree to pay all costs and expenses (including reasonable attorneys' fees) that Kuleanpay may incur in order to (a) collect any amounts you owe under this Agreement or (b) to initiate a mediation process or judicial proceeding to resolve a dispute between Buyer and Seller, as set forth in these Terms.</p>



<h2>Non-Transferability of the Services</h2>  
<p>You may not assign this Agreement or the Escrow Instructions to any other person or entity. Your right to use the Services shall not be sold or transferred to any other person or entity without the prior written consent of Kuleanpay. Kuleanpay may assign this Agreement upon notice to you. Any assignment or transfer in violation of this provision shall be null and void.</p>

<h2>Modifications  </h2>
<p>Kuleanpay reserves the right to change this Agreement, or any portion of it, at any time, without prior notice, provided that no such change will apply to a Transaction once the Users to such Transaction have agreed to the Transaction Escrow Instructions. You understand that the most recent version of this Agreement will be located on the Site.</p>

<h2>Notices </h2>
<p>Notices from Kuleanpay to you will be given by e-mail or by general posting on the Site. You may contact Kuleanpay by filling out the customer support form or such other email address as Kuleanpay posts as its address for notice on the Site in the most recent version of the Terms of Using the Escrow Platform</p>


<h2>Indemnification </h2>
<p>You agree to indemnify and hold Kuleanpay, Kuleanpay affiliates and their respective officers, directors, shareholders, employees and assigns harmless from any claim, demand, expense or damage, including reasonable attorneys' fees and court costs, arising from or relating to your use of the Services or any violation of this Agreement, the rules contained on the Site or the Transaction Escrow Instructions, including, without limitation, payment of Kuleanpay fees and any chargebacks from a card organisation or reversal or nonpayment of any credit or debit entry.</p>


<h2>Miscellaneous </h2>
<p>In the event of any dispute, claim, question, or disagreement arising from or relating to, this Agreement or to the Underlying Transaction, or breach of any of them, you agree to resolve such dispute in the manner set forth in these Terms.  </p>
<p>This Agreement shall be governed by the laws of Nigeria. If a dispute or difference arises under this Agreement, such dispute or difference shall be settled amicably by the parties within 20 (twenty) days of notification t of the existence of a dispute or claim.</p>
<p>If any such dispute is not settled amicably within 20 (twenty) days, then such dispute or claim shall be settled by mediation under the rules applicable at the Lagos State Multi-Door Courthouse (LMDC). </p>
<p>A single mediator shall be appointed jointly by the Parties within 3 (three) days of the expiry of the 20 (twenty) days reserved for an amicable settlement, failing which, the LMDC shall appoint the mediator.</p>
<p>Kuleanpay's failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Kuleanpay in writing.</p>
<p>If any term or other provision of this Agreement is invalid, illegal or incapable of being enforced by any law or public policy, all other terms and provisions of this Agreement shall nevertheless remain in full force and effect for so long as the economic or legal substance of the transactions contemplated hereby is not affected in any manner materially adverse to any party hereto.</p>
<p>Kuleanpay may assign this Agreement to any current or future affiliated company and to any successor in interest. Kuleanpay also may delegate certain of Kuleanpay's rights and responsibilities under the Agreement to independent contractors or other third parties.</p>
<p>If you are a registered User of the Site, then each time you request the Services will constitute your agreement to these Terms of Using the Escrow Platform, as amended from time to time in Kuleanpay's sole discretion, and evidence that you have read, understood and accepted the then applicable Terms of Using the Escrow Platform.</p>


</div>
    <app-section />
    <footer-section />
  </v-app>
</template>
  
  
  <script>
import NavMenuVue from "@/components/NavMenu.vue";
import FooterSection from "@/components/Home/FooterSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
export default {
  created(){
    window.scrollTo(0,0)
  },
  metaInfo() {
    return {
      title: `Terms of Use`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
  components: {
    NavMenuVue,
    AppSection,
    FooterSection,
  },
};
</script>
  
  
  <style scoped>
@media only screen and (max-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 25px 0px 20px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 100px 0px 60px;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  font-weight: 300 !important;
}
</style>