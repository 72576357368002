<template>
    <v-card class="summaryCard">
      <v-btn @click="closeOtp" fab small depressed class="float-right"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <h2>Verify Phone number</h2>
      <p>A 6 digit OTP has been sent to <span class="brandcolor">{{phone?phone:"your phone"}}</span>. Input code to continue</p>
  
      <v-form ref="form" class="mt-6 pa-1">
        <v-otp-input length="6" type="password" v-model="otp"></v-otp-input>
      </v-form>
      <div class="paymentButton">
        <v-btn
          @click="verifyOtp"
          large
          color="#0582D2"
          block
          class="white--text"
          :loading="verifyloading"
          >Continue</v-btn
        >
      </div>
    </v-card>
  </template>
  
  
  <script>
  export default {
    name: "OtpForm4",
    data() {
      return {
        otp:""
      };
    },
  
    props: {
      verifyloading: {
        type:Boolean
      },
      phone:{
        type:String || Number
      }
    },
    watch:{
      otp(){
        if(this.otp.length === 6){
        this.setOtp()
      }
      }
    },
    methods: {
      setOtp() {
        this.$emit("setOtp", this.otp);
      },
  
      closeOtp() {
        this.$emit("closeOtp");
      },
  
     verifyOtp() {
        this.$emit("verifyOtp");
      },
    },
  };
  </script>
  
  <style scoped>
  
  
  @media only screen and (max-width:600px){
    .centered-input >>> input {
    text-align: center;
    background: #f3f4f6;
    height: 100px;
  }
  
  input{
    background: #f3f4f6;
    width: 34px;
    height: 34px;
    border-radius: 8px;
    text-align: center;
    font-size: 50px;
  }
  
  input:focus,
  input:hover{
    border: 1px solid #a4c9ff !important;
    outline: 1px solid #a4c9ff !important;
  }
  
  .summaryCard {
    padding: 29px 20px;
  }
  
  .paymentButton {
    padding: 50px 0px 20px 0px;
  }
  }
  
  
  @media only screen and (min-width:600px){
    .centered-input >>> input {
    text-align: center;
    background: #f3f4f6;
    height: 100px;
  }
  
  input {
    background: #f3f4f6;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    text-align: center;
    font-size: 50px;
  }
  
  input:focus,
  input:hover {
    border: 1px solid #a4c9ff !important;
    outline: 1px solid #a4c9ff !important;
  }
  
  .summaryCard {
    padding: 24px 40px;
  }
  
  .paymentButton {
    padding: 50px 0px 20px 0px;
  }
  }
  
  </style>