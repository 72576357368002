<template>
  <v-main class="bestWayContainer">
    <div class="whyContainer">
      <div class="imageContainer">
        <img class="gifSize" src="../../assets/verifyblue.svg" alt="aboutgif" />
      </div>

      <h2>
        The best way to save yourself <br />
        from fraudulent transactions
      </h2>
      <p class="description my-3">
        Both buyers and sellers are protected with funds held in a third party
        escrow. Payments are released only after orders are fulfilled.
      </p>
      <div class="my-5">
        <v-btn
          x-large
          class="white--text"
          color="#0582D2"
          href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1"
          target="_blank"
          >Get Started
          <v-icon class="ml-2" small>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>
  </v-main>
</template>


<script lang="ts">
export default {};
</script>


<style scoped>

.gifSize{
  height:262px;
  width:600px
}


@media only screen and (max-width: 600px) {
  
.bestWayContainer {
  background: #011f32;
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  color: white;
}

.whyContainer h2 {
  font-size: 38px;
  line-height: 48px;
  padding: 10px 0px;
}
.whyContainer p {
  max-width: 500px;
  margin: auto;
  font-weight:400
}

.imageContainer {
  width: 20% !important;
  position: relative !important;
  display:none
}
.imageSize {
  position: relative !important;
  height: auto !important;
}

.whyContainer {
    padding: 120px 20px !important;
  }
}



@media only screen and (max-width: 854px) {
  .imageContainer {
  width: 20% !important;
  position: relative !important;
  display:none
}
}

@media only screen and (min-width: 600px) {

  .bestWayContainer {
  background: #011f32;
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  color: white;
}

.whyContainer h2 {
  font-size: 38px;
  line-height: 48px;
  padding: 10px 0px;
}

.whyContainer p{
  font-weight:300
}
  .whyContainer {
    padding: 64px !important;
  }

  .description{
    margin:auto;
    max-width:500px
  }
}
</style>
