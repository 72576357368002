<template>
  <div class="a-circle-progress-wrapper">
    <div class="circle-progress">
      <svg :viewBox="viewBox" :width="2 * r + strokeWidth">
        <path
          :fill="bgColor"
          :stroke-width="25"
          :stroke-linecap="strokeLinecap"
          :stroke="strokeBgColor"
          :d="pathD"
        />
        <path
          class="circle-progress"
          fill-opacity="0"
          :style="aboveCircleStyle"
          :stroke-width="strokeWidth"
          :stroke-linecap="strokeLinecap"
          :stroke="strokeColor"
          :stroke-dasharray="dasharray"
          :stroke-dashoffset="dashoffset"
          :d="pathD"
        />
      </svg>
      <div class="progress-content">
        <slot>
          <div class="inner-default-percentage">
            <div class="mt-12">
              <span>Amount Generated</span>
              <h1 v-if="!loading">{{ amount | amountFormatter(true) }}</h1>
              <h1 v-if="loading"><v-skeleton-loader type="list-item" /></h1>

              <span v-if="!loading">{{ startDate | daysAgoFormatter }}</span>
              <span v-if="loading"><v-skeleton-loader type="text" /></span>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>


<script>
import { amountFormatter } from "@/utils/amountFormatter";
import { daysAgoFormatter } from "@/utils/dateFormatter";
import Vue from "vue";
Vue.filter("amountFormatter", amountFormatter);
Vue.filter("daysAgoFormatter", daysAgoFormatter);

export default {
  name: "semi-circle-progress",
  data() {
    return {};
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    r: {
      type: Number,
      default: 150,
    },
    strokeWidth: {
      type: Number,
      default: 25,
    },
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    strokeBgColor: {
      type: String,
      default: "#c3e7fe",
    },
    strokeColor: {
      type: String,
    },
    strokeLinecap: {
      type: String,
      default: "round",
    },
    amount: {
      type: [Number, String],
      default: 0,
    },
    startDate: {
      type: [Number, String]
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    pathD() {
      let radius = this.r;
      let d = `M${radius * 2} ${radius} A${radius} ${radius} 0 0 0 0 ${radius}`;
      return d;
    },
    viewBox() {
      let cx = this.strokeWidth / 2;
      let cy = this.strokeWidth / 2;
      let w = 2 * this.r + this.strokeWidth;
      let h = this.r + this.strokeWidth;
      return `-${cx} -${cy} ${w} ${h}`;
    },
    leftCircleStyle() {
      return {
        "stroke-width": this.strokeWidth,
      };
    },
    dasharray() {
      let l = (3.14 * 2 * this.r) / 2;
      return `${l}`;
    },
    dashoffset() {
      let offset =
        this.dasharray *
          ((this.percentage > 100 ? 100 : this.percentage) / 100) +
        (3.14 * 2 * this.r) / 2;
      return `${offset}`;
    },
    aboveCircleStyle() {
      return {
        transition: `all ${this.duration}s ease-in-out`,
      };
    },
  },
  methods: {},
};
</script>
  
  <style scoped lang="scss">
.a-circle-progress-wrapper {
  .circle-progress {
    position: relative;
    display: flex;
    justify-content: center;

    .progress-content {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);

      .inner-default-percentage {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}

.success-chip {
  border: 0.01em solid #dafec3 !important;
  color: #1b9f04;
}
</style>