import moment from "moment";

export function dateFormatter(date) {
  return moment(date).format("ll");
}

export function filterMerchantDate(date) {
  return moment(date).format("ll").split(" ").slice(0, 2).join(" ");
}

export function filterDay(date) {
  return moment(date).format("dddd");
}

export function formatDate(date) {
  let dateArr = date.split("-");
  let newDate = []
  for (let i = dateArr.length-1; i >=0; i--) {
    newDate.push(dateArr[i])
    newDate.push("/")
  }
  newDate.pop()
  return newDate.join("")
}


export function daysAgoFormatter(date){
    return moment(date).startOf('day').fromNow();
}