<template>
  <v-app style="height: 100px">
    <div class="largescreen" :class="bg">
      <div
        class="navMenu d-flex justify-space-between"
        :class="bg"
      >
        <img
          class="pointer"
          @click="goTo('/')"
          src="../assets/newKuleanLogo.svg"
          alt="kuleanpaylogo"
        />
        <div class="mt-2 hideWeb">
          <ul class="d-flex">
            <li>
              <span
                :class="route === 'why-kuleanpay' ? 'active' : ''"
                @click="goTo('/why-kuleanpay')"
                >Why Kuleanpay</span
              >
            </li>
            <li @mouseover="showMenu()">
              <span
                :class="
                  route === 'escrow' || route === 'digital-bank' ? 'active' : ''
                "
                >Solution
                <v-icon
                  :class="
                    route === 'escrow' || route === 'digital-bank'
                      ? 'active'
                      : ''
                  "
                  >mdi-chevron-down</v-icon
                ></span
              >
            </li>
            <li>
              <span
                :class="route === 'partner' ? 'active' : ''"
                @click="goTo('/partner')"
                >Partner
              </span>
            </li>
            <li>
              <span
                :class="route === 'about-us' ? 'active' : ''"
                @click="goTo('/about-us')"
                >Company
              </span>
            </li>
          </ul>
        </div>

        <div class="ml-6 hideWeb">
          <v-btn
            href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay"
            outlined
            class="mr-5 brandcolor"
            large
           
            ><v-icon>mdi-google-play</v-icon>Get on Android</v-btn
          >
          <v-btn
            href="https://apps.apple.com/ng/app/kuleanpay/id1659853273"
            color="#0582D2"
            class="white--text"
            large
            
            ><v-icon>mdi-apple</v-icon>Get on IOS</v-btn
          >
        </div>

        <div v-click-outside="closeMenu" class="hideMobile">
          <div class="relative">
            <img
              class="imageSize"
              @click="drawer = !drawer"
              src="../assets/Icons/menuIcon2.svg"
              alt="menuIcon"
            />
            <!-- <input  type="checkbox" id="menu" />
            <label for="menu" class="icon">
              <div ref="menu" class="menu"></div>
            </label> -->
          </div>
        </div>
      </div>
    </div>
    <v-navigation-drawer v-model="drawer" fixed top right temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-blue--text text--accent-4">
          <v-list-item @click="goTo('/why-kuleanpay')"> Why Kuleanpay </v-list-item>

          <v-list-item>
            <v-list text>
              <v-list-group text no-action>
                <template v-slot:activator>
                  <v-list-item-content> Solutions </v-list-item-content>
                </template>

                <v-list-item @click="goTo('/escrow')">
                  <v-list-item-content> Escrow </v-list-item-content>
                </v-list-item>
                <v-list-item @click="goTo('/digital-bank')">
                  <v-list-item-content> Digital Bank</v-list-item-content>
                </v-list-item>
                <v-list-item @click="goTo('/merchant')">
                  <v-list-item-content> Merchant Payment</v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-list-item>

          <v-list-item @click="goTo('/partner')"> Partner </v-list-item>

          <v-list-item @click="goTo('/about-us')"> Company </v-list-item>

          <v-list-item-action>
            <div>
              <v-btn
                href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1"
                color="#0582D2"
                class="white--text mb-3"
                large
                ><v-icon>mdi-google-play</v-icon>Get on Android</v-btn
              >
              <v-btn
                href="https://apps.apple.com/ng/app/kuleanpay/id1659853273"
                color="#0582D2"
                block
                outlined
                large
                ><v-icon>mdi-apple</v-icon>Get on IOS</v-btn
              >
            </div>
          </v-list-item-action>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <div id="solutionMenu" class="solutionMenuWrapper">
      <div class="solutionMenu">
        <SolutionItem
          :title="'Escrow'"
          :description="'Both buyers and sellers are protected and funds held securely by us.'"
          :link="'/escrow'"
        />

        <SolutionItem
          :title="'Digital banking'"
          :description="'Say no to transaction delays and fraudulent transactions as you send and receive money. '"
          :link="'/digital-bank'"
        />

        <SolutionItem
          :title="'Merchants payments collection'"
          :description="'Receive payments  by offering your customers multiple payment options with just one link.'"
          :link="'/merchant'"
        />
      </div>
    </div>
  </v-app>
</template>




<script>
import SolutionItem from "./Menu/SolutionItem.vue";
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [{ title: "Loans" }],
    closeMenu:false
  }),

  components: {
    SolutionItem,
  },

  props: {
    route: {
      type: String,
    },
    bg: {
      type: String,
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    showMenu() {
      document.getElementById("solutionMenu").style.display = "block";
    },

    hideMenu() {
      document.getElementById("solutionMenu").style.display = "none";
    },
    goTo(slug) {
      window.location.href = slug;
    },
  },
};
</script>




<style scoped>
.router-link,
a {
  text-decoration: none !important;
  color: black !important;
}

.navMenu img {
  height: 46px;
  width: 109px;
}

.navMenu li {
  list-style-type: none;
  padding: 0px 0px 0px 40px;
  cursor: pointer;
}

li span a:hover {
  color: #0582d2 !important;
  border-bottom: 2px solid;
  padding-bottom: 6px;
}

.active {
  color: #0582d2 !important;
  border-bottom: 2px solid;
  padding-bottom: 6px;
}

.hideMobile {
  display: block;
}

.bgblue {
  background: #f5fbff;
}

.bgHome{
  background: #f0f7ff 
}

.bggreen{
  background: #E4EFF6
}

@media screen and (max-width: 1045px) {
  .hideWeb {
    display: none;
  }

  .hideMobile {
    display: block;
  }

  /*padding for mobile menu*/
  .navMenu {
    padding: 34px 40px;
  }

  input#menu {
    display: none;
  }

  .icon {
    cursor: pointer;
    display: block;
    height: 24px;
    padding: 16px;
    width: 24px;
  }

  label.icon {
    position: absolute;
    z-index: 200;
  }

  .icon .menu,
  .icon .menu::before,
  .icon .menu::after {
    background: black;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    width: 20px;
  }

  .icon:hover .menu,
  .icon:hover .menu::before,
  .icon:hover .menu::after {
    background: black;
  }

  .icon .menu {
    left: 18px;
    top: 20px;
  }

  .icon .menu::before {
    top: -6px;
  }

  .icon .menu::after {
    top: 6px;
  }

  #menu:checked + .icon .menu {
    background: transparent;
  }

  #menu:checked + .icon .menu::before {
    transform: rotate(45deg);
  }

  #menu:checked + .icon .menu::after {
    transform: rotate(-45deg);
  }

  #menu:checked + .icon .menu::before,
  #menu:checked + .icon .menu::after {
    top: 0;
    transition: top ease 0.3s, transform ease 0.3s 0.3s;
  }
}

@media screen and (min-width: 1045px) {
  .navMenu {
    padding: 34px 30px;
  }
  .hideWeb {
    display: block;
  }

  .hideMobile {
    display: none;
  }
}

@media screen and (min-width: 1168px) {
  .hideWeb {
    display: block;
  }

  .navMenu {
    padding: 34px 100px;
  }
}

@media screen and (min-width: 1900px) {
  .navMenu {
    max-width: 1700px !important;
    margin: 0 auto;
  }
}

.brandcolor {
  color: #0582d2 !important;
}

.imageSize {
  width: 30px !important;
}

.solutionMenu {
  margin: -21px 0px 0px 0px;
  background: white;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  position: relative;
  z-index: 20;
  display: none;
}

.solutionMenuWrapper {
  display: none;
}

.pointer {
  cursor: pointer;
}
</style>
