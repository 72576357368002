<template>
    <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_signup">
          <form
            action="https://thefifthlab.us8.list-manage.com/subscribe/post?u=87c2a8abc51d7a4dc42bce55a&amp;id=6f6c04f841&amp;f_id=00f05de0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <div id="mc_embed_signup_scroll">
              <div class="indicates-required">
                <span class="asterisk">*</span> indicates required
              </div>
              <div class="mc-field-group">
                <label for="mce-FNAME"
                  >First Name <span class="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value=""
                  name="FNAME"
                  class="required"
                  id="mce-FNAME"
                  required
                  placeholder="First Name"
                />
                
              </div>
              <div class="mc-field-group emailField">
                <label for="mce-EMAIL"
                  >Email Address <span class="asterisk">*</span>
                </label>
                <input
                  type="email"
                  value=""
                  name="EMAIL"
                  class="required email roundedInput"
                  id="mce-EMAIL"
                  required
                  placeholder="Enter email address"
                />

                <div class="optionalParent subscribeButton">
                <div class="clear foot">
                  <input
                    type="submit"
                    value="Join"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                </div>
              </div>
               
              </div>
              <div hidden="true">
                <input type="hidden" name="tags" value="4875299" />
              </div>
              <div id="mce-responses" class="clear foot">
                <div
                  class="response"
                  id="mce-error-response"
                  style="display: none"
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                  style="display: none"
                ></div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; display:none" aria-hidden="true">
                <input
                  type="text"
                  name="b_87c2a8abc51d7a4dc42bce55a_6f6c04f841"
                  tabindex="-1"
                  value=""
                />
              </div>
             
            </div>
          </form>
        </div>

</template>


<script lang="ts">
export default {
    name:"MailchimpComponent"
    
}
</script>



<style scoped>


#mc_embed_signup{
    margin:auto auto auto -20px !important
}

input{
    border-radius:40px !important;
    padding:20px !important;
}

.emailField{
    position:relative
}

.joinTheKuleanpay{
  font-size:48px;
  max-width:588px;
  margin:auto;
  line-height:48px
}

.button{
    background:#0582D2 !important;
    padding:6px 32px !important;
    height:auto !important;
    border-radius:40px !important;
    max-width:98px !important;
    position:absolute;
    right:0;
    top:0;
    margin:45px 15px 0px 0px !important
}

.subscribeContainer {
  height: 500px;
  padding: 103px 100px;
  text-align: center;
}

@media screen and (max-width: 444px) {
  .subscribeContainer {
    height: 500px;
    padding: 103px 10px;
    text-align: center;
  }
  .subscribeContainer h1 {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  .buttonPosition {
    margin: 40px 0px 0px 0px;
  }
}

@media screen and (max-width: 958px) {
  .subscribeContainer {
    height: 500px;
    padding: 103px 30px;
    text-align: center;
  }
}
</style>