<template>
  <div>
    <div class="text-center mt-12">
      <v-btn @click="$router.push('/')" class="primarybtn" depressed x-large>
        Go Home <v-icon class="mx-2" small>mdi-arrow-right</v-icon></v-btn
      >
    </div>
    <icon :name="'404'" />
  </div>
</template>
  
  
  
  <script>
import Icon from "@/assets/Icons/CustomIcons/Icon.vue";
export default {
  components: { Icon },
};
</script>