<template>
<v-app>
    <NavMenu/>
    <JoinForm/>
    <AppSection/>
    <FooterSection/>
</v-app>
</template>


<script>
import NavMenu from "@/components/NavMenu.vue"
import JoinForm from "@/components/JoinTransaction/JoinTransaction.vue"
import AppSection from "@/components/Home/AppSection.vue"
import FooterSection from "@/components/Home/FooterSection.vue" 


export default {
    components:{
    NavMenu,
    JoinForm,
    AppSection,
    FooterSection
},

metaInfo() {
    return {
      title: `Join Transaction`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
    
}
</script>



<style scoped>

</style>