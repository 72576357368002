<template>
  <v-main style="background: white">
    <div class="cardContainer">
      <h2 class="headingStyle">
        <v-icon small @click="goBack()" class="mr-2">mdi-arrow-left</v-icon>Join
        Transaction
      </h2>
      <v-card elevation="0" class="cardStyle">
        <p>
          Enter Transaction link or Transaction ID to view transaction details
        </p>
        <v-text-field
          prepend-inner-icon="mdi-view-grid-plus-outline"
          v-model="transactionID"
          outlined
          single-line
          dense
          placeholder="Enter transaction ID"
        />

        <div v-if="transactionID.length >= 8">
          <div class="flexcard">
            <span class="grey--text titleWidth">Transaction Title</span>
            <p class="font-weight-medium contentWidth">
              {{ details.transactionTitle }}
            </p>
          </div>
          <hr />
          <div class="flexcard">
            <span class="grey--text titleWidth">Transaction ID</span>
            <p class="font-weight-medium contentWidth">{{ transactionID }}</p>
          </div>

          <hr />
          <div class="flexcard">
            <span class="grey--text titleWidth">Category</span>
            <p class="font-weight-medium contentWidth">
              {{ details.category }}
            </p>
          </div>

          <hr />
          <div class="flexcard">
            <span class="grey--text titleWidth">Description</span>
            <p class="font-weight-medium contentWidth">
              {{ details.description }}
            </p>
          </div>

          <div class="my-3">
            <hr class="dottedLine" />
            <div class="flexcard">
              <span class="grey--text titleWidth">Seller</span>
              <p class="font-weight-medium contentWidth">
                {{ details.sellerName }}
                <span class="emailStyle">{{ details.email }}</span>
              </p>
            </div>
            <hr class="dottedLine" />
          </div>

          <div class="flexcard">
            <span class="grey--text titleWidth">Payment Type</span>
            <p class="font-weight-medium contentWidth">
              {{ details.paymentType }}
            </p>
          </div>

          <hr />
          <div v-if="details.milestones" class="flexcard">
            <span class="grey--text titleWidth">Milestones</span>
            <p class="font-weight-medium contentWidth">
              {{ details.milestonesNumber }}
            </p>
            <hr />
          </div>

          <div v-if="details.milestones">
            <div v-for="(milestone, i) in details.milestones" :key="i">
              <div class="mb-3 milestoneCard d-flex justify-space-between">
                <v-icon>mdi-flag-outline</v-icon>
                <span>{{ milestone.title }}</span>
                <span>NGN {{ milestone.amount }}</span>
              </div>
            </div>
            <hr class="dottedLine" />
          </div>

          <div class="flexcard my-2">
            <span class="grey--text titleWidth">Delivery</span>
            <p class="font-weight-medium contentWidth">
              {{ details.delivery }}
            </p>
          </div>

          <div class="flexcard my-5">
            <span class="grey--text titleWidth">Amount</span>
            <p class="font-weight-medium contentWidth">
              NGN {{ details.transactionAmount }}
            </p>
          </div>

          <div class="flexcard my-2">
            <span class="grey--text titleWidth">Address</span>
            <p class="font-weight-medium contentWidth">{{ details.address }}</p>
          </div>

          <hr class="dottedLine" />

          <div class="flexcard">
            <span class="grey--text titleWidth">Service Fee</span>
            <p class="font-weight-medium contentWidth">
              NGN {{ details.serviceFee }} <br />
              <span class="emailStyle"
                >by {{ details.serviceFeePayerType }}</span
              >
            </p>
          </div>
        </div>

        <div class="d-flex my-5">
          <v-checkbox color="#0582D2" v-model="checkbox"></v-checkbox>
          <span class="mt-4"
            >I have read and agreed to the
            <a  href="/terms-of-use" style="color: #0582d2"
              >Terms of use</a
            >
            and
            <a href="/privacy-policy" style="color: #0582d2"
              >privacy policy.</a
            ></span
          >
        </div>

        <div class="flexButtons">
          <div class="fullWidthDiv decline">
            <v-btn
              @click="decline = true"
              :loading="declineloading"
              block
              large
              class="rounded-lg white--text"
              color="#EF4444"
              >Decline</v-btn
            >
          </div>
          <div class="fullWidthDiv">
            <v-btn
              @click="acceptTransaction()"
              block
              large
              class="rounded-lg white--text"
              color="#0582D2"
              >Accept</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <!--decline modal-->
    <v-dialog persistent v-model="decline" max-width="500px">
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card class="text-center cardSize pb-6">
            <div class="pa-5">
              <v-img
                width="180px"
                class="mx-auto"
                src="../../assets/decline.gif"
                alt="decline"
              />
              <h2>Decline request</h2>
              <p class="px-6">
                Are you sure you want to decline request?. This process is not
                reversible
              </p>
            </div>
            <div class="px-6 pb-2">
              <v-btn
                :loading="declineloading"
                @click="declineTransaction()"
                depressed
                block
                class="my-2 red--text buttonSize"
                x-large
                color="#FEF2F2"
                >Yes, Decline</v-btn
              >

              <v-btn
                @click="decline = false"
                block
                class="my-2 white--text buttonSize"
                x-large
                color="#0582D2"
                >No, cancel</v-btn
              >
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-card class="text-center cardSize pb-6">
            <div class="pa-5">
              <v-img
                width="180px"
                class="mx-auto"
                src="../../assets/decline.gif"
                alt="decline"
              />
              <h2>Transaction Declined</h2>
              <v-btn
                @click="$router.push('/')"
                block
                class="my-5 white--text buttonSize"
                x-large
                color="#0582D2"
                >Back To Homepage</v-btn
              >
            </div>
          </v-card>
        </v-window-item>
      </v-window>
    </v-dialog>

    <v-dialog fullscreen v-model="accept">
      <template>
        <v-app>
          <v-sheet color="white" class="pa-4">
            <div class="text-right">
              <v-btn @click="accept = false" fab depressed
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
            <LoginComponent />
          </v-sheet>
        </v-app>
      </template>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import LoginComponent from "./LoginComponent.vue";

export default {
  data() {
    return {
      step: 1,
      loading: false,
      dialog: false,
      message: "",
      errors: "",
      checkbox: false,
      show: false,
      e1: 1,
      details: {
        title: "Branding",
        category: "Services",
        description:
          "This is the description based on what has been given at the beginning",
        user: "Jumoke Kolade Johnson",
        email: "jumokekolade@gmail.com",
        paymentType: "Milestones",
        milestonesNumber: 3,
        milestones: [
          { title: "Milestone 1", amount: 5000 },
          { title: "Milestone 2", amount: 6000 },
        ],
        delivery: "courier",
        amount: 11000,
        address:
          "House 4a, College of Medicine Quareters, University of Lagos, Yaba, Lagos. 101203.",
        serviceFee: 4000,
        serviceFeePayer: "Buyer",
      },
      transactionID: "",
      accepted: false,
      declineloading: false,
      decline: false,
      accept: false,
    };
  },
  created() {
    this.transactionID = JSON.parse(
      sessionStorage.getItem("joinTransactionData")
    ).transactionId.substring(1);
    this.details = JSON.parse(sessionStorage.getItem("joinTransactionData"));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    acceptTransaction() {
      if (this.checkbox == false) {
        this.$swal({
          icon: "warning",
          title: "You need to agree to the terms first",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        return;
      }

      this.accept = !this.accept;
      sessionStorage.setItem("transactionId", this.details.transactionId);
    },
    declineTransaction() {
      if (this.checkbox == false) {
        this.$swal({
          icon: "warning",
          title: "You need to agree to the terms first",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        return;
      }

      this.declineloading = true;
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=settlements/onetimesettlementmgt/decline-one-time-transaction",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          joinTransactionId: this.details.transactionId,
          phoneNumber: this.details.sellerNo,
          status: 2,
        },
      }).then((response) => {
        console.log(response);
        if (
          response.data.statusCode == 400 &&
          response.data.description == "Join declined!"
        ) {
          sessionStorage.removeItem("joinTransactionData");
          this.step++;
          return;
        }
        this.$swal({
          icon: "error",
          title: response.data.description,
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        this.declineloading = false;
      });
    },
  },
  components: { LoginComponent },
};
</script>

<style scoped>
.milestoneCard {
  background: #f9fafb;
  border-radius: 8px;
  padding: 9px 15px !important;
}

hr {
  border: 0.001em solid #dedfe3;
  margin: 9px 0px;
}

.dottedLine {
  border: 0.001em dashed #dedfe3;
}

.emailStyle {
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss04" on;
  color: #6b7280;
}

a,
router-link {
  color: #0582d2 !important;
  text-decoration: none !important;
}

@media screen and (max-width: 799px) {
  .cardContainer {
    padding: 10px;
  }
  .headingStyle {
    margin: 16px 0px 10px 0px;
  }

  .cardStyle {
    padding: 30px 15px 20px 15px;
    margin: 0px auto 60px auto;
    max-width: 100%;
    border: solid 1px #eef1f1;
    width: 580px;
    height: auto;
  }

  .decline {
    margin: 0px 0px 10px 0px;
  }
}

@media screen and (min-width: 700px) {
  .flexcard {
    display: flex;
  }
}

@media screen and (min-width: 800px) {
  .cardContainer {
    width: 580px;
    height: auto;
    padding: 10px 40px 10px 40px;
    margin: 0px auto 0px auto;
    max-width: 100%;
  }
  .headingStyle {
    margin: 16px 0px 30px 0px;
  }

  .cardStyle {
    padding: 30px 40px 30px 40px;
    margin: 0px auto 60px auto;
    max-width: 100%;
    border: solid 1px #eef1f1;
    width: 580px;
    height: auto;
  }

  .titleWidth {
    width: 40%;
  }

  .contentWidth {
    width: 60%;
  }

  .flexButtons {
    display: flex;
  }

  .fullWidthDiv {
    width: 100%;
  }

  .decline {
    margin: 0px 10px 0px 0px;
  }

  .blueText {
    color: #0582d2;
  }
}
</style>