<template>
  <div>
    <div v-if="!loading" class="project-image">
      <v-img
        :src="'data:image/octet-stream;base64,' + project.campeignImage"
        class="image"
      />
      <div v-if="percentage < 100" class="project-btn">
        <v-btn @click="showDonation" large class="primarybtn" depressed
          >Donate Now</v-btn
        >
      </div>
    </div>
    <div v-if="loading">
      <v-skeleton-loader type="image" />
    </div>
  </div>
</template>



<script>
export default {
  props: {
    project: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    percentage() {
      return (
        (parseFloat(this.project.contributions) /
          parseFloat(this.project.goalAmount)) *
        100
      );
    },
  },
  methods: {
    showDonation() {
      this.$emit("showDonation");
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .project-image {
    position: relative;
  }

  .image {
    height: 237px;
  }
  .project-btn {
    position: absolute;
    bottom: 0;
    margin-bottom: 6px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 600px) {
  .project-image {
    position: relative;
    margin: 20px 0px;
  }

  .image {
    height: 237px;
  }
  .project-btn {
    position: absolute;
    bottom: 0;
    margin-bottom: 6px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>