<template>
  <div>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          @submit.prevent="makePayment"
        >
          <label>Amount</label>
          <v-text-field
            v-model="amount"
            :rules="amountRules"
            required
            outlined
            dense
            type="number"
            inputmode="numeric"
            style="height: 60px"
          ></v-text-field>

          <label>Email</label>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            required
            outlined
            dense
            type="email"
            style="height: 60px"
          ></v-text-field>

          <label>Phone Number</label>
          <v-text-field
            v-model="phoneNumber"
            required
            outlined
            dense
            type="number"
            inputmode="numeric"
            :rules="phoneRules"
          ></v-text-field>
        </v-form>

        <div class="mt-3">
          <v-btn
            @click="initiateTransfer()"
            large
            class="white--text"
            color="#0582D2"
            block
            :loading="loading"
          >
            Proceed
          </v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="2">
        <Timer />

        <table>
          <tr>
            <td colspan="5">Bank</td>
            <td>{{ merchantBankName }}</td>
          </tr>

          <tr>
            <td colspan="5">Account Name</td>
            <td>
              {{ merchantAccountName }}
            </td>
          </tr>

          <tr>
            <td colspan="5">Account Number</td>
            <td>
              {{ merchantAccountNumber }}
              <v-icon @click="copyAccountNumber" color="#0582D2" small
                >mdi-bookmark-multiple-outline</v-icon
              ><v-icon @click="shareAccountNumber" color="#0582D2" small
                >mdi-share-variant</v-icon
              >
            </td>
          </tr>
        </table>

        <div class="mt-3">
          <v-btn
            @click="step++"
            large
            class="white--text"
            color="#0582D2"
            block
          >
            I've made payment
          </v-btn>
        </div>
      </v-window-item>

      <v-window-item :value="3">
        <success-screen
          :title="'Payment Processing'"
          :buttonText="'Close'"
          :description="'Thank you for your payment. The merchant would be notified once payment is confirmed'"
          @closeSuccess="goHome()"
        />
      </v-window-item>
    </v-window>
  </div>
</template>



<script>
import axios from "axios";
import Timer from "./Timer.vue";
import successScreen from "./successScreen.vue";

export default {
  data() {
    return {
      phoneNumber: "",
      email: "",
      amount: "",
      valid: true,
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => parseInt(v) > 0 || "Amount cannot be 0 or negative",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => v.length > 10 || "Phone must be valid",
      ],
      step: 1,
      loading: false,
      merchantAccountName: "",
      merchantAccountNumber: "",
      merchantBankName: ""
    };
  },
  props: {
    merchantName: {
      type: String,
    },
  },

  components: {
    Timer,
    successScreen,
  },

  methods: {
    goHome() {
      window.location.href = "/";
    },
    async proceed() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.step++;
    },

    copyAccountNumber() {
      navigator.clipboard
        .writeText(
          `"Bank: ${this.merchantBankName}, Account Name: ${this.merchantAccountName}, Account Number: ${this.merchantAccountNumber}"`
        )
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Account copied",
            text: "Account details successfully copied to clipboard",
            confirmButtonText: `<p class="brandcolor">Continue</p>`,
          });
        });
    },

    async shareAccountNumber() {
      const shareData = {
        title: "Kuleanpay Bank payment details",
        text: `"Bank: ${this.merchantBankName}, Account Name: ${this.merchantAccountName}, Account Number: ${this.merchantAccountNumber}"`,
        url: window.location.href,
      };

      await navigator.share(shareData);
    },

    initiateTransfer() {
      this.loading = true;
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=payments/paymentmgt/merchant-collection-link-with-transfer",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        data: {
          emailAddress: this.email,
          amount: this.amount,
          merchantCollectionLink:
            "https://app.kuleanpay.com/merchant?merchantId=" +
            this.$route.params.id.toUpperCase(),
          phoneNumber: this.phoneNumber,
        },
      }).then((response) => {
        this.loading = false;
        console.log(response);
        if (response.data.statusCode == 200) {
          this.step++;
          this.merchantAccountName = response.data.data.merchantAccountName;
          this.merchantAccountNumber = response.data.data.merchantAccountNumber;
          this.merchantBankName = response.data.data.merchantBankName;
          return;
        } else {
          this.$swal({
            icon: "error",
            title: "Something's wrong",
            text: response.data.description,
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        }
      });
    },
  },
};
</script>



<style scoped>
table {
  width: 100%;
}
td {
  padding: 20px 0px !important;
}
</style>