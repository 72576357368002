<template>
  <div id="howitworks" class="howItWorks">
    <h2>How it works</h2>

    <EscrowFeatureTwoSideVue
      :title="'Create an account'"
      :description="'Create an account via web or mobile and go to your account settings and complete KYC verification for tier 1 and 2. Your account will be verified instantly and you will be issued an account number. Your account number will be visible when you choose the fund wallet by bank transfer option.'"
      :image="'createDigitalBank'"
    />

    <EscrowFeatureReverseTwoSide
      :title="'Send & receive money instantly'"
      :description="'Say no to transaction delays and fraudulent transactions because we offer you multiple payment options with super-fast transaction time. We support all local banks in Nigeria.'"
      :image="'sendAndReceiveMoney'"
    />

   
  </div>
</template>



<script>
import EscrowFeatureTwoSideVue from "../Escrow/Features/EscrowFeatureTwoSide.vue";
import EscrowFeatureReverseTwoSide from "../Escrow/Features/EscrowFeatureReverseTwoSide.vue";
export default {
  components: {
    EscrowFeatureTwoSideVue,
    EscrowFeatureReverseTwoSide,
  },
};
</script>





<style scoped>
@media screen and (max-width: 400px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
}

@media screen and (max-width: 516px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }
  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 865px) {
  .howItWorks {
    text-align: center;
    padding: 30px 0px;
  }

  .howItWorks > h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1045px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1168px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1500px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}

@media screen and (min-width: 1900px) {
  .howItWorks {
    text-align: center;
    padding: 60px 0px 0px 0px;
  }
}
</style>


<!-- I pass an image a prop in this component -->