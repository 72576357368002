<template>
  <v-main class="bgcolor">
    <div class="jumbotronContainer">
      <div class="jumbotronText">
        <h2>Become a partner with Kuleanpay</h2>
        <p>
          Make money when you partner with Kuleanpay as you leverage our world
          class API to help you to grow your business and accept safe payments.
          With just a few lines of code, you can quickly create safe, quick
          services and start taking payments.
        </p>
        <div>
          <v-btn
            href="#partner-form"
            class="block createButton rounded-lg mr-4"
            x-large
            >Get in Touch</v-btn
          >
          <v-btn
            depressed
            color="rgba(0, 0, 0, 0.05)"
            @click="scheduleMeeting = !scheduleMeeting"
            class="joinButton block rounded-lg"
            x-large
            ><v-icon class="mr-2">mdi-calendar</v-icon>Schedule Meeting</v-btn
          >
        </div>
      </div>

      <div class="relative jumbotronImage">
        <v-img
          src="../../assets/Partner.svg"
          class="imageSize"
          alt="kuleanshield"
        />
      </div>
    </div>

    <v-dialog v-model="scheduleMeeting" max-width="500px">
      <MeetingScheduler @closeModal="scheduleMeeting = !scheduleMeeting" />
    </v-dialog>
  </v-main>
</template>




<script>
import MeetingScheduler from "./MeetingScheduler.vue";

export default {
  data() {
    return {
      scheduleMeeting: false,
    };
  },
  components: {
    MeetingScheduler,
  },
};
</script>




<style scoped>
.createButton {
  border-radius: 8px;
  background: #0582d2 !important;
  color: white;
}

.joinButton {
  color: black;
  background: rgba(0, 0, 0, 0.05);
}

.jumbotronContainer {
  padding: 0px 100px;
}

.bgcolor {
  background: #F5FBFF;
  height: auto;
}

.navMenu {
  padding: 34px 100px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-btn {
    width: 90%;
    margin: 0px 0px 15px 0px;
  }

  .createButton {
    width: 50%;
  }
  .joinButton {
    width: 70% !important;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 40px !important;
    line-height: 40px !important;
    width: 90%;
  }

  .bgcolor {
    height: auto !important;
  }

  .jumbotronContainer img {
    margin: 30px 0px;
    width: 95%;
  }

  .bgcolor {
    height: auto;
  }

  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
  }

  .jumbotronContainer img {
    margin: 90px 0px;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 90%;
    font-size: 16px;
  }

  .imageSize {
    margin: 40px 0px 0px 0px;
    height: auto;
    bottom: 0;
    object-fit: cover;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bgcolor {
    height: auto;
  }

  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
  }

  .jumbotronContainer img {
    margin: 90px 0px;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 90%;
    font-size: 16px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
    justify-content: space-between;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 60%;
    font-size: 18px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bgcolor {
    height: auto;
  }
  .jumbotronContainer {
    padding: 120px 50px 0px 30px;
    display: flex;
    justify-content: space-between;
  }

  .jumbotronText {
    padding: 40px 0px 90px 0px;
    width: 100%;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 56px;
    line-height: 56px;
    width: 80%;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 70%;
    font-size: 16px;
  }

  .jumbotronImage {
    width: 100%;
    position: relative;
  }

  .imageSize {
    position: absolute;
    height: 100%;
    bottom: 0;
    object-fit: cover;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .bgcolor {
    height: auto;
  }
  .jumbotronContainer {
    padding: 30px 46px 0px 100px;
    display: flex;
    justify-content: space-between;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 56px;
    line-height: 56px;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 70%;
    font-size: 16px;
  }

  .jumbotronText {
    padding: 40px 0px 90px 0px;
    width: 60%;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .imageSize {
    position: absolute;
    height: auto;
    bottom: 0;
  }
}

@media only screen and (min-width: 1500px) {
  .bgcolor {
    height: auto;
    max-height: 828px !important;
  }
  .jumbotronContainer {
    padding: 30px 46px 0px 100px;
    display: flex;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 56px;
  }

  .jumbotronContainer p {
    color: black;
    padding: 32px 0px;
    width: 50%;
    font-size: 16px;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .imageSize {
    position: relative;
    height: auto;
    bottom: 0;
  }
}

@media only screen and (min-width: 1700px) {
  .jumbotronContainer {
    max-width: 1700px !important;
    margin: 0 auto;
  }

  .jumbotronContainer h2 {
    color: black;
    font-size: 56px;
  }
}
</style>