<template>
  <div class="campaign-progress">
    <span>Goal Amount</span>
    <h3 v-if="!loading">{{ project.goalAmount | amountFormatter(true) }}</h3>
    <h3 v-if="loading">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </h3>
    <ProgressBar
      :project="project"
      :percentage="percentage"
      :loading="loading"
    />
  </div>
</template>
  
  
  <script>
import ProgressBar from "@/components/Misc/ProgressBar.vue";
import { amountFormatter } from "@/utils/amountFormatter";
import Vue from "vue";
Vue.filter("amountFormatter", amountFormatter);

export default {
  components: {
    ProgressBar,
  },
  props: {
    project: {
      type: Object,
    },
    percentage: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 600px) {
  .campaign-progress {
    border: 1px solid #d7d7d7;
    padding: 20px 10px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 600px) {
  .campaign-progress {
    border: 1px solid #d7d7d7;
    padding: 20px 20px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 1200px) {
  .campaign-progress {
    border: 1px solid #d7d7d7;
    padding: 20px 20px 40px 20px;
    margin: 20px 0px 0px 0px;
    border-radius: 8px;
  }
}
</style>