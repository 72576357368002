import { render, staticRenderFns } from "./CardPayment.vue?vue&type=template&id=2c3d981f&scoped=true&"
import script from "./CardPayment.vue?vue&type=script&lang=js&"
export * from "./CardPayment.vue?vue&type=script&lang=js&"
import style0 from "./CardPayment.vue?vue&type=style&index=0&id=2c3d981f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c3d981f",
  null
  
)

export default component.exports