<template>
  <div class="project-details">
    <h2 v-if="!loading">{{ project && project.title }}</h2>
    <h2 v-if="loading">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </h2>
    <v-chip
      v-if="!loading"
      label
      :class="project.approvalStatus.toLowerCase()"
      small
      >{{ project.approvalStatus }}</v-chip
    >
    <v-skeleton-loader v-if="loading" type="chip"></v-skeleton-loader>
    <div class="description-title">Description</div>
    <p v-if="!loading">
      {{
        project.description.length > 70 && excerpt
          ? `${excerptDescription}`
          : project.description
      }}
      <span
        @click="showFullDescription"
        class="pointer brandcolor"
        v-if="excerpt && project.description.length > 70"
        >read more</span
      >

      <span
        @click="showFullDescription"
        class="pointer brandcolor"
        v-if="!excerpt && project.description.length > 70"
        >show less</span
      >
    </p>

    <p v-if="loading">
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </p>
  </div>
</template>
  
  
  <script>
export default {
  data() {
    return {
      excerpt: true,
    };
  },
  props: {
    project: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },

  computed: {
    excerptDescription() {
      return this.project.description.slice(0, 70);
    },
  },

  methods: {
    showFullDescription() {
      this.excerpt = !this.excerpt;
    },
  },
};
</script>
  
  
  
<style scoped>
.active {
  color: #0883d3;
  background: #ddebf7 !important;
}
.description-title {
  color: #6e6e6e;
  padding: 15px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .project-details {
    border: 1px solid #d7d7d7;
    padding: 10px;
    margin: 10px 6px 10px 6px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 600px) {
  .project-details {
    border: 1px solid #d7d7d7;
    padding: 10px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 776px) {
  .project-details {
    border: 1px solid #d7d7d7;
    padding: 10px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
  }
}
</style>