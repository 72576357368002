<template>
  <div>
    <div class="project-grid">
      <div class="details-container">
        <div class="details-grid">
          <ProjectDetails :project="project" :loading="loading"/>
        </div>
        <div class="donation-grid">
          <DonationLink
            :link="'kuleanpay.com/crowdfunding/' + project.transactionId"
            :project="project"
          />
        </div>
      </div>
      <div class="full-width">
        <ProgressComponent :project="project" :percentage="percentage" :loading="loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressComponent from "./ProjectInfo/ProgressComponent.vue";
import DonationLink from "./ProjectInfo/DonationLink.vue";
import ProjectDetails from "./ProjectInfo/ProjectDetails.vue";
export default {
  props: {
    project: {
      type: Object,
    },
    slug: {
      type: String,
    },
    percentage: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    ProjectDetails,
    ProgressComponent,
    DonationLink,
  },
};
</script>



<style scoped>
.description-title {
  color: #6e6e6e;
  padding: 15px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .crowdproject {
    display: flex;
    justify-content: center;
  }

  .project-details {
    border: 1px solid #d7d7d7;
    padding: 10px;
    margin: 10px 6px 10px 6px;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 776px) {
  .project-grid {
    display: block;
    padding: 20px;
  }

  .full-width {
    grid-column: 1 / span 2;
  }

  .details-container {
    display: flex;
    width: 100%;
  }
  .details-grid {
    flex: 1;
  }
  .donation-grid {
    flex: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .project-grid {
    display: flex;
    grid-template-columns: 1fr 1fr; /* One column for smaller screens */
    grid-template-rows: auto auto;
    grid-column-gap: 15px;
  }

  .details-container {
    display: block;
  }
}
</style>