<template>
  <div class="teamBackground">
    <div>
      <p class="brandcolor">SEE THE TEAM</p>
      <h2>
        Our team consists of a group of talented and creative people who work
        hard to solve real problems and change how people live and work.
      </h2>
    </div>

    <div class="my-6">
      <v-btn target="_blank" href="https://thefifthlab.com/about" depressed color="#0582D2" class="white--text teambtn" x-large
        >View team <v-icon small class="ml-2">mdi-arrow-right</v-icon></v-btn
      >
    </div>
  </div>
</template>



<script>
export default {};
</script>


<style scoped>
@media screen and (max-width: 400px) {
  .teamBackground {
    background: #f5fbff;
    padding: 64px 24px;
  }

  .teamBackground h2 {
    font-weight: 600;
    font-size: 26px;
  }

  .teambtn {
    padding: 16px 20px !important;
    font-weight: 400;
    border-radius: 8px;
  }
}

@media screen and (max-width: 516px) {
  .teamBackground {
    background: #f5fbff;
    padding: 64px 24px;
  }

  .teamBackground h2 {
    font-weight: 600;
    font-size: 26px;
  }

  .teambtn {
    padding: 16px 20px !important;
    font-weight: 400;
    border-radius: 8px;
  }
}

@media screen and (max-width: 865px) {
  .teamBackground {
    background: #f5fbff;
    padding: 64px 24px;
  }

  .teamBackground h2 {
    font-weight: 600;
    font-size: 26px;
  }

  .teambtn {
    padding: 16px 20px !important;
    font-weight: 400;
    border-radius: 8px;
  }
}

@media screen and (min-width: 865px) {
  .teamBackground {
    background: #f5fbff;
    padding: 64px 90px 64px 130px;
    display:flex;
    justify-content: space-between;
  }

  .teamBackground h2 {
    font-weight: 600;
    font-size: 26px;
    max-width:560px
  }

  .teambtn {
    padding: 16px 20px !important;
    font-weight: 400;
    border-radius: 8px;
  }
}

@media screen and (min-width: 1045px) {
}

@media screen and (min-width: 1168px) {
}

@media screen and (min-width: 1500px) {
}

@media screen and (min-width: 1900px) {
}
</style>




