<template>
  <div class="text-center">
    <h1 v-if="!loading">
      {{ project.title }}
    </h1>
    <h1 v-if="loading">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </h1>
    <div v-if="!loading" class="meta">
      <span
        >By <span style="font-weight: 500">{{ project.customerName }}</span>
      </span>
      <span>• {{ project.transactionDate | dateFormatter }}</span>
      <span> • Nigeria</span>
    </div>
    <div>
      <v-skeleton-loader v-if="loading" type="list-item" />
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { dateFormatter } from "@/utils/dateFormatter";
Vue.filter("dateFormatter", dateFormatter);

export default {
  props: {
    project: {
      type: Object,
    },
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .meta {
    margin: 0px 0px 6px 6px;
    font-size: 12px;
  }

  .meta > * {
    margin: 0px 0px 0px 6px;
  }
}

@media only screen and (min-width: 600px) {
  .meta {
    margin: 0px 0px 6px 6px;
    font-size: 14px;
  }

  .meta > * {
    margin: 0px 0px 0px 6px;
  }
}
</style>