<template>
  <v-app>
    <NavMenu :route="$route.path.substring(1)" :bg="'bgblue'"/>
    <PartnerJumbotron />
    <BenefitsGrid />
    <PartnerWithUs />
    <BestWay />
    <CtaSection />
    <div style="margin: 90px 0px 0px 0px">
      <FooterSection />
      <WhatsApp />
    </div>
  </v-app>
</template>



<script>
import NavMenu from "../components/NavMenu.vue";
import PartnerJumbotron from "../components/Partner/PartnerJumbotron.vue";
import BenefitsGrid from "@/components/Partner/BenefitsGrid.vue";
import PartnerWithUs from "@/components/Partner/PartnerWithUs.vue";
import FooterSection from "@/components/Home/FooterSection.vue";
import CtaSection from "@/components/Home/CtaSection.vue";
import BestWay from "@/components/Home/BestWay.vue";
import WhatsApp from "@/components/WhatsApp/WhatsApp.vue";
export default {
  components: {
    NavMenu,
    PartnerJumbotron,
    BenefitsGrid,
    PartnerWithUs,
    FooterSection,
    BestWay,
    CtaSection,
    WhatsApp
  },

  metaInfo() {
    return {
      title: `Partner with Us`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay",
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content: "Ensure what you ordered is what you get with Kuleanpay",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992",
        },
      ],
    };
  },
};
</script>





<style scoped>
</style>