<template>
    <div class="featureContainer">
        <img :src="image" alt="Image" />
        <div class="ml-6 mt-3">
            <h2 class="featuretitle">
                {{title}}
            </h2>
            <p>
                {{description}}
            </p>
        </div>
    </div>
</template>



<script lang="ts">
export default {
    name:"FeaturesComponent",
    props:{
        image:{
            type:String,
            required:true
        },
        title:{
            type:String
        },
        description:{
            type:String
        }
    }
}
</script>


<style scoped>
.featuretitle{
    font-size:24px
}


@media only screen and (max-width:500px){
    .featureContainer{
    display:flex
}
}

@media only screen and (min-width:500px){
    .featureContainer{
    display:flex
}
}

@media only screen and (min-width:600px){
    .featureContainer{
    display:flex
}
}

@media only screen and (min-width:768px){
    .featureContainer{
    display:flex;
    margin:50px 0px 0px 0px
}
}

@media only screen and (min-width:894px){
    .featureContainer{
    display:flex
}
}

@media only screen and (min-width:996px){
    .featureContainer{
    display:flex
}
}

@media only screen and (min-width:1200px){
    .featureContainer{
    display:flex
}
}
</style>