<template>
    <div class="subscribeContainer">
      <div>
        <p class="brandcolor">News & updates</p>
        <h1 class="joinTheKuleanpay">Join the Kuleanpay Community</h1>
        <div class="mailChimpContainer">
        <Mailchimp />
      </div>
        <p class="text-center telegram">
        Join us on <a target="_blank" href="https://t.me/kuleanpay">Telegram</a>
      </p>
      </div>
    </div>
</template>




<script lang="ts">
import Mailchimp from "../Misc/Mailchimp.vue";
export default {
  components: {
    Mailchimp,
  },
};
</script>


<style scoped>
#mc_embed_signup {
  margin: auto auto auto -20px !important;
}

.joinTheKuleanpay {
  font-size: 48px;
  max-width: 588px;
  margin: auto;
  line-height: 48px;
}

.button {
  background: #0582d2 !important;
  padding: 6px 32px !important;
  height: auto !important;
}

.subscribeContainer {
  /* height: 500px; */
  padding: 103px 100px;
  text-align: center;
  max-width:1200px;
  margin:auto
}

@media screen and (max-width: 444px) {
  .subscribeContainer {
    /* height: 500px; */
    padding: 103px 10px 0px 10px;
    text-align: center;
  }
  .subscribeContainer h1 {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  .buttonPosition {
    margin: 40px 0px 0px 0px;
  }
}

@media screen and (max-width: 958px) {
  .subscribeContainer {
    padding: 103px 30px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .mailChimpContainer{
    max-width:700px;
    margin:auto
  }
}
</style>


