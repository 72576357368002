<template>
<v-app>
    <NavMenu  :route="$route.path.substring(1)" :bg="'bggreen'"/>
    <WhyJumbotron />
    <offer-component />
    <reverse-two-side/>
    <two-side/>
    <cta-section/>
    <app-section/>
    <footer-section />
    <WhatsApp />
</v-app>
    
</template>







<script>
import ReverseTwoSide from '@/components/Home/ReverseTwoSide.vue'
import NavMenu from "../components/NavMenu.vue"
import WhyJumbotron from "../components/Why/WhyJumbotron.vue"
import TwoSide from '@/components/Home/TwoSide.vue'
import AppSection from "@/components/Home/AppSection.vue"
import FooterSection from "@/components/Home/FooterSection.vue"
import OfferComponent from '@/components/Home/OfferComponent.vue'
import CtaSection from '@/components/Home/CtaSection.vue'
import WhatsApp from "@/components/WhatsApp/WhatsApp.vue"
export default {

    components:{
        NavMenu,
        WhyJumbotron,
        ReverseTwoSide,
        TwoSide,
        AppSection,
        FooterSection,
        OfferComponent,
        CtaSection,
        WhatsApp
    },

    metaInfo() {
    return {
      title: `Why Kuleanpay`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
    
}
</script>