<template>
  <v-app>
    <NavMenuVue :bg="'bgblue'"/>
    <div class="header">
      <h1>Privacy Policy</h1>
    </div>

    <div class="container">
      <h2>1. Introduction</h2>
      <p>
        Kuleanpay is a platform that provides individuals and corporate bodies
        with a transparent and secure online payment solution to the legal and
        professional services industries and to marketplaces and e-commerce
        platforms.
      </p>
      <p>
        This Privacy Notice (“Notice”) guides your use of our Website:
        <a href="https://kuleanpay.com">https://kuleanpay.com/</a> (“the
        Website”) and your rights regarding our collection, use, storage and
        protection of your personal data when you visit, access, browse through
        and/or use our Website or Services. Your privacy is important to us.
      </p>

      <h2>2. Terms of Use</h2>
      <p>
        You are required to comply with the provisions of our Terms of Use in
        relation to the information provided.
      </p>
      <h2>3. Your personal data that we process</h2>
      <p>
        Personal data means any information about an individual from which that
        person can be directly or indirectly identified. We do not consider
        personal data to include information that has been made anonymous such
        that it does not identify a specific user.
      </p>

      <p>
        In connection with the services we provide, we collect personal and
        financial information from you while you use our products, services and
        when you create an account or sign in to our Website. We may ask you to
        provide us with certain personal data directly to contact or identify
        you, and some automatically for our Website to function effectively. We
        also collect personal data when you provide us with feedback, and/or
        report a problem. The personal data we obtain include:
      </p>

      <table>
        <tr>
          <th>Service Users</th>
          <th>All visitors</th>
        </tr>
        <tr>
          <th>First and last name</th>
          <th>The domain name of the Internet service provider (ISP)</th>
        </tr>
        <tr>
          <th>Email address</th>
          <th>Data and time of your visits;</th>
        </tr>
        <tr>
          <th>Phone number</th>
          <th>
            The Internet protocol address used to connect your device to the
            Internet for identification purposes
          </th>
        </tr>

        <tr>
          <th>Account details (including username and password)</th>
          <th>Web pages visited, duration and frequency of visit</th>
        </tr>

        <tr>
          <th>Transactional data (information relating to a payment)</th>
          <th>
            Marketing and communications data (includes both a record of your
            decision to subscribe to our newsletter or to withdraw from
            receiving marketing materials from us.)
          </th>
        </tr>

        <tr>
          <th>
            Financial information (which may include ATM card details, Bank
            Verification Number (BVN), etc.)
          </th>
          <th>Trusted 3rd party API providers like Paystack and Blusalt</th>
        </tr>
      </table>

      <h2>4. Cookies</h2>

      <p>
        Cookies are tools used to collect information automatically from you
        when you visit a Website. Our website does not use cookies.
      </p>

      <h2>5. Lawful Bases for processing data</h2>

      <p>
        We are required to process your data under at least one of these lawful
        bases:
      </p>
      <p><ul>
        <li>
          <b>Legitimate interest:</b> Processing your data is necessary for our
          legitimate interests or the legitimate interests of a third party,
          provided your rights and interests do not outweigh those interests.
        </li>
        <li>
          <b>Consent:</b> You have given explicit consent for us to process your
          data for a specific purpose.
        </li>
        <li>
          <b>Contract:</b> If your data processing is necessary for a contract you
          have with us or because we have asked you to take specific steps
          before entering into that contract.
        </li>
        <li>
        <b>Legal obligation:</b> If the processing of your data is necessary where
          there is a statutory obligation on us.
        </li>
      </ul>
    </p>

      <h2>6. Purpose of processing your data and the lawful basis</h2>
      <p>We collect your
      data to:</p>


      <table>
        <tr>
          <th>Purpose of Processing</th>
          <th>Lawful Bases</th>
        </tr>
        <tr>
          <th>
            <ul>
            <li>To administer our business</li>
            <li>To help us to develop, improve, customise or restructure our services</li>
            <li>To enforce our terms of service and any terms and conditions of any other agreements for our services.</li>
            </ul>
          </th>
          <th>Legitimate interest, contract</th>
        </tr>
        <tr>
          <th>
            <ul>
            <li>To take statistical data and analytics for our use internally </li>
            <li>To send you service-related messages</li> 
            <li>To analyse Site usage and provide, maintain and improve the content and functionality of the Site.</li>
            </ul>
          </th>
          <th>Legitimate interest</th>
        </tr>
        <tr>
          <th>
            <ul>
                <li>
                To send marketing or promotional messages to you.
                </li>
            </ul>
          </th>
          <th>
            Consent
          </th>
        </tr>

        <tr>
          <th><ul><li>To secure your data and prevent fraud.</li></ul></th>
          <th>Legitimate interest, legal obligation</th>
        </tr>

        <tr>
          <th>
            <ul>
                <li>To manage your account.</li>
                <li>To communicate with you.</li>
                <li>To address your inquiries, process your registration, and complete your transactions.</li>
                <li>To enable an easy and effective  payment system.</li>
            </ul>
          </th>
          <th>
            Contract
          </th>
        </tr>

        <tr>
          <th>
        <ul>
            <li>To inform you whenever there are changes to our terms of business or services.</li>
        </ul>
          </th>
          <th>Legal obligation, contract</th>
        </tr>

        <tr>
            <th><ul><li>To fulfil our Know Your Customer (KYC) obligation.</li>
<li>To interact with regulatory authorities or other public authorities concerning you</li>
</ul>
</th>
<th>Legal obligation</th>
        </tr>
      </table>


      
<h2>7.	Your rights as a data subject</h2>

<p>The law vests you with certain rights as a data subject. They include the right to:</p>
<p>
<ul>
<li>access personal data we hold about you by requesting a copy of the personal data we hold about you</li>
<li>rectify such information where you believe it to be inaccurate</li>
<li>restrict the processing of your data in certain circumstances</li>
<li>object to the processing of your data where we intend to process such data for marketing purposes</li>
<li>where feasible, receive all personal data you have provided to us —in a structured, commonly used, and machine-readable format—and transmit the information to another data controller</li>
<li>request the erasure of your data (also known as the right to be forgotten);</li>
<li>withdraw your consent to the processing of your personal data; and</li>
<li>lodge a complaint with a relevant authority, where you have reason to believe that we have violated the term(s) of this Privacy Notice. (You may complain or seek redress from us within 30 days from the time you first detected the alleged violation.).</li>
</ul>
</p>
<p>
You may seek to exercise any of the above rights at any time by sending an email to us at: <a href="mailto:support@kuleanpay.com">support@kuleanpay.com.</a>
</p>
<p>
Users who have a complaint may direct it to us or the appropriate supervisory authority in their respective country.
</p>

<h2>
8.	Who we  share your data with
</h2>
<p>
We may share your data with the following third parties:
</p>


<table>
        <tr>
          <th>Third parties</th>
          <th>Purpose of data sharing</th>
        </tr>
        <tr>
          <th>Mailchimp</th>
          <th>We use Mailchimp for marketing purposes. Read Mailchimp’s privacy notice <a href="https://www.intuit.com/privacy/statement/" target="_blank">here.</a></th>
        </tr>
        <tr>
          <th>Google</th>
          <th>We use Google services for our Website’s operation. Read Google’s privacy notice <a href="https://policies.google.com/privacy?hl=en-US" target="_blank">here.</a>

</th>
        </tr>
        <tr>
          <th>AWS</th>
          <th>
            We use AWS cloud services for our Website’s data storage. Read AWS privacy notice <a href="https://aws.amazon.com/privacy/?nc1=f_pr" target="_blank">here.</a>
          </th>
        </tr>

        <tr>
          <th>Financial institutions</th>
          <th>We collaborate with various financial institutions to create and offer our product, and we may only use this information to market our related products unless the customer has given consent for other uses.</th>
        </tr>

        <tr>
          <th>Legal and Regulatory Authorities</th>
          <th>
            We may disclose your information if we believe it is reasonably necessary to comply with a law, regulation, order, subpoena, audit, or to protect any person's safety, or to address fraud, security, or technical issues. 
          </th>
        </tr>

      </table>

      <p>
        Note that if you wish to prevent your device’s operating system from sharing your Personal Data with Kuleanpay or with the third parties mentioned for profiling purposes, you can do so by setting up your device appropriately – namely, by changing the privacy settings on your device to disable/restrict any advertising tracking features. For more information on this, please see the following links:
      </p>

      <p>
        <ul>
            <li>iOS Devices: <a href="https://support.apple.com/en-us/HT202074" target="_blank">https://support.apple.com/en-us/HT202074;</a></li>
            <li>Android Devices: <a class="break" href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank">https://support.google.com/ads/answer/2662922?hl=en;</a></li>
        </ul>
      </p>

      <h2>9.	Retention of your data</h2>

<p>The data and any other information we collect from you will be stored for as long as necessary to fulfil the purposes described in this Notice.</p>

<p>However, we will retain data subject to relevant provisions of applicable laws in order to resolve disputes, prevent fraud and abuse, and enforce our legal agreements and policies. Furthermore, once you unsubscribe from our marketing communications, we delete your data for targeted marketing purposes.</p>

<p>Please keep in mind that your data may be kept for a longer period of time despite your request to have it removed if there is a legal requirement to do so.</p>

<h2>10.	How your data is stored and secured</h2>

<p>We are very particular about preserving your privacy and protecting your data. We deploy reasonable and appropriate technical and organisational measures to keep your data safe. However, we cannot completely guarantee the security of any information you transmit via our website, as the internet is not an entirely secure place. Nevertheless, we are committed to doing our best to protect you.</p>

<p>We protect your data using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorised access, disclosure and alteration.</p>

<p>Where there is an actual or suspected data breach capable of causing harm to your rights and freedoms, we will notify you without undue delay and use our best effort to remedy the breach promptly.</p>


<h2>11.	International transfer of data</h2>
<p>To achieve the purposes described in this Notice, we may transfer your data to foreign countries. We will take additional precautions to ensure the adequate protection of personal information when transferring it to a country other than where we operate.</p>
<p>We comply with applicable local laws and regulations in the countries where we operate when conducting international data transfers. This includes transferring data to countries deemed adequate under applicable laws, obtaining licences or approvals from the relevant authority, maintaining appropriate documentation, obtaining explicit consent, and performing contractual obligations. Furthermore, we have the ability to transfer when we have a legal obligation, need to establish or defend a legal claim, or have a public interest obligation.  </p>

<p>Furthermore, we take extra precautions for data transfer and the selection of trustworthy third parties and service providers in third countries. </p>

<p>Please contact us if you require any additional information about your data transfers to third countries (including the appropriate transfer mechanisms).</p>

<h2>12.	Marketing and communications</h2>
<p>We only send marketing communications to you with your consent. You may opt-out of our marketing or object to further processing by clicking on the 'unsubscribe' button at the bottom of the page.</p>


<h2>13.	Complaints</h2>

<p>If you are concerned about an alleged breach of data protection law or any other regulation by us, you can contact the Data Protection Officer (DPO) at <a href="mailto:dpo@thefifthlab.com" target="_blank">dpo@thefifthlab.com</a>. The DPO will investigate your complaint and provide information about how it was handled.</p>

<p>Please be advised that if your complaints are not satisfactorily addressed, you may file a complaint with the relevant data protection authority.</p>

<h2>14.	Changes to this Notice</h2>

<p>We update our privacy notice from time to time. We will notify our users when we make a change, and visitors will know this by checking the last date of update on this page whenever they visit.</p>

<h2>15.	Contact Us</h2>

<p>If you have any questions relating to this Notice, your rights under this Notice, or are not satisfied with how we manage your personal data, kindly reach out to our Data Protection Officer at <a href="mailto:dpo@thefifthlab.com" target="_blank">dpo@thefifthlab.com</a></p>


</div>
    <app-section/>
    <footer-section/>
  </v-app>
</template>


<script>
import NavMenuVue from "@/components/NavMenu.vue";
import FooterSection from "@/components/Home/FooterSection.vue";
import AppSection from "@/components/Home/AppSection.vue";
export default {
  created(){
    window.scrollTo(0,0)
  },
  components: {
    NavMenuVue,
    AppSection,
    FooterSection,
  },

  metaInfo() {
    return {
      title: `Privacy Policy`,
      titleTemplate: "%s | Kuleanpay",
      meta: [
        {
          name: "description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay" 
        },
        {
          property: "og:title",
          content: this.$route.params.id + " | Kuleanpay"
        },
        { property: "og:site_name", content: "Kuleanpay" },
        {
          property: "og:description",
          content:
            "Ensure what you ordered is what you get with Kuleanpay"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://kuleanpay.com" + this.$route.params.id
        },
        { property: "og:image", content: "https://firebasestorage.googleapis.com/v0/b/kuleanpay.appspot.com/o/kuleanpay.png?alt=media&token=c7390895-dcda-4cdc-9ff8-7f7fd67af992" }
      ]
    };
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 25px 0px 20px;
  }
}

@media only screen and (min-width: 600px) {
  .header {
    background: #f5fbff;
    color: #0582d2;
    text-align: center;
    padding: 60px;
    margin: 0px 0px 20px 0px;
  }
  .container {
    padding: 0px 100px 0px 60px;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  font-weight: 300 !important;
}

.break{
  word-break: break-all;
}
</style>