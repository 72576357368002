<template>
  <div class="twosideHeight">
      <div class="text-center partnerTitle">
        <div>
        <div class="brandcolor">Partner with us</div>
        <h3>
          We are just a button away from you. Leverage our always available
          world class support team who will attend to all your needs within
          seconds
        </h3>
    </div>
    </div>
    <div>
        <PartnerForm />
      </div>
  </div>
</template>



<script>
import PartnerForm from "./PartnerForm.vue";

export default {
  components: {
    PartnerForm,
  },
};
</script>



<style scoped>
.twosideHeight {
  padding-bottom: 50px;
}

.twoSideContainer {
  max-width: 1000px;
  display: flex;
  margin: 50px auto 90px 90px;
  justify-content: space-between;
}

.twoSideContainer h1 {
  font-size: 40px;
  line-height: 40px;
  padding: 15px 0px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
  .partnerWidth {
    text-align:center
  }
}

@media only screen and (min-width: 768px) {
  .partnerWidth {
    text-align: center;
  }
 

  .partnerTitle{
    text-align: center;
    max-width:630px;
    margin:30px auto 0px auto
  }


  .partnerTitle h3 {
    font-size: 32px;
    line-height: 40px;
    padding: 0px 0px 0px 0px;
    font-weight: 600;
  }



}

@media only screen and (min-width: 996px) {

}

@media only screen and (min-width: 1200px) {
  
}

@media only screen and (min-width: 1500px) {
 
}

@media only screen and (min-width: 1900px) {

}
</style>