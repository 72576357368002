<template>
  <div class="campaign-donation-link">
    <h4>Donation Link</h4>
    <div class="donation-button">
      <icon :name="'blue-link'" />
      <div>
        {{ link | donationLinkFormatter }}
      </div>
      <div>
        <icon @click="shareLink" :name="'share'" />
        <icon @click="copyLink" :name="'copy'" />
      </div>
    </div>

    <toast
      :alert="alert"
      :content="'Link copied to clipboard'"
      @closeToast="closeToast"
    />
  </div>
</template>



<script>
import Icon from "@/assets/Icons/CustomIcons/Icon.vue";
import Toast from "../../Misc/Toast.vue";
import { donationLinkFormatter } from "@/utils/descriptionFormatter";
import Vue from "vue";
Vue.filter("donationLinkFormatter", donationLinkFormatter);

export default {
  data() {
    return {
      alert: false,
    };
  },
  components: { Icon, Toast },
  props: {
    link: {
      type: String,
    },
    project: {
      type: Object,
    }
  },
  methods: {
    shareLink() {
      const data = {
        title: this.project.title,
        url: this.link,
      };
      navigator.share(data);
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
      this.alert = !this.alert;
    },

    closeToast() {
      this.alert = false;
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .campaign-donation-link {
    border: 1px solid #d7d7d7;
    padding: 20px 10px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
  }
  .donation-button {
    background: #f5fbff;
    display: flex;
    padding: 9px 8px;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 600px) {
  .campaign-donation-link {
    border: 1px solid #d7d7d7;
    padding: 20px 10px 60px 10px;
    margin: 20px 6px 10px 6px;
    border-radius: 8px;
    height: auto;
  }
  .donation-button {
    background: #f5fbff;
    display: flex;
    padding: 9px 8px;
    justify-content: space-between;
  }
}
</style>