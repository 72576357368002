<template>
     <div @click="goToLink(link)" class="solutionItem">
      <div class="d-flex">
        <img src="../../assets/Star.svg"/>
        <h3 class="ml-2 mt-1"> {{ title }} </h3>
      </div>

      <p>
        {{ description }}
      </p>
    </div>
</template>


<script>
export default {
    props:{
        title:{
            type: String
        },
        description:{
            type:String
        },
        link:{
            type:String
        }
    },

    methods:{
        goToLink(link){
            window.location.href = link
        }
    }
}
</script>



<style scoped>
.solutionItem{
    padding:40px 35px;
    cursor: pointer
}

.solutionItem:hover{
    background:#ddebf7
}

.solutionItem > h3{
    font-size:20px !important
}

.solutionItem > p{
    font-size:14px;
    margin:10px 0px
}
</style>