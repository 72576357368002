<template>
    <v-main>
      <div class="twosideHeight">
        <div class="twoSideContainer">
          <div class="twoSideText">

            <div v-if="title">
            <div class="titleWithIcon">
            <img class="iconImage" src="../../../assets/Star.svg"/> 
              <h2>{{ title }}</h2>
            </div>

            <p>
             {{ description }}
            </p>
            </div>

            <div v-if="!title">
              <div class="descriptionWithoutTitle">{{ description }} </div>
            </div>
        
          </div>
          <div class="imagePosition">
            <img :src="require(`../../../assets/${image}.svg`)" />
          </div>
        </div>
      </div>
    </v-main>
  </template>
    
    
    
    
  <script lang="js">
  
  export default {
    props:{
        title:{
            type:String
        },
        description:{
            type:String
        },
        image:{
            type:String
        }
    }
  };
  </script>
    
    <style scoped>
  .twoSideContainer h1 {
    font-size: 32px;
    line-height: 32px;
    padding: 15px 0px;
    font-weight: 600;
  }
  
  .twoSideText {
    margin: 109px 0px 0px 0px;
    text-align:start
  }

  .descriptionWithoutTitle{
    font-weight:500
  }
  
  @media only screen and (max-width: 600px) {
    .imagePosition {
      margin: 30px;
    }
    .twoSideContainer {
      display: block;
      margin: 50px auto 0px auto;
      justify-content: space-between;
      padding: 40px 0px;
    }

    .twoSideContainer h2 {
      font-size: 32px
    }
  
    .twoSideContainer img {
      width: 90%;
    }
  
    .twoSideContainer p {
      width: 100%;
    }
  
    .twoSideText {
      margin: 40px auto;
      width: 90%;
      text-align: start;
    }
    .twosideHeight {
      background:#F5FBFF;
      height: auto;
      border: 0px !important;
      outline: 0px !important;
    }

    .iconImage{
        width:40px !important
    }
  }
  
  @media only screen and (min-width: 600px) {
    .twoSideContainer h2 {
      font-size: 32px
    }
    .imagePosition {
      margin: 50px;
    }
  
    .imagePosition img {
      width: 500px;
    }
    .twoSideContainer {
      /* max-width:1000px; */
      display: block;
      margin: 50px auto 0px auto;
      justify-content: space-between;
    }
    .twoSideContainer h1 {
      font-size: 32px;
      line-height: 40px;
      padding: 0px 90px 17px 0px;
      font-weight: 600;
      width: 90%;
    }
  
    .twoSideContainer p {
      width: 70%;
    }
  
    .twoSideText {
      margin: 80px 40px;
      padding: 50px 0px 0px 0px;
    }
    .twosideHeight {
      background:#F5FBFF;
      height: auto;
      border: 0px !important;
      outline: 0px !important;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .imagePosition {
      margin: 50px;
    }
    .imagePosition img {
      width: 600px;
    }
    .twoSideContainer {
      /* max-width:1000px; */
      display: block;
      margin: 50px auto 0px auto;
      justify-content: space-between;
    }
    .twoSideContainer h1 {
      font-size: 32px;
      line-height: 32px;
      padding: 0px 90px 17px 0px;
      font-weight: 600;
      width: 100%;
    }
  
    .twoSideContainer p {
      width: 70%;
      font-size: 18px;
    }
  
    .twoSideText {
      margin: 100px 40px 0px 40px;
      padding: 80px 0px;
    }
  
    .twosideHeight {
      height: auto;
      border: 0px !important;
      outline: 0px !important;
      padding: 30px 0px 50px 0px
    }

    .titleWithIcon{
        display:flex;
    }
    .titleWithIcon h2{
        margin:0px 0px 0px 10px
    }

    .twoSideContainer p {
      width: 50%;
      font-size: 18px;
      text-align: start;
      margin:20px 60px 0px 0px
    }

    .descriptionWithoutTitle{
    font-weight:500;
    font-size:20px;
    padding: 0px;
    margin:-80px 0px 0px 0px;
    max-width:500px
  }
  }
  
  @media only screen and (min-width: 996px) {
    .imagePosition {
      margin: 50px 0px 0px 0px;
    }
  
    .imagePosition img {
      width: auto;
    }
    .twoSideContainer p {
      width: 70%;
    }
    .twoSideContainer {
      max-width: 86%;
      display: flex;
      margin: 50px 0px 0px 100px;
      justify-content: space-between;
    }
  
    .twoSideContainer h1 {
      font-size: 48px;
      line-height: 48px;
      padding: 10px 0px;
      font-weight: 600;
      width: 100%;
    }
    .twoSideText {
      margin: 100px 0px 0px 0px;
      width: 50%;
    }
  
    .twosideHeight {
      height: auto;
      border: 0px !important;
      outline: 0px !important;
      background:#F5FBFF;
    }
  
    .textDescription {
      width: 60%;
    }
  }
  
  @media only screen and (min-width: 1400px) {
    .twoSideContainer {
      display: flex;
      margin: 50px 0px 0px 100px;
      justify-content: space-between;
    }
  
    .twoSideContainer h1 {
      font-size: 48px;
      line-height: 48px;
      padding: 10px 0px;
      font-weight: 600;
      width: 100%;
    }
    .twoSideText {
      margin: 0px 0px 0px 0px;
      width: 50%;
    }
  
    .twosideHeight {
      height: auto;
      border: 0px !important;
      outline: 0px !important;
      padding: 60px 0px;
      background:#F5FBFF;
    }
  }
  
  @media only screen and (min-width: 1900px) {
    .twoSideContainer {
      display: flex;
      margin: 50px 0px 0px 100px;
      justify-content: space-between;
      height: auto;
    }
  
    .twoSideContainer {
      max-width: 1500px;
      display: flex;
      margin: 100px auto 0px auto;
      justify-content: space-between;
    }
  
    .twoSideContainer h1 {
      font-size: 48px;
      line-height: 48px;
      padding: 10px 0px;
      font-weight: 600;
    }
  
    .twoSideText {
      margin: 0px 0px 0px 0px;
    }

    .descriptionWithoutTitle{
    font-weight:500;
    font-size:20px;
    padding: 0px;
    margin:-0px 0px 0px 0px;
    max-width:500px
  }
  }
  </style>