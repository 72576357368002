<template>
  <v-container>
    <div class="loginContainer">
      <div class="d-flex justify-center">
        <a href="/">
          <img src="../../assets/kuleanBrand.svg" />
        </a>
      </div>

      <h2 style="text-align:center;margin: 16px 0px 0px 0px">Welcome Back</h2>
      <v-alert class="mt-1" :value="alert" type="error">
        {{ errors }}
      </v-alert>

      <v-form @submit.prevent="login()" ref="form" class="mt-2 pa-1">
        <label>Phone Number</label>
        <vue-tel-input
          autocomplete="null"
          @country-changed="countryChanged"
          class="phoneField"
          v-model="phone"
          type="number"
          style="height: 46px; width: 100%; margin: 0px 0px 10px 0px"
        ></vue-tel-input>

        <label>Password</label>
        <v-text-field
          autocomplete="off"
          v-model="password"
          outlined
          dense
          color="#0582D2"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          class="input-group--focused"
          @click:append="show = !show"
          style="margin-bottom: -10px"
        />

        <div class="text-right">
          <router-link to="/forgot-password"> Forgot Password</router-link>
        </div>

        <v-checkbox
          color="#0582D2"
          v-model="checkbox"
          label="Remember me"
        ></v-checkbox>

        <v-btn
          depressed
          class="
            mt-3
            white--text
            createAccount
            animate__animated animate__fadeInUp
          "
          style="
            height: 48px;
            margin-top: -13px;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 8px;
          "
          large
          @click="login()"
          :loading="loading"
        >
          Log In</v-btn
        >
      </v-form>
    <div class="createAccountText">I don't have an account. <router-link to="/signup">Create Account</router-link></div>
    </div>
  </v-container>
</template>



<script>
import axios from "axios";
export default {
  data() {
    return {
      password: "",
      loading: false,
      snackbar: false,
      alert: false,
      dialog: false,
      message: "",
      errors: "",
      checkbox: false,
      // rules: {
      //   required: (value) => !!value || "Required.",
      //   emailMatch: () => "The email and password you entered don't match",
      // },
      show: false,
      phone: "",
      country: "",
    };
  },
  methods: {
    countryChanged(e) {
      this.country = e.dialCode;
    },

    login() {
      this.loading = true;
      this.phone = this.phone.replaceAll(" ", "");
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=session-manager/session/authenticate/customer",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          phoneNumber: this.phone.replaceAll(" ", ""),
          password: this.password,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.description == "Login Successful") {
            this.$store.dispatch("fetchUser", response.data.data);
            sessionStorage.setItem(
              "userData",
              JSON.stringify(response.data.data)
            );
            sessionStorage.setItem("userToken", response.data.data.idToken);
            window.location.href = "/dashboard";
          } else {
            this.errors = response.data.description;
            this.loading = false;
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
            }, 2000);
            this.loading = false;
            return;
          }
        })
        .catch((error) => {
          this.alert = true;
          this.errors = error.message;
          this.loading = false;
        });
    },
  },
};
</script>


<style scoped>
@media screen and (min-width: 400px) {
  .buttonFlex {
    display: flex;
    justify-content: center;
  }
}

a,
router-link {
  color: #0582D2 !important;
  text-decoration: none !important;
}
.or {
  text-align: center;
}
.line {
  height: 1px;
  background: #959ba7;
}
.orText {
  position: relative;
  top: 10px;
  color: #959ba7;
  font-size: 13px;
  background: white;
  display: inline-block;
  padding: 0 20px;
  border-radius: 30px;
}
.v-btn {
  text-transform: none !important;
}
.createAccount {
  background: linear-gradient(180deg, #0582D2 0%, #0582D2 100%);
}
.bg {
  background-image: url("../../assets/authBackground.svg");
  height: 600px;
  background-position: center;
  background-size: cover;
}
.bgMobile {
  background-image: url("../../assets/authBackground.svg");
  background-position: center;
  background-size: cover;
  height: 800px;
}
.login {
  position: absolute;
}

.loginContainer{
    margin:10px auto;
    max-width: 448px;
    border:1px solid #DEDFE3;
    padding:60px 40px 30px 40px
}

.createAccountText{
    color:#959BA7;
    text-align:center
}
</style>