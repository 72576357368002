<template>
  <v-main class="twosideHeight">
    <div class="twoSideContainer flex-row-reverse">
      <div class="twoSideText">
        <div>
        <div v-if="title" class="titleWithIcon">
          <img class="iconImage" src="../../../assets/Star.svg" />
          <h2>{{ title }}</h2>
        </div>

        <p class="headingWidth">
          {{ description }}
        </p>
    </div>
      </div>
      <div class="imagePosition">
        <img :src="require(`../../../assets/${image}.svg`)" />
      </div>
    </div>
  </v-main>
</template>
  
  


<script>
  
export default {
  props:{
      title:{
          type:String
      },
      description:{
          type:String
      },
      image:{
          type:String
      }
  }
};
</script>
  
  
  <style scoped>
.twoSideContainer h1 {
  font-size: 40px;
  line-height: 40px;
  padding: 15px 0px;
  font-weight: 600;
}

.twoSideText {
  margin: 109px 0px 0px 0px;
  text-align:start
}

@media screen and (max-width: 600px) {

    .twoSideContainer h2 {
      font-size: 32px
    }
  .imagePosition {
    margin: 30px;
  }

  .imagePosition img {
    width: auto;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
    padding: 50px 0px;
  }
  .twoSideContainer h1 {
    line-height: 30px;
    padding: 10px 0px 10px 0px;
    font-weight: 600;
  }

  .twoSideContainer img {
    width: 90%;
  }

  .twoSideContainer p {
    width: 100%;
  }

  .twoSideText {
    margin: 0px auto;
    width: 90%;
    text-align: start;
  }
  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
  .iconImage{
        width:40px !important
    }
}

@media screen and (min-width: 600px) {
    .twoSideContainer h2 {
      font-size: 32px
    }
  .imagePosition {
    margin: 50px;
  }

  .imagePosition img {
    width: 500px;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
  }
  .twoSideContainer h1 {
    font-size: 40px;
    line-height: 40px;
    padding: 0px 90px 17px 0px;
    font-weight: 600;
    width: 90%;
  }

  .twoSideContainer p {
    width: 70%;
  }

  .twoSideText {
    margin: 0px 40px;
    padding: 50px 0px 0px 0px;
  }
  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
  .iconImage{
        width:40px !important
    }
}

@media screen and (min-width: 768px) {
  .imagePosition {
    margin: 50px;
  }
  .imagePosition img {
    width: 600px;
  }
  .twoSideContainer {
    /* max-width:1000px; */
    display: block;
    margin: 50px auto 0px auto;
    justify-content: space-between;
  }
  .twoSideContainer h1 {
    font-size: 44px;
    line-height: 40px;
    padding: 0px 90px 17px 0px;
    font-weight: 600;
    width: 100%;
  }

  .twoSideContainer p {
      width: 50%;
      font-size: 18px;
      text-align: start;
      margin:20px 60px 0px 0px
    }

  .twoSideText {
    margin: 0px 40px;
    padding: 80px 0px;
  }

  .titleWithIcon{
        display:flex;
        margin:50px 0px 0px 0px
    }
    .titleWithIcon h2{
        margin:0px 0px 0px 10px
    }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }
}

@media screen and (min-width: 996px) {
  .imagePosition {
    margin: 50px 0px 0px 0px;
  }

  .imagePosition img {
    width: auto;
  }
  .twoSideContainer p {
    width: 100%;
  }
  .twoSideContainer {
    max-width: 86%;
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
    padding: 60px 0px;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
    width: 100%;
  }
  .twoSideText {
    margin: 90px 0px 0px 90px;
    width: 50%;
  }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
  }

  .textDescription {
    width: 60%;
  }

  .bankDescription {
    max-width: 420px;
  }
}

@media screen and (min-width: 1400px) {
  .twoSideContainer {
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
    width: 100%;
  }
  .twoSideText {
    margin: 0px 0px 0px 0px;
    width: 50%;
  }

  .twosideHeight {
    height: auto;
    border: 0px !important;
    outline: 0px !important;
    padding: 60px 0px;
  }
}

@media screen and (min-width: 1900px) {
  .twoSideContainer {
    display: flex;
    margin: 50px 0px 0px 100px;
    justify-content: space-between;
    height: auto;
  }

  .twoSideContainer {
    max-width: 1500px;
    display: flex;
    margin: 100px auto 0px auto;
    justify-content: space-between;
  }

  .twoSideContainer h1 {
    font-size: 48px;
    line-height: 48px;
    padding: 10px 0px;
    font-weight: 600;
  }

  .twoSideText {
    margin: 0px 0px 0px 0px;
  }
}
</style>