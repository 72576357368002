<template>
  <div class="crowdprojects">
    <div v-for="(project, i) in projects" :key="i">
      <SingleProject
        :title="project.title"
        :amount="project.goalAmount"
        :description="project.description"
        :image="project.campeignImage"
        :status="project.approvalStatus"
        :active="active"
        :type="'private'"
        :project="project"
      />
    </div>
    <LoadingProject v-if="loading" />
    <LoadingProject v-if="loading" />
    <LoadingProject v-if="loading" />
    <LoadingProject v-if="loading" />
  </div>
</template>
  
  
  
<script>
import LoadingProject from "../Loading/LoadingProject.vue";
import SingleProject from "./SingleProject.vue";

export default {
  name: "CrowdProjects",
  components: {
    LoadingProject,
    SingleProject,
  },
  props: {
    projects: {
      type: Array,
    },
    active: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 600px) {
  .crowdprojects {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .crowdprojects {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 776px) {
  .crowdprojects {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) {
  .crowdprojects {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px 0px;
  }
}

@media only screen and (min-width: 1500px) {
  .crowdprojects {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px 0px;
  }
}
</style>