<template>
  <v-main>
    <v-sheet class="sheet">
      <div class="donationform">
        <div class="d-flex justify-space-between">
          <div class="pointer" @click="closeDonation">
            <BreadCrumbs :items="items" />
          </div>

          <div>
            <v-btn @click="closeDonation" depressed icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </div>
        <div class="my-2">
          <ProjectMeta :project="project" :title="title" :date="date" />
        </div>
        <v-form lazy-validation ref="form" v-model="valid">
          <label>Name</label>
          <v-text-field
            class="text-input"
            placeholder="Enter name"
            dense
            outlined
            v-model="name"
          />
          <label>Email</label>
          <v-text-field
            placeholder="Enter email"
            dense
            outlined
            :rules="emailRules"
            v-model="email"
            class="text-input"
          />

          <label>Amount</label>
          <AmountInput ref="amountInput" @setAmount="setAmount" />
        </v-form>

        <div class="my-3">
          <v-btn
            :loading="loading"
            @click="initiatePayment"
            block
            x-large
            class="primarybtn"
          >
            Make Donation
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-main>
</template>



<script>
import AmountInput from "../Forms/AmountInput.vue";
import BreadCrumbs from "../Misc/BreadCrumbs.vue";
import ProjectMeta from "./ProjectMeta.vue";
import { LOGINURL } from "@/constants/constants";
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      email: "",
      amount: null,
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      amountRules: [(v) => !!v || "Amount cannot be empty"],
      items: [
        {
          text: "CrowdFunding",
          disabled: false,
          href: "",
        },
        {
          text: "Details",
          disabled: true,
          href: "details",
        },
      ],
      loading: false,
      processId: "",
    };
  },

  props: {
    project: {
      type: Object,
    },
    title: {
      type: String,
    },
    date: {
      type: String,
    },
  },
  components: {
    ProjectMeta,
    AmountInput,
    BreadCrumbs,
  },
  methods: {
    setAmount(e) {
      this.amount = parseFloat(e.replaceAll(",", ""));
    },
    closeDonation() {
      this.$emit("closeDonation");
    },

    callback(response) {
      console.log(response);
    },
    initiatePayment() {
      this.loading = true;
      axios({
        method: "POST",
        url:
          LOGINURL +
          "settlements/onetimesettlementmgt/crowd-funding/initiate-make-payment-outside",
        headers: {
          channel: "Web",
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        data: {
          crowdFundingLink: `https//www.kuleanpay.com${this.project.transactionId}`,
          amount: this.amount,
          goalAmount: this.project.goalAmount,
          customerId: this.email,
          walletNo: this.project.walletNo,
          customerName: this.name,
        },
      })
        .then((response) => {
          this.loading = false;
          if (response.data.statusCode === 200) {
            this.processId = response.data.data.processId;
            this.makePayment();
          } else{
            this.$swal({
            icon: "error",
            title: "Something went wrong",
            text: response.data.description,
          })
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong",
            text: error.message,
          });
        });
    },
    async makePayment() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.$emit("closeDonation");
        this.$emit("makePayment", {
          processId: this.processId,
          name: this.name,
          email: this.email,
          amount: this.amount,
        });
      }
    },
  },
};
</script>


<style scoped>
.sheet {
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .donationform {
    max-width: 300px;
    padding: 10px 0px;
    margin: auto;
  }
}

@media only screen and (min-width: 600px) {
  .donationform {
    max-width: 450px;
    margin: auto;
    padding: 10px 0px;
  }
}
</style>