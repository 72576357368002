<template>
  <v-main class="bgcolor">
    <div class="jumbotronContainer">
      <div class="jumbotronText">
        <h2>Ensure what you ordered is what you get with kuleanpay</h2>
        <p>
          Buyers and sellers can now protect their money and integrity
          respectively when they use Kuleanpay.
        </p>
        <div class="buttonFlex">
          <v-btn
            href="https://play.google.com/store/apps/details?id=com.cwg.kuleanpay&pli=1"
            color="#0582D2"
            class="mr-5 rounded-lg white--text"
            x-large
            ><v-icon class="mr-3">mdi-view-grid-plus-outline</v-icon>Create
            Transaction</v-btn
          >
          <div class="joinContainer">
            <div class="joinForm">
              <v-img
                class="joinIcon"
                src="../../assets/joinTransaction.svg"
                width="19px"
                height="19px"
              />
              <input
                placeholder="Enter ID to join"
                v-model="joinId"
                type="text"
              />
            </div>
            <v-btn
              @click="joinTransaction"
              :loading="joinloading"
              v-if="joinId != ''"
              width="10px"
              class="joinButton"
              large
              text
              color="#0582D2"
              >Join</v-btn
            >
          </div>
        </div>
      </div>

      <div class="relative jumbotronImage">
        <v-img
          class="imageSize"
          src="../../assets/delivery.svg"
          alt="kuleanshield"
        />
      </div>

      <v-dialog persistent max-width="590px" v-model="otpSent">
        <v-window v-model="step">
          <v-window-item :value="1">
            <OtpForm6
              @closeOtp="closeOtp"
              @setOtp="setOtp"
              @verifyOtp="verifyOtp"
              :verifyloading="verifyloading"
            />
          </v-window-item>
          <v-window-item :value="2">
            <successScreen
              :title="'title'"
              :description="'description'"
              :button-text="'ButtonText'"
              @closeSuccess="closeSuccess"
            />
          </v-window-item>
        </v-window>
      </v-dialog>
    </div>
  </v-main>
</template>




<script>
import axios from "axios";
import successScreen from "../OTP/successScreen.vue";
import OtpForm6 from "../OTP/OtpForm6.vue";
export default {
  data: () => ({
    joinId: "",
    joinloading: false,
    step: 1,
    otpSent: false,
    otp: "",
    verifyloading: false,
    joinTransactionId: "",
    requestId: "",
  }),

  components: {
    successScreen,
    OtpForm6,
  },

  methods: {
    closeSuccess() {
      alert("trap lan je");
    },
    createTransaction() {
      this.$router.push("/create-transaction");
    },

    joinTransaction() {
      this.joinloading = true;
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=settlements/onetimesettlementmgt/get-join-transactions-details",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json"
        },
        data: {
          joinTransactionId: "#" + this.joinId.trim(),
        },
      })
        .then((response) => {
          console.log(response);
          this.joinloading = false;
          if (response.data.statusCode == 200) {
            this.otpSent = true;
            this.requestId = response.data.data.requestId;
            this.joinTransactionId = response.data.data.joinTransactionId;
            return;
          }
          this.$swal({
            icon: "error",
            title: response.data.description,
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
        })
        .catch((error) => {
          console.log(error);
          this.joinloading = false;
        });
    },
    setOtp(e) {
      this.otp = e;
    },
    verifyOtp() {
      this.verifyloading = true;
      axios({
        method: "POST",
        url: "https://proxy.kuleanpay.com/api/login?url=settlements/onetimesettlementmgt/validate-get-join-transactions-details",
        headers: {
          channel: "Web",
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          otp: this.otp,
          requestId: this.requestId,
        },
      })
        .then((response) => {
          console.log(response);
          this.verifyloading = false;
          if (response.data.statusCode == 200) {
            sessionStorage.setItem(
              "joinTransactionData",
              JSON.stringify(response.data.data)
            );
            this.$router.push("/join-transaction");
            return;
          }

          this.$swal({
            icon: "error",
            title: response.data.description,
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
        })
        .catch((error) => {
          console.log(error);
          this.joinloading = false;
        });
    },
    closeOtp() {
      this.otpSent = false;
    },
  },
};
</script>




<style scoped>
.createButton {
  border-radius: 8px;
}

.joinButton {
  color: white;
}

.jumbotronContainer {
  padding: 0px 100px;
}

.bgcolor {
  background: linear-gradient(108.45deg, #f0f7ff -4.61%, #f0f7ff 104.96%);
  height: auto;
}

.navMenu {
  padding: 34px 100px;
}

@media only screen and (max-width: 400px) {
  .bgcolor {
    height: auto !important;
    max-height: 400px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-btn {
    width: 90%;
    margin: 15px 0px 0px 0px;
  }
  .createButton,
  .joinButton {
    width: 90% !important;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 40px !important;
    line-height: 40px !important;
    width: 90%;
  }

  .bgcolor {
    height: auto !important;
    max-height: 950px;
  }

  .jumbotronContainer img {
    margin: 30px 0px;
    width: 95%;
  }

  .bgcolor {
    height: auto;
  }

  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
  }

  .jumbotronContainer img {
    margin: 90px 0px;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 90%;
    font-size: 16px;
  }

  .joinContainer {
    display: flex;
  }

  .joinButton {
    margin: 19px 30px 0px 0px !important;
    width: 50px !important;
  }

  input[type="text"] {
    height: 50px;
    border: 1px solid black;
    border-radius: 8px;
    width: 90% !important;
    padding: 0px 0px 0px 40px;
    font-size: 19px;
  }

  input[type="text"]:focus {
    outline: #09101d;
    width: 60%;
    padding: 0px 0px 0px 40px;
  }

  .joinForm:focus {
    position: relative;
    margin: 19px 0px 0px 0px;
    width: auto;
  }
  .joinForm {
    position: relative;
    margin: 19px 0px 0px 0px;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bgcolor {
    height: auto;
  }

  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
  }

  .jumbotronContainer img {
    margin: 90px 0px;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 90%;
    font-size: 16px;
  }

  .buttonFlex {
    display: flex;
  }
  .joinContainer {
    display: flex;
  }

  input[type="text"] {
    height: 50px;
    border: 1px solid black;
    border-radius: 8px;
    width: 90%;
    padding: 0px 0px 0px 40px;
    font-size: 19px;
  }

  input[type="text"]:focus {
    outline: #09101d;
    /* width:60%; */
    padding: 0px 0px 0px 40px;
  }

  .joinForm {
    position: relative;
    margin: 0px 0px 0px 0px;
    width: 100%;
  }

  .joinForm:focus {
    position: relative;
    width: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .jumbotronContainer {
    padding: 94px 0px 0px 40px;
    display: block;
    justify-content: space-between;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 50px;
    line-height: 50px;
    width: 90%;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 60%;
    font-size: 18px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bgcolor {
    height: auto;
  }
  .jumbotronContainer {
    padding: 120px 50px 0px 30px;
    display: flex;
    justify-content: space-between;
  }

  .jumbotronText {
    padding: 40px 0px 90px 0px;
    width: 100%;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 56px;
    line-height: 56px;
    width: 80%;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 70%;
    font-size: 16px;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .imageSize {
    position: absolute;
    height: 100%;
    bottom: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .bgcolor {
    height: auto;
  }
  .jumbotronContainer {
    padding: 30px 46px 0px 100px;
    display: flex;
    justify-content: space-between;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 56px;
    line-height: 56px;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 70%;
    font-size: 16px;
  }

  .jumbotronText {
    padding: 40px 0px 90px 0px;
    width: 60%;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .imageSize {
    position: absolute;
    height: 100%;
    bottom: 0;
  }
}

@media screen and (min-width: 1500px) {
  .bgcolor {
    height: auto;
    max-height: 828px !important;
  }
  .jumbotronContainer {
    padding: 70px 46px 0px 100px;
    display: flex;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 56px;
  }

  .jumbotronContainer p {
    color: #09101d;
    padding: 32px 0px;
    width: 50%;
    font-size: 16px;
  }

  .jumbotronImage {
    width: 50%;
    position: relative;
  }

  .imageSize {
    position: relative;
    height: auto;
    bottom: 0;
  }
}

@media screen and (min-width: 1900px) {
  .jumbotronContainer {
    max-width: 1700px !important;
    margin: 0 auto;
  }

  .jumbotronContainer h2 {
    color: #09101d;
    font-size: 56px;
  }
}

.joinIcon {
  position: absolute;
  top: 13px;
  left: 11px;
}
</style>
