<template>
  <v-snackbar top right color="success" outlined :value="alert">
    <div class="toast">
      <div><icon :name="'success-icon'" /></div>
      <div>{{ content }}</div>
      <v-icon small @click="closeToast">mdi-close</v-icon>
    </div>
  </v-snackbar>
</template>


<script>
import Icon from "@/assets/Icons/CustomIcons/Icon.vue";
export default {
  name: "ToastComponent",
  components: { Icon },
  props: {
    content: {
      type: String,
    },
    alert: {
      type: Boolean,
    },
  },
  methods: {
    closeToast() {
      this.$emit("closeToast");
    },
  },
};
</script>



<style scoped>
.toast {
  display: flex;
  justify-content: space-between;
}
.toast > * {
  padding: 0px 4px;
}
</style>